<template>
   <div class="pa-1">
         <three-state-switch
            v-if="isBooleanFieldType(field)"
            :useInputValue="true"
            :input-value="getContentBrickValue(field)"
            hide-details
            class="mt-0 data-model-values-field"
            true-value="true"
            false-value="false"
            :allowSetIndeterminate="true"
            :layout="field.booleanLayout"
            :readonly="readonly"
         >
            <template v-slot:label>
               {{ getFieldName(field) }}
            </template>
         </three-state-switch>
         <v-text-field
            v-else-if="isNumericFieldType(field)"
            class="data-model-values-field"
            :value="getContentBrickValue(field)"
            :readonly="readonly"
         >
            <template v-slot:label>
               {{ getFieldName(field) }}
            </template>
         </v-text-field>
         <v-text-field
            v-else-if="isCalculatedFieldType(field)"
            class="data-model-values-field"
            :value="field.fieldCalculation?.formula"
            :readonly="readonly"
         >
            <template v-slot:label>
               {{ getFieldName(field) }}
            </template>
            <template v-slot:append>
               <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                     <v-icon v-on="on" :v-bind="attrs">mdi-calculator-variant-outline</v-icon>
                  </template>
                  <span>Calculated field</span>
               </v-tooltip>
            </template>
         </v-text-field>
         <div v-else-if="isTextAreaFieldType(field)" style="text-align: left">
            <label class="field-label">
               {{ getFieldName(field) }}
            </label>
            <div class="mt-1" v-html="getContentBrickValue(field)" style="line-height: 0.6; font-size: 14px"></div>
         </div>
         <v-combobox
            v-else-if="isComboBoxFieldType(field) || isListFieldType(field) || isMultiListFieldType(field)"
            :value="getListFieldItems(field)"
            class="data-model-values-field"
            :label="getFieldName(field)"
            item-text="text"
            item-value="identifier"
            multiple
            small-chips
            hide-no-data
            :readonly="true"
            hide-details
         >
            <template v-slot:selection="{ attrs, item }">
               <v-chip small v-bind="attrs" class="chip-item">
                  {{ item.text }}
               </v-chip>
            </template>
         </v-combobox>
         <v-text-field
            v-else-if="isTextFieldType(field) || isTextAreaFieldType(field)"
            class="data-model-values-field"
            :value="getContentBrickValue(field)"
            :readonly="readonly"
         >
            <template v-slot:label>
               {{ getFieldName(field) }}
            </template>
         </v-text-field>
         <v-text-field
            v-else-if="isDateFieldType(field)"
            class="data-model-values-field"
            :value="getContentBrickValue(field)"
            :readonly="readonly"
         >
            <template v-slot:label>
               {{ getDateFieldLabel(field) }}
            </template>
         </v-text-field>
         <div
            v-else-if="isDocumentFieldType(field) || isImageFieldType(field) || isVideoFieldType(field)"
            :ref="generateFileFieldReference(field.id)"
         >
            <template>
               <div>
                  <div>
                     {{ getFieldName(field) }}
                  </div>
                  <v-row v-if="isDocumentFieldType(field)">
                     <v-chip-group class="ml-3">
                        <v-chip
                           v-for="(item, index) in getContentBrickValue(field)"
                           :key="index"
                           small
                           @click="onDownloadAttachmentClick(item)"
                           @mouseup.stop.prevent
                        >
                           {{ getAttachmentName(item) }}
                           <span class="grey--text ml-1">{{ getAttachmentSize(item) }}</span>
                        </v-chip>
                     </v-chip-group>
                  </v-row>
                  <v-row v-else-if="isImageFieldType(field)">
                     <v-col
                        v-for="(item, index) in getContentBrickValue(field)"
                        :key="index"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        xl="2"
                        align-self="start"
                     >
                        <v-tooltip top>
                           <template v-slot:activator="{ on, attrs }">
                              <v-card flat tile v-bind="attrs" v-on="on">
                                 <v-img
                                    max-height="200"
                                    max-width="200"
                                    :src="mediaUrls[item]"
                                    @click="onDownloadAttachmentClick(item)"
                                    @mouseup.stop.prevent
                                 >
                                    <template v-slot:placeholder>
                                       <v-row class="fill-height ma-0" align="center" justify="center">
                                          <v-progress-circular
                                             indeterminate
                                             color="blue lighten-5"
                                          ></v-progress-circular>
                                       </v-row>
                                    </template>
                                 </v-img>
                              </v-card>
                           </template>
                           <div @mouseup.stop.prevent>
                              <template v-if="!attachmentsLoading">
                                 {{ getAttachmentName(item) }}
                                 <span class="grey--text ml-1">
                                    {{ getAttachmentSize(item) }}
                                 </span>
                              </template>
                              <template v-else>Loading...</template>
                           </div>
                        </v-tooltip>
                     </v-col>
                  </v-row>
                  <v-row v-else-if="isVideoFieldType(field)">
                     <v-col
                        v-for="(item, index) in getContentBrickValue(field)"
                        :key="index"
                        cols="12"
                        xl="6"
                        align-self="start"
                        class="pa-1"
                     >
                        <v-tooltip top>
                           <template v-slot:activator="{ on, attrs }">
                              <v-card flat tile v-bind="attrs" v-on="on">
                                 <video
                                    :src="mediaUrls[item]"
                                    class="w-100 h-100 object-fit-cover"
                                    @mouseup.stop.prevent
                                    controls
                                    preload="metadata"
                                 ></video>
                                 <attachment-download-btn @click="onDownloadAttachmentClick(item)" absolute top left />
                              </v-card>
                           </template>
                           <div @mouseup.stop.prevent>
                              <template v-if="!attachmentsLoading">
                                 {{ getAttachmentName(item) }}
                                 <span class="grey--text ml-1">
                                    {{ getAttachmentSize(item) }}
                                 </span>
                              </template>
                              <template v-else>Loading...</template>
                           </div>
                        </v-tooltip>
                     </v-col>
                  </v-row>
               </div>
            </template>
         </div>
         <v-row v-else-if="isSubContentBrickFieldType(field)" align="center">
            <v-col>
               <div class="d-flex align-center">
                  <div class="mr-2">
                     {{ field.name }}
                  </div>
                  <v-tooltip top>
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           v-bind="attrs"
                           v-on="on"
                           icon
                           color="error"
                           elevation="2"
                           :to="getSubContentBrickDetailLink(field)"
                           target="_blank"
                        >
                           <v-icon>mdi-cube-outline</v-icon>
                        </v-btn>
                     </template>
                     <span>Sub Content Brick Detail</span>
                  </v-tooltip>
               </div>
            </v-col>
         </v-row>
         <v-text-field
            v-else
            :disabled="true"
            :label="'Not implemented field type: ' + field.name"
            :value="getContentBrickValue(field)"
            hide-details
         ></v-text-field>
   </div>
</template>

<script lang="ts">
import {
   AttachmentDownloadModel,
   AttachmentMetadata,
   ContentBrickDefinitionApi,
   ContentBrickFieldDefinition,
   ContentBrickFieldType,
   IContentBrickDefinition,
   ListItem,
} from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { Component, Prop } from "vue-property-decorator";
import AttachmentUtils from "@utils/AttachmentUtils";
import _ from "lodash";
import ValidationRules from "@models/shared/ValidationRules";
import ThreeStateSwitch from "@components/Shared/three-state-switch.vue";
import AttachmentDownloadBtn from "@components/Shared/attachment-download-btn.vue";
import BaseResponse from "@models/BaseResponse";
import { FieldDefinition } from "@models/shared/ContentBrickTreeNodeTypeUtils";

@Component({
   name: "DesignGuidelineField",
   components: {
      ThreeStateSwitch,
   },
})
export default class DesignGuidelineField extends ComponentBase {
   @Prop({ required: true })
   field: FieldDefinition;

   @Prop({ required: true })
   attachments: { [key: string]: AttachmentMetadata } | undefined;

   @Prop({ default: () => {} })
   mediaUrls: { [key: string]: string };

   @Prop({ required: true })
   contentBrick: IContentBrickDefinition;

   @Prop({ default: false })
   attachmentsLoading: boolean;

   readonly: boolean = true;

   getContentBrickValue(field: FieldDefinition): string | string[] | undefined {
      if ("value" in field) {
         return field.value;
      }
      return undefined;
   }

   //---------------- Type handling --------------
   isNumericFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.Integer || field.type === ContentBrickFieldType.Decimal;
   }

   isTextFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.TextBox;
   }

   isDateFieldType(field: ContentBrickFieldDefinition): boolean {
      return (
         field.type === ContentBrickFieldType.Date ||
         field.type === ContentBrickFieldType.DateTime ||
         field.type === ContentBrickFieldType.Time
      );
   }

   isDocumentFieldType(field: ContentBrickFieldDefinition): boolean {
      return AttachmentUtils.isDocumentField(field.type);
   }

   isImageFieldType(field: ContentBrickFieldDefinition): boolean {
      return AttachmentUtils.isImageField(field.type);
   }

   isVideoFieldType(field: ContentBrickFieldDefinition): boolean {
      return AttachmentUtils.isVideoField(field.type);
   }

   isSubContentBrickFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.SubContentBrick;
   }

   isTextAreaFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.TextArea;
   }

   isCalculatedFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.Calculated;
   }

   isBooleanFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.Boolean;
   }

   isComboBoxFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.ComboBox;
   }

   isListFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.List;
   }

   isMultiListFieldType(field: ContentBrickFieldDefinition): boolean {
      return field.type === ContentBrickFieldType.MultiSelectList;
   }

   getFieldName(field: ContentBrickFieldDefinition): string | undefined {
      return `${field.name} (${field.identifier})${this.isFieldValueRequired(field) ? "*" : ""}`;
   }

   isFieldValueRequired(field: ContentBrickFieldDefinition) {
      return field.isMandatory;
   }

   getListFieldItems(field: ContentBrickFieldDefinition): ListItem[] {
      if (field.listId) {
         return field.list?.items?.filter((co) => !co.disabled) ?? [];
      }
      return [];
   }

   getDateFieldLabel(field: ContentBrickFieldDefinition) {
      var fieldName = this.getFieldName(field);
      return field.type === ContentBrickFieldType.Date
         ? this.getFieldLabel(fieldName, ValidationRules.isoFormatDate)
         : field.type === ContentBrickFieldType.DateTime
         ? this.getFieldLabel(fieldName, ValidationRules.isoFormatDateTime)
         : this.getFieldLabel(fieldName, ValidationRules.isoFormatTime);
   }

   getFieldLabel(name, help) {
      return `${name} ${help}`;
   }

   generateFileFieldReference(fieldId: string): string {
      return "cbFieldFileReference" + fieldId.toString(); // Generate original reference for each document/image contentBrick Field
   }

   getAttachmentName(blobName: string): string | undefined {
      var metadata = this.attachments?.[blobName];
      return metadata?.fileName;
   }

   getAttachmentSize(blobName: string): string | undefined {
      var metadata = this.attachments?.[blobName];
      if (metadata) {
         return AttachmentUtils.sizeHumanReadable(metadata.size);
      }
   }

   getSubContentBrickDetailLink(field: ContentBrickFieldDefinition) {
      return {
         name: "sub-content-brick-detail-code",
         params: { code: field.subContentBrickCode },
      };
   }

   async onDownloadAttachmentClick(blobName: string): Promise<void> {
      var file = await this.downloadAttachment(blobName);
      if (file) {
         AttachmentUtils.downloadFile(file);
      }
   }

   async downloadAttachment(blobName: string): Promise<AttachmentDownloadModel | undefined> {
      if (!blobName) {
         throw "Blob name is required";
      }

      try {
         // Call the API FileResponse
         let file = ContentBrickDefinitionApi.generateAttachmentDownloadUrl(this.contentBrick!.id, blobName);
         return file;
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API DesignGuideline attachment download error:", error);
         this.notifyError(error, "download", "DesignGuideline Attachment");
      }
   }
}
</script>
