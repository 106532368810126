import { DesignGuidelineNodeType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IDesignGuidelineNodeTypeProperties extends IEnumBaseProperties {
   readonly icon: string;
   readonly legacyLabel: string;
}

export default class DesignGuidelineNodeTypeDecorator implements IDesignGuidelineNodeTypeProperties {
   value: DesignGuidelineNodeType;
   private static readonly unknown: number = -1; //to-do possible duplicate with ContentBrickTreeNodeType

   constructor(value: DesignGuidelineNodeType) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get icon(): string {
      return this.properties.icon;
   }

   get legacyLabel(): string {
      return this.properties.legacyLabel;
   }

   static isResultEnum(value: any): value is DesignGuidelineNodeType {
      return Object.values(DesignGuidelineNodeType).includes(value);
   }

   static get(result: DesignGuidelineNodeType): IDesignGuidelineNodeTypeProperties {
      return (
         DesignGuidelineNodeTypeDecorator.DesignGuidelineNodeTypeProperties.get(result) ??
         DesignGuidelineNodeTypeDecorator.DesignGuidelineNodeTypeProperties.get(
            DesignGuidelineNodeTypeDecorator.unknown
         )!
      );
   }

   static get AllItems(): DesignGuidelineNodeTypeDecorator[] {
      return Array.from(DesignGuidelineNodeTypeDecorator.DesignGuidelineNodeTypeProperties.keys()).map(
         (status: DesignGuidelineNodeType) => new DesignGuidelineNodeTypeDecorator(status)
      );
   }

   private get properties(): IDesignGuidelineNodeTypeProperties {
      return DesignGuidelineNodeTypeDecorator.DesignGuidelineNodeTypeProperties.get(this.value)!;
   }

   private static readonly DesignGuidelineNodeTypeProperties: Map<
      DesignGuidelineNodeType,
      IDesignGuidelineNodeTypeProperties
   > = new Map<DesignGuidelineNodeType, IDesignGuidelineNodeTypeProperties>([
      [
         DesignGuidelineNodeType.Field,
         { icon: "mdi-cube", legacyLabel: "Field", translationKey: "contentBrickTreeNodeType.field" },
      ],
      [
         DesignGuidelineNodeType.ValueField,
         { icon: "mdi-cube-scan", legacyLabel: "Value Field", translationKey: "contentBrickTreeNodeType.valueField" },
      ],
      [
         DesignGuidelineNodeType.Group,
         { icon: "mdi-file-tree-outline", legacyLabel: "Group", translationKey: "contentBrickTreeNodeType.group" },
      ],
      [
         DesignGuidelineNodeTypeDecorator.unknown,
         {
            icon: "mdi-alert-circle",
            legacyLabel: "Unknown node type",
            translationKey: "contentBrickTreeNodeType.unknown",
         },
      ],
   ]);
}
