import { IItemReference } from "@backend/api/pmToolApi";

class RouterUtils {
   public getDataModelDetailRouteValue(item: IItemReference, nodeId?: string): object {
      let routeName: string | undefined = undefined;
      if (item.code?.startsWith("DDM")) routeName = "domain-data-model-detail";
      else if (item.code?.startsWith("GDM") || item.code?.startsWith("LDM")) routeName = "library-detail";
      else throw `Unsupported entity code: '${item.code}' when getting item link.`;

      if (nodeId) routeName += "-node";
      return {
         name: routeName,
         params: { id: item.id, nodeId: nodeId },
      };
   }
}
export default new RouterUtils();
