<template>
   <!--<td class="pr-0 pl-0" :colspan="headers.length + 1" v-if="item != null">
        <v-simple-table>
            <tr v-for="child in item.children" :key="child.id">
                <div class="expandedRow">
                    <td class="expandedTd"><button @click="expanded = !expanded">tad</button></td>
                    <td class="expandedTd" v-for="header in headers.slice(1)" :key="header.value">{{child[header.value]}}</td>
                </div>
                <div :class="{ 'd-none': !expanded}" class="expandedRow" v-if="item.children && item.children.length > 0">
                    <tree-data-table-row :item="child" :headers="headers" />
                </div>
            </tr>
        </v-simple-table>
    </td>-->
   <v-main class="ml-4">
      <v-row v-for="child in item.children" :key="child.id">
         <v-col>
            <button @click="expandItem(child)">tad</button>
         </v-col>
         <v-col v-for="header in headers" :key="header.value">
            {{ child[header.value] }}
         </v-col>
      </v-row>
      <v-row :class="{ 'd-none': !expanded }">
         <tree-data-table-row :item="expandedItem" :headers="headers" />
      </v-row>
   </v-main>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
   name: "TreeDataTableRow",
})
export default class TreeDataTableRow extends Vue {
   @Prop({ default: [] })
   headers: object[];

   @Prop({ default: [] })
   item: object;

   expanded: boolean = false;
   expandedItem: object = null;

   expandItem(item: object) {
      this.expanded = !this.expanded;
      this.expandedItem = item;
   }
}
</script>
