import { IPublicModelBase } from "@backend/api/pmToolApi";

type LoadCallback<TEntity> = () => Promise<TEntity[] | undefined>;

export default class SharedLookup<TEntity extends IPublicModelBase> {
   public loading: boolean = true;
   private valueProvider: LoadCallback<TEntity>;
   private timestamp: number;
   private __cache: { [key: string]: TEntity };

   get cache(): { readonly [key: string]: TEntity } {
      return this.__cache || {};
   }

   private set cache(newValue: { [key: string]: TEntity }) {
      this.__cache = newValue;
      this.timestamp = Date.now();
   }

   constructor(valueProvider: LoadCallback<TEntity>, load: boolean = true) {
      this.valueProvider = valueProvider;
      if (load) {
         this.Reload();
      }
   }

   public async Reload() {
      this.loading = true;
      try {
         let entities = await this.valueProvider();

         if (entities) {
            this.cache = entities.reduce((values, current) => {
               values[current.id] = current;
               return values;
            }, <{ [key: string]: TEntity }>{});
         }
      } finally {
         this.loading = false;
      }
   }
}
