<template></template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import BaseResponse from "@models/BaseResponse";
import * as moment from "moment";
import { TranslationApi, TranslationPublicModel } from "@backend/api/pmToolApi";
import { getTranslationValue } from "@utils/TranslationUtils";
import globalStore from "@backend/store/globalStore";
import VueRouter, { Route } from "vue-router";

import TranslationCachedApi from "@backend/store/apiCache/TranslationCachedApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

@Component({
   name: "ComponentBase",
})
export default class ComponentBase extends Vue {
   notifySuccess(actionName: string, subjectName: string, plural: boolean = false) {
      var verb: string = plural ? "have" : "has";
      EventBus.$emit(Events.DisplayToast, {
         color: "success",
         text: `${subjectName} ${verb} been ${actionName}`,
      });
   }

   notifyError(error: BaseResponse, actionName: string, subjectName: string) {
      if (error.errors && Object.keys(error.errors).length > 0) {
         for (var prop in error.errors) {
            EventBus.$emit(Events.DisplayToast, {
               color: "error",
               text: `Failed to ${actionName} ${subjectName}: ${error.errors[prop]}`,
            });
         }
      } else {
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to ${actionName} ${subjectName}: ${error.message}`,
         });
      }
   }

   getErrorMessages(error: BaseResponse, actionName: string, subjectName: string): string[] {
      if (error.errors && Object.keys(error.errors).length > 0) {
         var errors: string[] = [];
         for (var prop in error.errors) {
            errors.push(`Failed to ${actionName} ${subjectName}: ${error.errors[prop]}`);
         }
         return errors;
      } else {
         return [`Failed to ${actionName} ${subjectName}: ${error.message}`];
      }
   }

   notifyInfo(message: string) {
      EventBus.$emit(Events.DisplayToast, {
         color: "info",
         text: message,
      });
   }

   formatUtcDate(date: string | null): string | undefined {
      return date ? moment.utc(date).local().format("l") : undefined;
   }

   formatUtcDateTime(date: moment.Moment | null): string | undefined {
      return date ? moment.utc(date).local().format("l LT") : undefined;
   }

   formatUtcDateTimeSeconds(date: moment.Moment | null): string | undefined {
      return date ? moment.utc(date).local().format("l LTS") : undefined;
   }

   formatDate(date: string | null): string | undefined {
      return date ? moment.utc(date).format("l") : undefined;
   }

   formatDateTime(date: moment.Moment | null): string | undefined {
      return date ? moment.utc(date).format("l LT") : undefined;
   }

   formatDateTimeSeconds(date: moment.Moment | null): string | undefined {
      return date ? moment.utc(date).format("l LTS") : undefined;
   }

   updateWindowTitle(prefix: string | undefined, postfix: string | undefined = undefined) {
      document.title = document.title.replace(/(.+) - (.+)/, `${prefix ?? "$1"} - ${postfix ?? "$2"}`);
   }

   // translations
   routeTranslations: TranslationPublicModel[] = [];

   async loadRouteTranslations(routeNames: string | string[]) {
      if (!Array.isArray(routeNames)) routeNames = [routeNames];
      let selectedLanguage = globalStore.getLanguage();
      let translationResponse = selectedLanguage
         ? await TranslationCachedApi.getBatchRouteTranslations(routeNames, selectedLanguage.id)
         : await TranslationCachedApi.getBatchRouteDefaultTranslations(routeNames);
      this.routeTranslations = translationResponse ?? [];
   }

   translateKey(key: string, translations: TranslationPublicModel[] | null = null): string {
      return getTranslationValue(key, translations ?? this.routeTranslations);
   }

   translateHeaders(headers: any[], translations: TranslationPublicModel[] | null = null) {
      for (var header of headers) {
         header.text = this.translateKey(header.translationKey, translations ?? this.routeTranslations);
      }
      return headers;
   }

   getDecoratorText(item: IEnumBaseProperties, translations: TranslationPublicModel[] | null = null): string {
      return `${this.translateKey(item.translationKey, translations)}`;
   }

   getSortedByDecoratorText(items: IEnumBaseProperties[], translations: TranslationPublicModel[]) {
      const result = [...items];
      const translate = (x: IEnumBaseProperties) => this.getDecoratorText(x, translations);
      result.sort((a, b) => translate(a).localeCompare(translate(b)));
      return result;
   }
}
</script>
