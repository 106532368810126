import { IDataModelNode } from "@backend/api/pmToolApi";
export default class DataModelNodeQueryError extends Error {
    lastCorrectNode: IDataModelNode;

    constructor(m: string, lastCorrectNode: IDataModelNode) {
       super(m);

       this.lastCorrectNode = lastCorrectNode;
  
       Object.setPrototypeOf(this, DataModelNodeQueryError.prototype);
    }
 }