<template>
   <v-btn
      hide-details
      @click.stop="onClick()"
      @mouseup.stop.prevent
      class="justify-center white"
      icon
      width="15px"
      height="15px"
      v-bind="$attrs"
   >
      <v-icon class="red--text">$delete</v-icon>
   </v-btn>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
   name: "AttachmentDeleteBtn",
})
export default class AttachmentDeleteBtn extends Vue {
   onClick() {
      this.$emit("click");
   }
}
</script>
<style scoped>
</style>