const ScriptOnLoadSignature = `void OnLoad(JToken domainData, JToken processData, Dictionary<string, Field> fields, Utils utils)
{    
    // ON LOAD SCRIPT //
}

class Field
{
    bool Visible { get; set; } = true;
    bool Readonly { get; set; } = false;
    object Value { get; set; }
}

class Utils
{
    int? CurrentIteration { get; }
}`;

const ScriptOnValidationSignature = `void OnValidation(JToken domainData, JToken processData, Dictionary<string, Field> fields, ValidationResult validationResult, Utils utils)
{
    // ON VALIDATION SCRIPT //
}

class ValidationResult
{
    bool Success { get; set; } = true;
    string ErrorMessage { get; set; } = null;
}

class Field
{
    bool Visible { get; } = true;
    bool Readonly { get; } = false;
    object Value { get; }
}

class Utils
{
    int? CurrentIteration { get; }
}`;

export { ScriptOnLoadSignature, ScriptOnValidationSignature };
