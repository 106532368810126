import { EntityStatus } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IStatusProperties extends IEnumBaseProperties {
   readonly color: string;
}

export class EntityStatusDecorator implements IStatusProperties {
   value: EntityStatus;
   private static readonly unkown: number = -1;

   constructor(value: EntityStatus) {
      this.value = value;
   }

   get color(): string {
      return this.properties.color;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   static get AllItems(): EntityStatusDecorator[] {
      return Array.from(EntityStatusDecorator.entityStatusProperties.keys())
         .filter((status: number) => status != EntityStatusDecorator.unkown)
         .map((status: number) => new EntityStatusDecorator(status));
   }

   static get GetActiveAndInactive(): EntityStatusDecorator[] {
      return Array.from(EntityStatusDecorator.entityStatusProperties.keys())
         .filter((status: number) => status != EntityStatus.Draft && status != EntityStatusDecorator.unkown)
         .map((status: number) => new EntityStatusDecorator(status));
   }

   static get GetDraftOnly(): EntityStatusDecorator[] {
      return Array.from(EntityStatusDecorator.entityStatusProperties.keys())
         .filter(
            (status: number) =>
               status != EntityStatus.Active && status != EntityStatusDecorator.unkown && status != EntityStatus.Deleted
         )
         .map((status: number) => new EntityStatusDecorator(status));
   }

   private get properties(): IStatusProperties {
      return (EntityStatusDecorator.entityStatusProperties.get(this.value) ??
         EntityStatusDecorator.entityStatusProperties.get(EntityStatusDecorator.unkown))!;
   }

   private static readonly entityStatusProperties: Map<number, IStatusProperties> = new Map<number, IStatusProperties>([
      [EntityStatus.Draft, { translationKey: "entityStatus.draft", color: "#aed6f1" }],
      [EntityStatus.Active, { translationKey: "entityStatus.active", color: "#6dab6a" }],
      [EntityStatus.Deleted, { translationKey: "entityStatus.deleted", color: "#d16058" }],
      [EntityStatusDecorator.unkown, { translationKey: "entityStatus.unknown", color: "#dddddd" }],
   ]);
}
