import { ContentBrickScriptType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IContentBrickTypeProperties extends IEnumBaseProperties {}

export class ContentBrickScriptTypeDecorator implements IContentBrickTypeProperties {
   value: ContentBrickScriptType;
   private static readonly unkown: number = -1;

   constructor(value: ContentBrickScriptType) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   static get AllItems(): ContentBrickScriptTypeDecorator[] {
      return Array.from(ContentBrickScriptTypeDecorator.ContentBrickScriptTypeProperties.keys())
         .filter((status: number) => status != ContentBrickScriptTypeDecorator.unkown)
         .map((status: number) => new ContentBrickScriptTypeDecorator(status));
   }

   private get properties(): IContentBrickTypeProperties {
      return (
         ContentBrickScriptTypeDecorator.ContentBrickScriptTypeProperties.get(this.value) ??
         ContentBrickScriptTypeDecorator.ContentBrickScriptTypeProperties.get(ContentBrickScriptTypeDecorator.unkown)!
      );
   }

   private static readonly ContentBrickScriptTypeProperties: Map<number, IContentBrickTypeProperties> = new Map<
      number,
      IContentBrickTypeProperties
   >([
      [ContentBrickScriptType.OnLoad, { translationKey: "contentBrickScriptType.onLoad" }],
      [ContentBrickScriptType.OnValidate, { translationKey: "contentBrickScriptType.onValidate" }],
      [ContentBrickScriptTypeDecorator.unkown, { translationKey: "contentBrickScriptType.unknown" }],
   ]);
}
