<template>
   <v-menu
      v-model="showDateTimeDialog"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      :disabled="readonly"
      offset-y
      min-width="290px"
   >
      <template v-slot:activator="{ on, attrs }">
         <v-text-field
            :value="dateTimeLocalized"
            :placeholder="placeholder"
            :label="label"
            :class="{ 'pt-0': !label, 'mt-0': !label, 'field-editable': !readonly }"
            :rules="rules"
            readonly
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
         >
              <template v-for="(index, name) in $slots" v-slot:[name]>
                 <slot :name="name" />
              </template>
            <template v-slot:prepend>
               <v-icon :class="{ 'mt-2': !label }">{{ format.icon }}</v-icon>
            </template>
            <template v-slot:append>
               <v-btn v-if="clearable !== false && !readonly" icon text link class="clear-btn" @click="onClear">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
            </template>
         </v-text-field>
      </template>
      <v-container class="pa-0 d-flex" fluid>
         <v-date-picker
            v-if="date"
            v-model="dateVM"
            :class="{ 'v-picker-l': time }"
            @change="onDateChanged(true)"
         ></v-date-picker>
         <v-time-picker
            v-if="time"
            v-model="timeVM"
            :class="{ 'v-picker-r': date }"
            :format="timePickerFormat"
            use-seconds
            @change="onTimeChanged"
         >
            <template>
               <v-spacer></v-spacer>
               <v-btn text color="error" @click="onTimeChanged">OK</v-btn>
            </template>
         </v-time-picker>
      </v-container>
   </v-menu>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ValidationRules from "@models/shared/ValidationRules";
import DateTimeUtils, { DateTimeFormatInfo } from "@utils/DateTimeUtils";
import * as moment from "moment";
import { IRegularExpression } from "@backend/api/pmToolApi";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";

@Component({
   name: "FieldDateTimePicker",
   components: {},
})
export default class FieldDateTimePicker extends Vue {
   showDateTimeDialog: boolean = false;
   dateTime: moment.Moment | null = null;
   dateVM: string | null = null;
   timeVM: string | null = null;

   @Prop({ default: null, required: true })
   value: string | null;

   @Prop({ default: "" })
   label: string;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: false })
   clearable: boolean;

   @Prop({ default: () => [] })
   rules: ((v: any) => true | string)[];

   @Prop({ default: true })
   date: boolean;

   @Prop({ default: false })
   time: boolean;

   @Prop({ default: null })
   regularExpression: IRegularExpression | null;

   @Watch("value")
   onValueChanged(value: string | null) {
      if (!value) {
         this.dateTime = null;
      } else {
         this.dateTime = moment(value, this.format.parse, true);
         this.dateVM = this.dateTime.toISOString(true).substr(0, 10);
         this.timeVM = this.dateTime.toISOString(true).substr(11, 8);
      }
   }

   // ---------- Regular Expression ----------
   hasRegularExpresion(): boolean {
      return this.regularExpression ? true : false;
   }

   getFormatedValueByRegex(): string | null {
      if (this.hasRegularExpresion() && this.dateTime) {
         let value = this.updatedValue;
         let formattedValue: string | undefined = value ?? undefined;

         if (this.regularExpression?.formatRegexes?.length) {
            formattedValue = RegularExpressionUtils.formatByRegexProperties(
               formattedValue,
               this.regularExpression.validationRegex,
               this.regularExpression.formatRegexes
            );

            if (formattedValue !== undefined) {
               return formattedValue;
            }
         }
         return value !== undefined ? value : null;
      } else {
         return !this.dateTime ? null : this.dateTime?.format(this.format.display);
      }
   }

   mounted() {
      this.onValueChanged(this.value);
   }

   get format(): DateTimeFormatInfo {
      return DateTimeUtils.getFormat(this.date, this.time);
   }

   get placeholder(): string {
      return this.format.placeholder;
   }

   get dateTimeLocalized(): string | null {
      return this.hasRegularExpresion()
         ? this.getFormatedValueByRegex()
         : DateTimeUtils.getDateTimeLocalized(this.dateTime, this.format);
   }

   get timePickerFormat() {
      return moment().format("LT").length > 5 ? "ampm" : "24hr";
   }

   get updatedValue(): string | null {
      return this.date ? (this.time ? `${this.dateVM}T${this.timeVM}` : this.dateVM) : this.timeVM;
   }

   onTimeChanged() {
      this.$emit("input", this.updatedValue);
      this.showDateTimeDialog = false;
   }

   onDateChanged(isPicker: boolean) {
      if (!isPicker || !this.time) {
         this.$emit("input", this.updatedValue);
         this.showDateTimeDialog = false;
      }
   }

   onClear() {
      this.$emit("input", null);
   }
}
</script>
<style lang="scss" scoped>
::v-deep .v-time-picker-title__time .v-picker__title__btn,
::v-deep .v-time-picker-title__time span {
   height: 56px;
   font-size: 56px;
}

/*when label+margin is removed from component, input must reflect it*/
.v-input.mt-0 ::v-deep.v-text-field__slot {
   margin-top: 8px;
}

.v-picker {
   &-l {
      border-radius: 4px 0px 4px 4px;
   }
   &-r {
      border-radius: 0px 4px 4px 4px;
   }
}

.clear-btn {
   width: 24px;
   height: 24px;
}
</style>
