import Vue from "vue";
import Vuetify from "vuetify";
import cs from "vuetify/src/locale/cs";
import en from "vuetify/src/locale/en";
import ru from "vuetify/src/locale/ru";
import fr from "vuetify/src/locale/fr";
import de from "vuetify/src/locale/de";
import zhHans from "vuetify/src/locale/zh-Hans";
import ko from "vuetify/src/locale/ko";
import sk from "vuetify/src/locale/sk";

import light from "../styles/theme";
import dark from "../styles/theme";

import IconSwitchTrue from "@components/Shared/icon-switch-true.vue";
import IconSwitchFalse from "@components/Shared/icon-switch-false.vue";
import IconSwitchIndeterminate from "@components/Shared/icon-switch-indeterminate.vue";

Vue.use(Vuetify);

let userLang = "";

let lang = window.navigator.language;
switch (lang.toLowerCase()) {
   case "cn":
      userLang = "zh-cn";
      break;
   case "fr":
      userLang = "fr";
      break;
   case "cs":
      userLang = "cs";
      break;
   case "ru":
      userLang = "ru";
      break;
   case "de":
      userLang = "de";
      break;
   case "ko":
      userLang = "ko";
      break;
   case "sk":
      userLang = "sk";
      break;
   default:
      userLang = "en";
      break;
}

export default new Vuetify({
   lang: {
      locales: { "zh-cn": zhHans, cs, en, ru, fr, de, ko, sk },
      current: userLang,
   },
   theme: {
      themes: { dark, light },
      options: { customProperties: true },
   },
   breakpoint: {
      mobileBreakpoint: "xs", // < 600px
   },
   icons: {
      values: {
         switchTrue: {
            // custom icon name -> $switchTrue
            component: IconSwitchTrue, // custom icon component
         },
         switchFalse: {
            // custom icon name -> $switchFalse
            component: IconSwitchFalse, // custom icon component
         },
         switchIndeterminate: {
            // custom icon name -> $switchIndeterminate
            component: IconSwitchIndeterminate, // custom icon component
         },
      },
   },
});
