<template>
   <a :href="helpdeskUrl" target="_blank" class="red--text text--darken-2">
      <v-icon color="red darken-2">mdi-help-circle-outline</v-icon>
      {{ translateKey("helpdesk.helpLabel") }}
   </a>
</template>

<script lang="ts">
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { Component, Vue } from "vue-property-decorator";
import ComponentBase from "./Base/component-base.vue";

@Component({
   name: "HelpdeskNavButton",
})
export default class HelpdeskNavButton extends ComponentBase {
   routeNames: string[] = ["helpdesk"];

   helpdeskUrl: string = "https://einhell.atlassian.net/servicedesk/customer/portal/8";

   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => this.loadRouteTranslations(this.routeNames));
   }
}
</script>

<style scoped></style>
