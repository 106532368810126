import { render, staticRenderFns } from "./export-pdf-design-guideline-history.vue?vue&type=template&id=5b78c817&scoped=true"
import script from "./export-pdf-design-guideline-history.vue?vue&type=script&lang=ts"
export * from "./export-pdf-design-guideline-history.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b78c817",
  null
  
)

export default component.exports