<template>
   <div class="vqb-group card" :class="'depth-' + depth.toString()">
      <div class="vqb-group-heading card-header">
         <div class="match-type-container form-inline">
            <label class="mr-3 mt-1" for="vqb-match-type">
               {{ labels.matchType }}
            </label>
            <v-select
               id="vqb-match-type"
               :items="labels.matchTypes"
               v-model="query.logicalOperator"
               :readonly="readonly"
               item-text="label"
               item-value="id"
               hide-details
               dense
               outlined
               class="flex-grow-0"
            />
            <div class="ml-5 form-group" v-if="!readonly">
               <v-select
                  v-model="selectedRule"
                  class="ml-2 flex-grow-0"
                  :items="rules"
                  item-text="label"
                  return-object
                  flex="0 1 auto"
                  hide-details
                  dense
                  outlined
               />
               <v-btn class="ml-2" @click="addRule" elevation="2">
                  <v-icon color="success">mdi-plus</v-icon>
                  <span>{{ labels.addRule }}</span>
               </v-btn>
               <v-btn class="ml-2" v-if="depth < maxDepth" @click="addGroup" elevation="2">
                  <v-icon color="success">mdi-plus</v-icon>
                  <span>{{ labels.addGroup }}</span>
               </v-btn>
            </div>
            <v-btn v-if="depth > 1 && !readonly" icon elevation="2" class="ml-auto" @click="remove">
               <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
         </div>
      </div>
      <div class="vqb-group-body card-body">
         <div class="vqb-children">
            <component
               :is="getComponent(child.type)"
               v-for="(child, index) in query.children"
               :key="index"
               :type="child.type"
               :query.sync="child.query"
               :rule-types="ruleTypes"
               :rules="rules"
               :rule="ruleById(child.query.rule)"
               :index="index"
               :max-depth="maxDepth"
               :depth="depth + 1"
               :labels="labels"
               :readonly="readonly"
               @child-deletion-requested="removeChild"
            />
         </div>
      </div>
   </div>
</template>
<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import QueryBuilderGroup from "vue-query-builder/dist/group/QueryBuilderGroup.umd.js";
import QueryBuilderRule from "./vuetify-rule.vue";

export default {
   name: "QueryBuilderGroup",

   components: {
      QueryBuilderRule: QueryBuilderRule,
   },
   props: ["readonly"],
   data() {
      return {
         groupComponent: null,
         ruleComponent: null,
      };
   },

   mounted() {
      this.groupComponent = this.$options.components["QueryBuilderGroup"];
      this.ruleComponent = this.$options.components["QueryBuilderRule"];
   },

   methods: {
      getComponent(type) {
         return type === "query-builder-group" ? this.groupComponent : this.ruleComponent;
      },
   },
   extends: QueryBuilderGroup,
};
</script>

<style>
.vue-query-builder .vqb-group .rule-actions {
   margin-bottom: 20px;
   display: block;
}

.vue-query-builder .vqb-rule {
   margin-top: 15px;
   margin-bottom: 15px;
   background-color: #f5f5f5;
   border-color: #ddd;
   padding: 15px;
}

.vue-query-builder .vqb-group.depth-1 .vqb-rule,
.vue-query-builder .vqb-group.depth-2 {
   border-left: 2px solid #8bc34a;
}

.vue-query-builder .vqb-group.depth-2 .vqb-rule,
.vue-query-builder .vqb-group.depth-3 {
   border-left: 2px solid #00bcd4;
}

.vue-query-builder .vqb-group.depth-3 .vqb-rule,
.vue-query-builder .vqb-group.depth-4 {
   border-left: 2px solid #ff5722;
}

.vue-query-builder .close {
   opacity: 1;
   color: rgb(150, 150, 150);
}

.vqb-group .card-header {
   padding-right: 15px; /*align with Rule delete button*/
}

@media (min-width: 768px) {
   .vue-query-builder .vqb-rule.form-inline .form-group {
      display: block;
   }
}
</style>
