<!--<template>
    <v-data-table :headers="headers"
                  :items="items"
                  item-key="name"
                  show-expand>
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>PBB Tree view</v-toolbar-title>
            </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers,item }">
            <tree-data-table-row :item="item" :headers="headers" />
        </template>
    </v-data-table>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from "vue-property-decorator";
    import TreeDataTableRow from "./tree-data-table-row.vue";

    @Component({
        name: "TreeDataTable",
        components: {
            TreeDataTableRow
        }
    })
    export default class TreeDataTable extends Vue {

        @Prop({ default: [] })
        headers: object[];

        @Prop({ default: [] })
        items: object[];


        expanded: boolean = false;

    }
</script>-->
<template>
   <v-main class="ma-3">
      <v-row class="ml-2">
         <v-col>Expand</v-col>
         <v-col v-for="header in headers" :key="header.value">
            {{ header.text }}
         </v-col>
      </v-row>
      <v-row v-for="item in items" :key="item.id" class="ml-2">
         <v-col>
            <button @click="expandItem(item)">tad</button>
         </v-col>
         <v-col v-for="header in headers" :key="header.value" class="ma-2">
            {{ item[header.value] }}
         </v-col>
      </v-row>
      <v-row :class="{ 'd-none': !expanded }">
         <tree-data-table-row :item="expandedItem" :headers="headers" />
      </v-row>
   </v-main>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import TreeDataTableRow from "./tree-data-table-row.vue";

@Component({
   name: "TreeDataTable",
   components: {
      TreeDataTableRow,
   },
})
export default class TreeDataTable extends Vue {
   @Prop({ default: [] })
   headers: object[];

   @Prop({ default: [] })
   items: object[];

   expanded: boolean = false;
   expandedItem: object = null;

   expandItem(item: object) {
      this.expanded = !this.expanded;
      this.expandedItem = item;
   }
}
</script>
