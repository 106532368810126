import { BooleanFieldLayout } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IBooleanFieldLayoutProperties extends IEnumBaseProperties {}

export class BooleanFieldLayoutDecorator implements IBooleanFieldLayoutProperties {
   value: BooleanFieldLayout;
   private static readonly unkown: number = -1;

   constructor(value: BooleanFieldLayout) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   static get AllItems(): BooleanFieldLayoutDecorator[] {
      return Array.from(BooleanFieldLayoutDecorator.entityStatusProperties.keys())
         .filter((status: number) => status != BooleanFieldLayoutDecorator.unkown)
         .map((status: number) => new BooleanFieldLayoutDecorator(status));
   }

   private get properties(): IBooleanFieldLayoutProperties {
      return (
         BooleanFieldLayoutDecorator.entityStatusProperties.get(this.value) ??
         BooleanFieldLayoutDecorator.entityStatusProperties.get(BooleanFieldLayoutDecorator.unkown)!
      );
   }

   private static readonly entityStatusProperties: Map<number, IBooleanFieldLayoutProperties> = new Map<
      number,
      IBooleanFieldLayoutProperties
   >([
      [BooleanFieldLayout.Switch, { translationKey: "booleanFieldLayout.switch" }],
      [BooleanFieldLayout.Checkbox, { translationKey: "booleanFieldLayout.checkbox" }],
   ]);
}
