import UserDto from "../../models/user/UserDto";

const LocalStorageKeys = {
   CURRENT_USER: "CURRENTUSER",
   LOGIN_NAME: "userLoginName",
   LOGIN_USER_ID: "userId",
   LOGIN_LAST_NAME: "userLoginLastName",
   LOGIN_EMAIL: "userLoginEmail",
   LOGIN_PASSWORD: "loginPassword",
};

const store = {
   setUserName: function (loginName) {
      localStorage.setItem(LocalStorageKeys.LOGIN_NAME, loginName);
   },
   getUserName: function () {
      return localStorage.getItem(LocalStorageKeys.LOGIN_NAME);
   },
   setUserId: function (loginUserId) {
      localStorage.setItem(LocalStorageKeys.LOGIN_USER_ID, loginUserId);
   },
   getUserId: function () {
      return localStorage.getItem(LocalStorageKeys.LOGIN_USER_ID);
   },
   setUserEmail: function (loginEmail) {
      localStorage.setItem(LocalStorageKeys.LOGIN_EMAIL, loginEmail);
   },
   getUserEmail: function () {
      return localStorage.getItem(LocalStorageKeys.LOGIN_EMAIL);
   },
   setUserLastName: function (loginLastName) {
      localStorage.setItem(LocalStorageKeys.LOGIN_LAST_NAME, loginLastName);
   },
   getUserLastName: function () {
      return localStorage.getItem(LocalStorageKeys.LOGIN_LAST_NAME);
   },
   setUserPassword: function (loginPassword) {
      localStorage.setItem(LocalStorageKeys.LOGIN_PASSWORD, loginPassword);
   },
   getUserPassword: function () {
      return localStorage.getItem(LocalStorageKeys.LOGIN_PASSWORD);
   },
   saveCurrentUser(user: UserDto): void {
      localStorage.setItem(LocalStorageKeys.CURRENT_USER, JSON.stringify(user));
   },
   clearCurrentUser(): void {
      localStorage.removeItem(LocalStorageKeys.CURRENT_USER);
   },
   getCurrentUser(): UserDto {
      var userJson = localStorage.getItem(LocalStorageKeys.CURRENT_USER);

      if (!userJson) return null;

      return JSON.parse(userJson);
   },
   isUserLoggedIn(): boolean {
      return true;
   },
};

export default store;
