import ITreeviewNode from "@models/treeview/ITreeviewNode";

class TreeviewUtils {
   /**
    * Expands nodes provided via their keys, collapses all other nodes (not present in keys)
    * @param keys Keys of nodes to expand
    * @param store Node store to use to expand nodes
    */
   public setDefaultExpandedAndCollapsedKeys(keys, store) {
      keys = keys || [];

      const allNodes = store._getAllNodes();

      for (const node of allNodes) {
         node.collapse();
      }

      store.setDefaultExpandedKeys(keys);
   }

   /**
    * Expands and then collapses (or collapses and expands) given node, triggering UI refresh of the node
    * @param treeNode Node to expand and collapse
    */
   public refreshExpand(treeNode: ITreeviewNode<any>) {
      if (treeNode.expanded) {
         treeNode.collapse();
         treeNode.expand();
      } else {
         treeNode.expand();
         treeNode.collapse();
      }
   }

   /**
    * Gets nodes in path from root node to the provided node
    * @param node Node to get path of
    * @param treeView Treeview component reference
    * @returns Path of nodes from root to @param node
    */
   public getNodePath<TNode>(node: TNode, treeView: any): TNode[] {
      return treeView.getNodePath(node) as TNode[];
   }

   /**
    * Scrolls Treeview component to the node with the provided ID
    * @param nodeId ID of the node to sroll to
    * @param treeView Treeview component reference
    */
   public scrollToItem(nodeId: string, treeView: any) {
      treeView.scrollToItem(nodeId);
   }
}
export default new TreeviewUtils();
