<template>
   <v-btn elevation="2" icon @click="isExpanded = !isExpanded">
      <v-icon>{{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
   </v-btn>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import GlobalStore from "@backend/store/globalStore";

@Component({
   name: "DetailHeaderExpandButton",
   components: {},
})
export default class DetailHeaderExpandButton extends Vue {
   @Prop()
   value: boolean | null;

   //-------------- Expansion ---------------
   isExpandedInternal: boolean | null = null;

   get isExpanded(): boolean {
      if (this.isExpandedInternal === null) {
         var storedExpanded = GlobalStore.getEntityHeaderExpanded();
         this.$emit("input", storedExpanded);
         return storedExpanded;
      }

      return this.isExpandedInternal;
   }

   set isExpanded(value: boolean) {
      this.isExpandedInternal = value;
      this.$emit("input", value);
      GlobalStore.setEntityHeaderExpanded(value);
   }

   mounted() {
      this.isExpandedInternal = this.value;
   }
}
</script>
<style></style>
