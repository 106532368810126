class DomUtils {
   /**
    * Removes an element from DOM by a provided XPath
    * @param xpath XPath by which to find the element to remove from DOM
    */
   public removeElementByXpath(xpath: string): void {
      var element = this.getElementByXpath(xpath);
      element?.parentNode?.removeChild(element);
   }

   /**
    * Removes provided element from DOM
    * @param element Element to remove
    */
   public removeElement(element: HTMLElement): void {
      element?.parentNode?.removeChild(element);
   }

   /**
    * Renames provided element
    * @param element Element to rename
    */
   public renameElement(element: HTMLElement, value: string): void {
      element.innerText = value;
   }

   /**
    * Gets an element from DOM by a provided XPath
    * @param xpath XPath by which to find the element in DOM
    * @returns Found HTMLElement, null otherwise
    */
   public getElementByXpath(xpath: string): HTMLElement | null {
      var element = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
      return element as HTMLElement | null;
   }

   /**
    * Gets all elements from DOM matching a provided XPath
    * @param xpath XPath by which to find elements in DOM
    * @returns Sequence of found HTMLElements
    */
   public getElementsByXpath(xpath: string): HTMLElement[] {
      var elementsIterator = document.evaluate(xpath, document, null, XPathResult.UNORDERED_NODE_ITERATOR_TYPE, null);
      var elements: HTMLElement[] = [];
      try {
         var currentElement = elementsIterator.iterateNext();
         while (currentElement) {
            elements.push(currentElement as HTMLElement);

            currentElement = elementsIterator.iterateNext();
         }
      }
      catch (e) {
         console.log("Error: Document tree modified during iteration" + e);
      }
      return elements;
   }

   /**
    * Gets an element from DOM by a provided element ID
    * @param id ID by which to find the element in DOM
    * @returns Found HTMLElement, null otherwise
    */
   public getElementById(id: string): HTMLElement | null {
      var element = document.getElementById(id);
      return element as HTMLElement | null;
   }

   /**
    * Sets value of the element from DOM by a provided element ID
    * @param id ID by which to find the element in DOM
    */
   public setElementValueById(id: string, value: string) {
      var element = this.getElementById(id);
      if (!element) {
         throw `Could not find element by provided ID: ${id}`;
      }
      element["value"] = value;
   }
}
export default new DomUtils();