<template>
   <div class="d-inline-flex px-5" @click.stop.prevent>
      <v-tooltip bottom>
         <template v-slot:activator="{ on }">
            <v-badge overlap bottom color="transparent" class="align-self-start" offset-x="16" offset-y="16">
               <v-icon v-on="on" color="purple">mdi-code-greater-than-or-equal</v-icon>
               <template v-slot:badge>
                  <v-icon v-on="on" :color="getContionCheckColor(value.conditionResult)" size="14" class="check-bg">
                     {{ getContionResultIcon(value.conditionResult) }}
                  </v-icon>
               </template>
            </v-badge>
         </template>
         <span>
            {{ getResultTooltip(value.conditionResult) }}
         </span>
      </v-tooltip>
      <three-state-switch
         v-model="value.manualResult"
         ref="manualResultSwitch"
         class="ml-1 custom-result-switch disable-shake"
         :readonly="readonly"
         color="error"
         label="Custom result"
         hide-details="auto"
         :allow-set-indeterminate="true"
         :rules="manualResultRules()"
         dense
         @change="$emit('manual-result-changed')"
      ></three-state-switch>
      <v-tooltip bottom>
         <template v-slot:activator="{ on }">
            <v-badge class="ml-2 align-self-start" overlap bottom color="transparent" offset-x="16" offset-y="16">
               <v-btn
                  v-on="on"
                  color="error"
                  class="manual-result-note-btn"
                  @click.stop.prevent="onEditNote"
                  icon
                  small
               >
                  <v-icon>mdi-account-details</v-icon>
               </v-btn>
               <template v-slot:badge>
                  <v-icon v-on="on" color="error" class="badge-interactive" @click.stop.prevent="onEditNote">
                     mdi-pencil
                  </v-icon>
               </template>
            </v-badge>
         </template>
         <span>{{ translateKey("taskDetail.testReportDialog.editManualResultNoteTooltip", translations) }}</span>
      </v-tooltip>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { TranslationPublicModel, DesignGuidelineFieldEvaluation } from "@backend/api/pmToolApi";
import DesignGuidelineResultDecorator from "@models/shared/DesignGuidelineResultDecorator";
import { TestResultTreeNode } from "@models/testReport/TestResultTree";
import ThreeStateSwitch from "@components/Shared/three-state-switch.vue";

@Component({
   name: "TaskTestReportNodeResult",
   components: { ThreeStateSwitch },
})
export default class TaskTestReportNodeResult extends ComponentBase {
   @Prop({ required: true })
   value: TestResultTreeNode;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Watch("value.manualResultNote")
   onNoteChanged() {
      this.$refs!["manualResultSwitch"]!.validate();
   }

   validateManualResult(): boolean | string {
      if (this.value.manualResult == undefined) return true;
      if (this.value.manualResult == this.value.conditionResult) return true;
      if (this.value.manualResultNote) return true;

      return "Note required";
   }

   manualResultRules() {
      return [
         () => this.validateManualResult(),
         () =>
            !(this.value.evaluationType === DesignGuidelineFieldEvaluation.Manual && this.value.manualResult == null) ||
            "Evaluation required",
      ];
   }

   // ------------- Group Result icon ----------------
   getContionResultIcon(result: boolean | undefined): string | undefined {
      return DesignGuidelineResultDecorator.get(result).icon;
   }

   getContionCheckColor(result: boolean | undefined): string | undefined {
      return DesignGuidelineResultDecorator.get(result).color;
   }

   getResultTooltip(result: boolean | undefined): string {
      const translationKey = DesignGuidelineResultDecorator.get(result).translationKey;
      return this.translateKey(translationKey, this.translations);
   }

   onEditNote() {
      this.$emit("edit-manual-result-note", {
         designGuidelineNodeResult: this.value,
      });
   }
}
</script>
<style scoped lang="scss">
.custom-result-switch {
   margin-top: 0;
   padding-top: 0;
}
</style>
