<template>
   <div>
      <v-chip
         v-if="propagateClick"
         class="status-chip"
         :class="pointer ? 'status-chip-pointer' : ''"
         light
         :color="color"
         label
         :text-color="textColor"
         @click.stop="onStatusClicked"
      >
         <span class="status-chip-text">{{ text }}</span>
      </v-chip>
      <v-chip
         v-else
         class="status-chip"
         :class="pointer ? 'status-chip-pointer' : ''"
         light
         :color="color"
         :text-color="textColor"
         label
      >
         <span class="status-chip-text">{{ text }}</span>
      </v-chip>
   </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
   components: {},
})
export default class Status extends Vue {
   @Prop({
      default: false,
   })
   pointer: boolean;

   @Prop({
      default: false,
   })
   propagateClick: boolean;

   @Prop({
      default: "",
   })
   text: string;

   @Prop({
      default: "gray",
   })
   color: string;

   @Prop({ default: undefined })
   isDark?: boolean;

   onStatusClicked() {
      this.$emit("clicked");
   }

   get textColor(): string {
      if (this.isDark !== undefined) {
         return this.isDark ? "white" : "black";
      }

      // previous implementation kept for compatibility with other status usages
      if (this.color != "#aed6f1") return "white";
      else return "black";
   }
}
</script>

<style scoped lang="scss">
.status {
   &-chip {
      &-text {
         min-width: 150px;
         text-align: center;
      }

      &-pointer {
         cursor: pointer;
      }
   }
}
</style>
