<template>
   <div :class="{ 'label-value-wrapper': true, 'label-width': addExtraNewline }">
      <v-row>
         <v-col class="pt-0 pb-0" :cols="squish ? 'auto' : false">
            <span class="label-value-header">{{ labelText }}</span>
         </v-col>
      </v-row>
      <v-row>
         <v-col class="pt-0 pb-0" :cols="squish ? 'auto' : false">
            <span v-if="value" class="label-value-text">{{ valueText }}</span>
            <slot v-else></slot>
         </v-col>
      </v-row>
   </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
   components: {},
})

/**
 * Component used for showing label with its value
 */
export default class LabelValue extends Vue {
   @Prop({ default: "" })
   label: string;

   @Prop({ default: "" })
   value: string;

   @Prop({ default: true })
   squish: boolean;

   @Prop({ default: true })
   addExtraNewline: boolean;

   get labelText(): string {
      return this.label ? this.label : "-";
   }

   get valueText(): string {
      return this.value ? this.value : "-";
   }

   mounted() {}
}
</script>

<style lang="scss" scoped>
.label-width {
   width: 95%;
}
.label-value {
   // &-wrapper {
   //     padding-bottom: 12px;
   // }

   &-header {
      // font-size: 14px;
      font-weight: bold;
      // color: rgb(70, 70, 70);
   }

   &-text {
      font-size: medium;
      color: rgb(70, 70, 70);
   }
}
</style>
