<template>
   <v-dialog v-model="showDialog" persistent max-width="800">
      <v-card>
         <v-card-title>
            <span>{{ translateKey("webcamRecordingDialog.title") }}</span>
            <v-spacer></v-spacer>
            <v-btn class="red--text" icon v-shortkey.once="['esc']" @shortkey="closeDialog" @click="closeDialog">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-row>
               <!-- Webcam Video Stream -->
               <v-col cols="12" md="6">
                  <video ref="video" autoplay playsinline style="max-width: 100%; height: auto"></video>

                  <!-- Capture Image Button -->
                  <v-btn v-if="!isVideo" color="error" block @click="captureImage">
                     <v-icon left>mdi-camera</v-icon>
                     {{ translateKey("webcamRecordingDialog.captureImage") }}
                  </v-btn>

                  <!-- Capture Video Button -->
                  <v-btn v-else color="error" block @click="toggleRecording">
                     <v-icon left>{{ isRecording ? "mdi-stop" : "mdi-record-circle" }}</v-icon>
                     {{
                        isRecording
                           ? translateKey("webcamRecordingDialog.stopRecording")
                           : translateKey("webcamRecordingDialog.startRecording")
                     }}
                  </v-btn>
               </v-col>

               <!-- Display Preview for Captured Images -->
               <v-col cols="12" md="6" class="text-center" v-if="!isVideo && capturedImage">
                  <v-img :src="capturedImage" max-width="100%"></v-img>
               </v-col>

               <!-- Display Preview for Recorded Video -->
               <v-col cols="12" md="6" class="text-center" v-if="isVideo && recordedVideo">
                  <video :src="recordedVideo" controls style="max-width: 100%; height: auto"></video>
               </v-col>

               <v-col cols="12" class="text-center"></v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <!-- Confirm file -->
            <v-btn class="red--text" text @click="confirmFile" :disabled="!mediaFile">
               {{ translateKey("webcamRecordingDialog.confirmFile") }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   name: "WebcamRecordingDialog",
})
export default class WebcamRecordingDialog extends ComponentBase {
   videoStream: MediaStream | null = null;
   mediaRecorder: MediaRecorder | null = null;
   recordedChunks: Blob[] = [];
   isRecording: boolean = false;
   capturedImage: string | null = null;
   recordedVideo: string | null = null;
   mediaFile: File | null = null;

   showDialog: boolean = false;

   @Prop({ default: false })
   isVideo: boolean;

   routeName: string = "webcam-recording-dialog";

   mounted() {
      this.loadRouteTranslations(this.routeName);
   }

   openDialog() {
      this.startWebcam();
      this.showDialog = true;
   }

   closeDialog() {
      this.stopWebcam();
      this.clearData();
      this.showDialog = false;
   }

   clearData() {
      this.mediaFile = null;
      this.capturedImage = null;
      this.recordedVideo = null;
   }

   // Start the webcam stream
   async startWebcam() {
      try {
         this.videoStream = await navigator.mediaDevices.getUserMedia({
            video: true,
         });
         const videoElement = this.$refs.video as HTMLVideoElement;
         if (videoElement && this.videoStream) {
            videoElement.srcObject = this.videoStream;
         }
      } catch (error) {
         console.error("Error accessing webcam:", error);
         this.notifyError({ message: error, success: false }, "access", "webcam");
      }
   }

   // Stop the webcam stream
   stopWebcam() {
      if (this.videoStream) {
         this.videoStream.getTracks().forEach((track) => track.stop());
      }
   }

   // Capture an image from the webcam stream
   captureImage() {
      const videoElement = this.$refs.video as HTMLVideoElement;
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
         this.capturedImage = canvas.toDataURL("image/png");

         // Convert the captured image to a File object
         canvas.toBlob((blob) => {
            if (blob) {
               const file = new File([blob], "captured-image.png", {
                  type: "image/png",
               });
               this.mediaFile = file;
            }
         }, "image/png");
      }
   }

   // Toggle video recording
   toggleRecording() {
      if (this.isRecording) {
         this.stopRecording();
      } else {
         this.startRecording();
      }
   }

   // Start recording video
   startRecording() {
      if (this.videoStream) {
         this.mediaRecorder = new MediaRecorder(this.videoStream);
         this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
               this.recordedChunks.push(event.data);
            }
         };
         this.mediaRecorder.onstop = this.handleRecordingStop;
         this.mediaRecorder.start();
         this.isRecording = true;
      }
   }

   // Stop recording video
   stopRecording() {
      if (this.mediaRecorder) {
         this.mediaRecorder.stop();
         this.isRecording = false;
      }
   }

   // Handle the recording stop event
   handleRecordingStop() {
      const blob = new Blob(this.recordedChunks, { type: "video/webm" });
      this.recordedVideo = URL.createObjectURL(blob);

      // Convert the recorded video to a File object
      const file = new File([blob], "recorded-video.webm", {
         type: "video/webm",
      });
      this.mediaFile = file;
      this.recordedChunks = [];
   }

   confirmFile() {
      if (this.mediaFile) {
         this.$emit("upload-attachment", this.mediaFile);
         this.closeDialog();
      }
   }
}
</script>

<style scoped></style>
