/**
 * Flag used for tagging GDM reference object propagation status
 */
export default class PropagatedGdmReferenceFlag {
   /**
    * GDM reference path of codes identifying level of GDM reference (in GDM reference tree)
    * @example For GDM references A (3) -> B (2) -> C (1), path is: "GDM000003/GDM000002/GDM000001"
    */
   path: string;
   /**
    * True if GDM reference is propagated to wrapping levels of GDM reference tree, false otherwise
    */
   isPropagated: boolean;

   constructor(path: string, isPropagated: boolean) {
      this.path = path;
      this.isPropagated = isPropagated;
   }
}
