import moment from "moment";
import { TagTypeEnum } from "../../models/filter/TagTypeEnum";
import { EntityType, IItemReference, ItemReference, VersionedFilterOptions } from "../api/pmToolApi";

const GridOperationStoreKeys = {
   COLUMNCONFIGURATION: "columnConfiguration",
   DEFAULTGRIDPAGESIZE: "defaultGridPageSize",
};

export interface ColumnConfiguration {
   gridKey: string;
   pageSize: number;
   columns: string[];
}

export enum GridName {
   ProjectDetailTabPbbList = "ProjectDetailTabPbbList",
   ProjectDetailTabPbbListDraft = "ProjectDetailTabPbbListDraft",
   ProjectDetailTabPbbListSimulation = "ProjectDetailTabPbbListSimulation",
}

class GridOperationStore {
   public columnConfigurations: ColumnConfiguration[] = [];
   public defaultGridPageSize: number = 20;
   public GridNames = GridName;

   constructor() {
      this.loadDataFromLocalStorage();
   }

   private loadDataFromLocalStorage() {
      let localstorageItemColumns = localStorage.getItem(GridOperationStoreKeys.COLUMNCONFIGURATION);
      if (localstorageItemColumns) {
         this.columnConfigurations = JSON.parse(localstorageItemColumns);
      }

      let localstorageItemDefaultPageSize = localStorage.getItem(GridOperationStoreKeys.DEFAULTGRIDPAGESIZE);
      if (localstorageItemDefaultPageSize) {
         this.defaultGridPageSize = JSON.parse(localstorageItemDefaultPageSize);
      }
   }

   private initColumnConfiguration(gridKey: string): ColumnConfiguration {
      this.loadDataFromLocalStorage();
      let columnConfiguration = this.getColumnConfiguration(gridKey);
      if (!columnConfiguration) {
         columnConfiguration = {
            gridKey: gridKey,
            pageSize: 20,
            columns: this.getDefaultForGridKey(gridKey),
         };
         this.setColumnConfiguration(columnConfiguration);
      }
      return columnConfiguration;
   }

   private getColumnConfiguration(gridKey: string): ColumnConfiguration | undefined {
      return this.columnConfigurations.find((x) => x.gridKey === gridKey);
   }

   private setColumnConfiguration(columnConfiguration: ColumnConfiguration) {
      let confIndex = this.columnConfigurations.findIndex((x) => x.gridKey == columnConfiguration.gridKey);
      if (confIndex != -1) {
         this.columnConfigurations.splice(confIndex, 1);
      }
      this.columnConfigurations.push(columnConfiguration);
      localStorage.setItem(GridOperationStoreKeys.COLUMNCONFIGURATION, JSON.stringify(this.columnConfigurations));
   }

   private getGridKey(gridKey: GridName | EntityType): string {
      return typeof gridKey === "string" ? gridKey : EntityType[gridKey];
   }

   public setSelectedColumns(gridKey: GridName | EntityType, selected: string[]) {
      let conf = this.initColumnConfiguration(this.getGridKey(gridKey));
      conf.columns = selected;
      this.setColumnConfiguration(conf);
   }

   public getSelectedColumns(gridKey: GridName | EntityType): string[] | null {
      let conf = this.getColumnConfiguration(this.getGridKey(gridKey));
      if (conf) {
         return conf.columns;
      }
      return null;
   }

   public getSelectedColumnsOrDefaultForOverview(gridKey: GridName | EntityType): string[] {
      let conf = this.getColumnConfiguration(this.getGridKey(gridKey));
      if (conf) {
         return conf.columns;
      }
      return this.getDefaultForGridKey(this.getGridKey(gridKey));
   }

   private getDefaultForGridKey(gridKey: string) {
      if (gridKey === "ProjectDefinition") {
         return ["code", "displayText", "entityStatus", "applicant", "owner", "created.timestamp", "startPbbName"];
      } else {
         return ["version", "code", "displayText", "entityStatus"];
      }
   }

   public getPageSize(entityType: EntityType | null | undefined) {
      if (entityType) {
         let conf = this.getColumnConfiguration(EntityType[entityType]);
         if (conf) {
            return conf.pageSize;
         }
      }

      return this.defaultGridPageSize;
   }

   public setPageSize(value: number, entityType: EntityType | null | undefined) {
      if (entityType) {
         let conf = this.initColumnConfiguration(EntityType[entityType]);
         conf.pageSize = value;
         this.setColumnConfiguration(conf);
      } else {
         this.defaultGridPageSize = value;

         localStorage.setItem(GridOperationStoreKeys.DEFAULTGRIDPAGESIZE, JSON.stringify(value));
      }
   }

   public capitalizeFirstLetter(value: string): string {
      return value.charAt(0).toUpperCase() + value.slice(1);
   }

   public updateHeaderOrder(evt, selectedHeaders: Array<any>, gridKey: GridName | EntityType) {
      let headers = selectedHeaders;
      let oldIndex = evt.oldIndex;
      let newIndex = evt.newIndex;
      if (newIndex >= headers.length) {
         var k = newIndex - headers.length + 1;
         while (k--) {
            headers.push(undefined);
         }
      }
      headers.splice(newIndex, 0, headers.splice(oldIndex, 1)[0]);

      this.setSelectedColumns(
         gridKey,
         headers.map((x) => x.value)
      );

      return headers;
   }
}

const store = new GridOperationStore();

export default store;
