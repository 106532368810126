<template>
   <v-card>
      <v-card-title>
         <v-row justify="start">
            <v-col cols="12" md="6" class="pt-0 pb-0" align-self="center">
               {{ translateKey("historyTab.versionsLabel", translations) }}
            </v-col>
            <v-col cols="12" md="6">
               <v-text-field
                  v-model="searchVersion"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  color="red darken-2"
                  hide-details
                  autofocus
               />
            </v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-row>
            <v-col>
               <v-data-table
                  :mobile-breakpoint="0"
                  v-if="extendedVersions"
                  class="overview-table pmtool-table"
                  :headers="versionsHeader"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :items="extendedVersions"
                  dense
                  :custom-filter="customSearch"
                  :search="searchVersion"
                  :footer-props="footerProps"
               >
                  <template v-slot:item.code="{ item }">
                     <router-link :to="getDetailRoute(item)" class="black--text">
                        <div>
                           {{ item.code }}
                        </div>
                     </router-link>
                  </template>
                  <template v-slot:item.displayText="{ item }">
                     <router-link :to="getDetailRoute(item)" class="black--text">
                        <div>
                           {{ item.displayText }}
                        </div>
                     </router-link>
                  </template>
                  <template v-slot:item.activatedBy="{ item }">
                     <router-link :to="getDetailRoute(item)" class="black--text">
                        <div>
                           {{ item.activatedBy }}
                        </div>
                     </router-link>
                  </template>
                  <template v-slot:item.activatedDate="{ item }">
                     <router-link :to="getDetailRoute(item)" class="black--text">
                        <div>
                           {{ item.activatedDate }}
                        </div>
                     </router-link>
                  </template>
               </v-data-table>
            </v-col>
         </v-row>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import { IItemReference, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "./Base/component-base.vue";

@Component({
   name: "VersionTableTab",
   components: {},
})
export default class VersionTableTab extends ComponentBase {
   searchVersion: string = "";
   sortBy: string[] = ["code"];
   sortDesc: boolean[] = [true];

   @Prop({ default: () => [] })
   versions: IItemReference[];

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.versionsHeader, this.translations);
   }

   versionsHeader = [
      {
         text: "Version",
         value: "code",
         class: "pmtool-table-header-fixed-sm",
         translationKey: "historyTab.versionHeader",
      },
      { text: "Note", value: "displayText", translationKey: "historyTab.noteHeader" },
      { text: "Changed information", value: "activatedBy", translationKey: "historyTab.changedInformationHeader" },
      { text: "Changed date", value: "activatedDate", translationKey: "historyTab.changedDateHeader" }, // lastModified.timestamp // lastModified.user.displayText
   ];

   getDetailRoute(item: IItemReference) {
      return { params: { ...this.$router.currentRoute.params, id: item.id } };
   }

   mounted() {
      this.translateHeaders(this.versionsHeader, this.translations);
   }

   get extendedVersions(): IItemReference[] {
      return this.versions.map((version) => ({
         ...version,
         activatedDate: version.lastModified?.timestamp.format("l"),
         activatedBy: version.lastModified?.user?.displayText,
      }));
   }
}
</script>
