import { render, staticRenderFns } from "./attachment-delete-btn.vue?vue&type=template&id=7daf48ec&scoped=true"
import script from "./attachment-delete-btn.vue?vue&type=script&lang=ts"
export * from "./attachment-delete-btn.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7daf48ec",
  null
  
)

export default component.exports