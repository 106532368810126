import { LanguagePublicModel, UserUnavailability, UserApi } from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";
import AuthStore from "@backend/store/authStore.ts";

class UserCachedApi extends CachedApiBase {
   private static readonly getActiveLanguagesKey = "UserApi.getCurrentUserPreferredLanguage";
   private static readonly getCurrentUserUnavailabilityKey = "UserApi.getCurrentUserUnavailability";

   invalidateAll() {
      this.invalidatePreferredLanguage();
      this.invalidatetUserUnavailability();
   }

   invalidatePreferredLanguage() {
      this.invalidateItem(UserCachedApi.getActiveLanguagesKey);
   }

   invalidatetUserUnavailability() {
      this.invalidateItem(UserCachedApi.getCurrentUserUnavailabilityKey);
   }

   /**
    * Get current User's preferred language
    * @return Language
    */
   async getCurrentUserPreferredLanguage(): Promise<LanguagePublicModel> {
      let result = await this.cacheEndpointAsync<LanguagePublicModel>(
         UserCachedApi.getActiveLanguagesKey,
         // note: should be key->value caching, but we can use the fact it is only called with currentId + cache gets cleared on logout
         async () => {
            let preferredLanguage = await UserApi.getPreferredLanguage(AuthStore.getUserId()!);
            return [preferredLanguage];
         },
         LanguagePublicModel.fromJS
      );
      return result[0];
   }

   /**
    * Gets current user unavailability
    * @return User unavailability
    */
   async getCurrentUserUnavailability(): Promise<UserUnavailability> {
      let result = await this.cacheEndpointAsync<UserUnavailability>(
         UserCachedApi.getCurrentUserUnavailabilityKey,
         // note: should be key->value caching, but we can use the fact it is only called with currentId + cache gets cleared on logout
         async () => {
            let preferredLanguage = await UserApi.getUserUnavailability(AuthStore.getUserId()!);
            return [preferredLanguage];
         },
         UserUnavailability.fromJS
      );
      return result[0];
   }

   private static _instance: UserCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): UserCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default UserCachedApi.instance;
