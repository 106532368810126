<template>
   <v-dialog v-model="showDialog" width="75%" persistent>
      <v-card v-if="group">
         <v-card-title primary-title="primary-title">
            <span>Add group</span>
            <v-spacer />
            <v-btn class="red--text" icon="icon" v-shortkey.once="['esc']" @shortkey="cancelClick" @click="cancelClick">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-form ref="detailForm">
               <v-row>
                  <v-col cols="6">
                     <v-text-field
                        label="Name"
                        :rules="rules.name"
                        v-model="group.name"
                        @blur="EventBus.$emit(Events.P2P, { eId: _uid })"
                        autofocus
                     ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                     <identifier-field
                        v-model="group.identifier"
                        :source="group.name"
                        :eventId="_uid"
                        label="Identifier"
                     ></identifier-field>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
               class="red--text"
               outlined="outlined"
               @click="cancelClick"
               v-shortkey.once="['esc']"
               @shortkey="cancelClick"
            >
               Cancel
            </v-btn>
            <v-btn class="error" text="text" @click="addClick">Add</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import IdentifierField from "@components/Shared/identifier-field.vue";
import {
   ContentBrickFieldGroupDefinition,
   GateCondition,
   GateConditionExpression,
   ContentBrickExpressionType,
} from "@backend/api/pmToolApi";
import { Guid } from "guid-typescript";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "ContentBrickAddGroupDialogAsync",
   components: { IdentifierField },
})
export default class ContentBrickAddGroupDialogAsync extends Vue {
   showDialog: boolean = false;
   group: ContentBrickFieldGroupDefinition | null = null;
   EventBus: any = EventBus;
   Events: any = Events;
   resolve: (parent: ContentBrickFieldGroupDefinition | null) => void;

   async showDialogAsync(parent: ContentBrickFieldGroupDefinition): Promise<ContentBrickFieldGroupDefinition | null> {
      if (!parent) throw "Parent node is required";
      if (this.showDialog) throw "Dialog already shown";
      this.$refs["detailForm"]?.resetValidation();

      return new Promise<ContentBrickFieldGroupDefinition | null>((resolve) => {
         this.group = this.initDefaultGroup(parent.id);
         this.group.order = (parent.children ?? []).reduce((max, c) => Math.max(max, c.order), -1) + 1;
         this.showDialog = true;
         this.resolve = resolve;
      });
   }

   cancelClick() {
      this.showDialog = false;
      this.resolve && this.resolve(null);
   }

   addClick() {
      if (!this.$refs["detailForm"].validate()) {
         return;
      }
      this.showDialog = false;
      this.resolve && this.resolve(this.group);
   }

   rules = {
      name: [
         (v) => !!v?.trim() || "Name is required",
         (v) => v?.length <= 80 || "Name must be less than 80 characters",
      ],
   };

   // ------------ Group init ------------------
   initDefaultGroup(groupId: string): ContentBrickFieldGroupDefinition {
      return new ContentBrickFieldGroupDefinition({
         id: Guid.create().toString(),
         groupId: groupId,
         name: "",
         identifier: undefined,
         order: Number.MAX_VALUE,
         children: [],
         description: undefined,
         condition: this.initDefaultCondition(),
         visibilityCondition: this.initDefaultCondition(),
      });
   }

   initDefaultCondition(): GateCondition {
      return new GateCondition({
         queries: [],
         description: undefined,
         predicate: new GateConditionExpression({
            operator: "and",
            operands: [],
            expressionType: ContentBrickExpressionType.LogicGroupExpression,
            left: undefined,
            right: undefined,
            hasPermission: undefined,
         }),
      });
   }
}
</script>
