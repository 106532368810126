<template>
   <v-row>
      <v-col></v-col>
      <v-spacer></v-spacer>
      <v-col>
         <div class="footer" v-if="!closedWindow">
            <v-card @click="expand" :height="height" class="expand-card" :width="width" elevation="10">
               <v-card-title>
                  <div class="minimize-card">
                     <v-icon @click="maximizeWindow">mdi-account-outline</v-icon>
                     <span @click="maximizeWindow" class="card-header-name">Libor Fridrich</span>
                     <v-btn v-if="maximizedWindow" icon><v-icon>mdi-window-minimize</v-icon></v-btn>
                     <v-btn v-if="maximizedWindow" icon @click="maximizeWindow">
                        <v-icon>mdi-window-maximize</v-icon>
                     </v-btn>
                     <v-btn v-if="maximizedWindow" icon @click="closeWindow">
                        <v-icon>mdi-window-close</v-icon>
                     </v-btn>
                  </div>
               </v-card-title>
               <v-divider class="my-0 grey lighten-1"></v-divider>
               <v-card-text v-if="maximizedWindow" class="card-text-area">
                  <v-row>
                     <v-col cols="1" md="1">
                        <!-- icona -->
                     </v-col>
                     <v-col>
                        <div v-for="message in messages">
                           <div v-if="message.isSender" class="sender-message">
                              <span class="sender-message-span ma-3">
                                 <span class="ma-3 chat-message">{{ message.text }}</span>
                              </span>
                           </div>
                           <div v-if="!message.isSender" class="reciever-message">
                              <span class="reciever-message-span ma-3 deep-orange lighten-5">
                                 <span class="ma-3 chat-message">{{ message.text }}</span>
                              </span>
                           </div>
                        </div>
                     </v-col>
                  </v-row>
               </v-card-text>
               <v-divider class="my-0 grey lighten-1"></v-divider>
               <v-card-actions class="card-actions">
                  <v-text-field single-line label="Type"></v-text-field>
               </v-card-actions>
            </v-card>
         </div>
      </v-col>
   </v-row>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
   name: "ChatWindow",
   components: {},
})
export default class ChatWindow extends Vue {
   isOpen: boolean = false;
   notificationCount: number = 3;
   height: number = 50;
   width: number = 200;
   maximizedWindow: boolean = false;
   closedWindow: boolean = false;
   messages: object[] = [
      { id: 1, name: "Me", text: "Hey man, how are you ?", isSender: false },
      { id: 2, name: "Libor Fridrich", text: "I'm fine", isSender: true },
      {
         id: 3,
         name: "Libor Fridrich",
         text: "About PBB00001...",
         isSender: true,
      },
      {
         id: 4,
         name: "Libor Fridrich",
         text: "Did you make some changes ?",
         isSender: true,
      },
      {
         id: 5,
         name: "Libor Fridrich",
         text: "Are you here ?",
         isSender: true,
      },
      { id: 6, name: "Me", text: "Yes of course", isSender: false },
      { id: 7, name: "Me", text: "Check your task list", isSender: false },
   ];

   closeWindow() {
      this.closedWindow = true;
   }

   maximizeWindow() {
      if (this.maximizedWindow) {
         this.height = 50;
         this.width = 200;
      } else {
         this.height = 350;
         this.width = 350;
      }
      this.maximizedWindow = !this.maximizedWindow;
   }
}
</script>

<style scoped>
.chat-message {
   font-size: 16px;
}

.sender-message-span {
   box-shadow: 1px 1px 2px 1px rgb(221, 221, 221);
   background-color: rgb(241, 241, 241);
   border-radius: 5px;
}

.reciever-message-span {
   box-shadow: 2px 2px 2px 1px #f3e1df;
   border-radius: 5px;
}

.sender-message {
   text-align: justify;
   border-radius: 5px;
   margin-top: 4px;
}

.reciever-message {
   text-align: right;
   border-radius: 5px;
   margin-top: 4px;
}

.footer {
   position: fixed;
   bottom: 0;
}

.card-header-name {
   font-size: 15px;
   margin-left: 10px;
   margin-right: 35px;
}

.minimize-card {
   margin-top: -5px;
}

.expand-card {
   transition: width 0.4s, height 0.5s;
}

.card-text-area {
   max-height: 240px;
   height: 240px;
}

.card-actions {
   margin-top: -12px;
}
</style>
