import { ImageType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IImageTypeProperties extends IEnumBaseProperties {
   readonly extensions: string;
   isFileTypeValid(fileType: string): boolean;
}

export class ImageTypeDecorator implements IImageTypeProperties {
   value: ImageType;
   private static readonly unkown: number = -1;

   constructor(value: ImageType) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get extensions(): string {
      return this.properties.extensions;
   }

   get accept(): string {
      return this.properties.extensions.replaceAll("*", ""); //html uses syntax without star
   }

   isFileTypeValid(fileType: string): boolean {
      return this.properties.isFileTypeValid(fileType);
   }

   static get AllItems(): ImageTypeDecorator[] {
      return Array.from(ImageTypeDecorator.entityStatusProperties.keys())
         .filter((status: number) => status != ImageTypeDecorator.unkown)
         .map((status: number) => new ImageTypeDecorator(status));
   }

   private get properties(): IImageTypeProperties {
      return (
         ImageTypeDecorator.entityStatusProperties.get(this.value) ??
         ImageTypeDecorator.entityStatusProperties.get(ImageTypeDecorator.unkown)!
      );
   }

   private static readonly pictureFileTypes: string[] = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/tiff",
      "image/gif",
   ];

   private static readonly entityStatusProperties: Map<number, IImageTypeProperties> = new Map<
      number,
      IImageTypeProperties
   >([
      [
         ImageType.Picture,
         {
            extensions: "*.jpg, *.jpeg, *.png, *.bmp, *.tiff, *.gif",
            isFileTypeValid: (type: string) => ImageTypeDecorator.pictureFileTypes.includes(type),
            translationKey: "imageType.picture",
         },
      ],
      [
         ImageTypeDecorator.unkown,
         {
            extensions: "",
            isFileTypeValid: () => false,
            translationKey: "imageType.unknown",
         },
      ],
   ]);
}
