import { Unit, UnitReference, UnitApi, EntityStatus } from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";
import { Guid } from "guid-typescript";
import { cloneDeep } from "lodash";

class UnitCachedApi extends CachedApiBase {
   private static readonly currencyUnitTypeId: string = "f5d926b2-5c3f-4241-9748-a7e12d55832a";
   private static readonly timeUnitTypeId: string = "f4a821d6-8919-4a0c-b659-f13bc4ba4481";

   invalidateAll() {
      this.invalidateItem("UnitApi.getAllUnits");
   }

   /**
    * Returns all Units
    * @return List of all Units references
    */
   async getAllUnits(): Promise<Unit[]> {
      return await this.cacheEndpointAsync<Unit>("UnitApi.getAllUnits", UnitApi.getAllUnits.bind(UnitApi), Unit.fromJS);
   }

   /**
    * Gets filtered UnitReferences from all Units cache
    * @param status if set, filter by EntityStatus
    * @param unitTypeId if set,  filter by unitTypeId
    * @returns list of all matching UnitReferences
    */
   async getAllUnitReferences(
      status: EntityStatus | undefined,
      unitTypeId: string | undefined
   ): Promise<UnitReference[]> {
      let units = await this.getAllUnits();
      let filteredUnits = units.filter(
         (u) =>
            (status === undefined || u.entityStatus === status) &&
            (unitTypeId === undefined || u.unitType?.id === unitTypeId)
      );

      return filteredUnits.map(this.unitToReference);
   }

   /**
    * Gets all Unit references of 'currency' type from all Units cache
    * @returns all Unit references of 'currency' type
    */
   async getCurrencies(): Promise<UnitReference[]> {
      let units = await this.getAllUnits();
      let currencyUnitTypeId = Guid.parse(UnitCachedApi.currencyUnitTypeId);
      let currencies = units.filter((u) => !!u.unitType?.id && Guid.parse(u.unitType.id).equals(currencyUnitTypeId));
      return currencies.map(this.unitToReference);
   }

   /**
    * Gets all Unit references of 'time' type from all Units cache
    * @returns all Unit references of 'time' type
    */
   async getTimeUnits(): Promise<UnitReference[]> {
      let units = await this.getAllUnits();
      let timeUnitTypeId = Guid.parse(UnitCachedApi.timeUnitTypeId);
      let timeUnits = units.filter((u) => !!u.unitType?.id && Guid.parse(u.unitType.id).equals(timeUnitTypeId));
      return timeUnits.map(this.unitToReference);
   }

   private unitToReference(unit: Unit): UnitReference {
      return new UnitReference({
         id: unit.id,
         code: undefined,
         displayText: unit.name,
         abbreviation: unit.abbreviation,
         isStandard: unit.isStandard,
         isHidden: unit.isHidden,
         ratio: unit.ratio,
         unitType: unit.unitType,
         entityStatus: unit.entityStatus,
         lastModified: unit.lastModified ? cloneDeep(unit.lastModified) : undefined,
      });
   }

   private static _instance: UnitCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): UnitCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default UnitCachedApi.instance;
