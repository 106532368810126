import { VideoType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IVideoTypeProperties extends IEnumBaseProperties {
   readonly extensions: string;
   isFileTypeValid(fileType: string): boolean;
}

export class VideoTypeDecorator implements IVideoTypeProperties {
   value: VideoType;
   private static readonly unkown: number = -1;

   constructor(value: VideoType) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get extensions(): string {
      return this.properties.extensions;
   }

   get accept(): string {
      return this.properties.extensions.replaceAll("*", ""); //html uses syntax without star
   }

   isFileTypeValid(fileType: string): boolean {
      return this.properties.isFileTypeValid(fileType);
   }

   static get AllItems(): VideoTypeDecorator[] {
      return Array.from(VideoTypeDecorator.entityStatusProperties.keys())
         .filter((status: number) => status != VideoTypeDecorator.unkown)
         .map((status: number) => new VideoTypeDecorator(status));
   }

   private get properties(): IVideoTypeProperties {
      return (
         VideoTypeDecorator.entityStatusProperties.get(this.value) ??
         VideoTypeDecorator.entityStatusProperties.get(VideoTypeDecorator.unkown)!
      );
   }

   private static readonly videoFileTypes: string[] = ["video/mp4", "video/webm", "video/ogg"];

   private static readonly entityStatusProperties: Map<number, IVideoTypeProperties> = new Map<
      number,
      IVideoTypeProperties
   >([
      [
         VideoType.Video,
         {
            extensions: "*.mp4, *.webm, *.ogv",
            isFileTypeValid: (type: string) => VideoTypeDecorator.videoFileTypes.includes(type),
            translationKey: "videoType.video",
         },
      ],
      [
         VideoTypeDecorator.unkown,
         {
            extensions: "",
            isFileTypeValid: () => false,
            translationKey: "videoType.unknown",
         },
      ],
   ]);
}
