import {
   DocumentType,
   AttachmentDownloadModel,
   ContentBrickFieldType,
   ImageType,
   VideoType,
   ProjectContentBrickField,
} from "@backend/api/pmToolApi";
import { DocumentTypeDecorator } from "@models/shared/DocumentTypeDecorator";
import { ImageTypeDecorator } from "@models/shared/imageTypeDecorator";
import { VideoTypeDecorator } from "@models/shared/VideoTypeDecorator";
import moment from "moment";

export class AttachmentUploadInfo {
   isFinalizing: boolean = true;
   lastTime: moment.Moment = moment();

   filesCount: number = 1;
   doneFilesCount: number = 0;
   smoothingFactor: number = 0.3;

   currentSpeed: number = 0;
   currentdTime: number = 0;
   currentdBytes: number = 0;

   private sampleMaxCount: number = 5;
   private samples: { dBits: number; dTime: number }[] = [];

   currentFileB: number = 0;
   currentFileTotalB: number = 0;

   doneFilesB: number = 0;
   allFilesTotalB: number = 0;

   get currentFileProgress(): number {
      return this.currentFileTotalB ? this.currentFileB / this.currentFileTotalB : 0;
   }

   get allFilesProgress(): number {
      return Math.min(1, this.allFilesTotalB ? (this.doneFilesB + this.currentFileB) / this.allFilesTotalB : 0);
   }

   get allFilesRemainingS(): number {
      return (this.allFilesTotalB * (1 - this.allFilesProgress)) / this.wmaSpeed;
   }

   get wmaSpeed(): number {
      if (!this.samples.length) return 0;
      let avg = this.samples.reduce(
         (curr, sample) => {
            curr.sum += sample.dBits;
            curr.weight += sample.dTime;
            return curr;
         },
         { sum: 0, weight: 0 }
      );
      return avg.sum / avg.weight;
   }

   constructor(files: File[]) {
      this.filesCount = files.length;
      this.allFilesTotalB = files.reduce((sum: number, file: File) => (sum += file.size), 0);
   }

   update(progressEvent: any) {
      let now = moment();

      if (this.isFinalizing) {
         this.isFinalizing = false;
         this.currentFileB = 0;
      }

      this.currentdTime = now.diff(this.lastTime, "s", true /*output decimal*/);
      this.currentdBytes = progressEvent.loaded - this.currentFileB;
      this.currentFileB = progressEvent.loaded;
      this.currentFileTotalB = progressEvent.total;
      this.currentSpeed = this.currentdBytes / this.currentdTime;

      this.samples.push({
         dBits: this.currentdBytes,
         dTime: this.currentdTime,
      });
      this.samples.splice(0, Math.max(0, this.samples.length - this.sampleMaxCount));

      if (this.currentFileProgress === 1) {
         this.doneFilesB += this.currentFileTotalB;
         this.doneFilesCount = Math.min(this.doneFilesCount + 1, this.filesCount);
         this.isFinalizing = true;
      }

      this.lastTime = now;
   }
}

export class BulkAttachmentState {
   /**
    * [field ID -> bool] state lookup, sets the Downloading flag for specific field(s)
    */
   downloading: { [key: string]: boolean } = {};

   /**
    * [field ID -> bool] state lookup, sets the Uploading progress info for specific field(s)
    */
   uploading: { [key: string]: AttachmentUploadInfo } = {};

   /**
    * [field ID -> bool] state lookup, sets the Deleting flag for specific field(s)
    */
   deleting: { [key: string]: boolean } = {};

   /**
    * [Attachment name -> download URL] lookup
    */
   mediaUrls: { [key: string]: string } = {}; // ImageType or VideoType Fields content

   mediaUrlsUpdated: { [key: string]: boolean } = {};
}

class AttachmentUtils {
   /**
    * Renders File size as a human-readable string
    * @param size File size
    * @param precision decimal spaces precision (default is 1, e.g. 0.5)
    */
   public sizeHumanReadable(size: number, precision: number = 1): string {
      const sizeSuffex = ["B", "kB", "MB", "GB", "TB"];
      // log(0) is not defined => replace multiplier value with 0 if size is 0
      const multiplier = size ? Math.floor(Math.log(size) / Math.log(1024)) : 0; // log(a) / log(b) = log_b (a)
      return `${(size / Math.pow(1024, multiplier)).toFixed(precision)} ${sizeSuffex[multiplier]}`;
   }

   public downloadFile(file: AttachmentDownloadModel) {
      var link = document.createElement("a");
      link.download = file.fileName!;
      link.href = file.downloadUrl!;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }

   /**
    * Renders File transfer speed as a human-readable string (in bits per second)
    * @param speedB File stranfer speed in Bytes
    * @param precision decimal spaces precision (default is 1, e.g. 0.5)
    */
   public speedHumanReadable(speedB: number, precision: number = 1): string {
      const speedSuffex = ["bit/s", "kbit/s", "Mbit/s", "Gbit/s", "Tbit/s"];
      let speedBits = speedB * 8;
      // log(0) is not defined => replace multiplier value with 0 if size is 0
      const multiplier = speedBits ? Math.floor(Math.log(speedBits) / Math.log(1024)) : 0; // log(a) / log(b) = log_b (a)
      return `${(speedBits / Math.pow(1024, multiplier)).toFixed(precision)} ${speedSuffex[multiplier]}`;
   }

   public validateDropEventFiles(
      uploadModel: { files: File[]; field: any },
      currentFiles: string[] | undefined
   ): string | null {
      const isImageFieldType = this.isImageField(uploadModel.field.definition.type);
      const isVideoFieldType = this.isVideoField(uploadModel.field.definition.type);

      const fieldDocType = this.getAttachmentTypeDecoratorFromField(uploadModel.field.definition);

      const uploadCount = uploadModel.files.length;

      if (this.wouldExceedMaxCount(uploadCount, uploadModel.field.definition.documentMaxCount, currentFiles)) {
         return `Uploading (${uploadCount}) file(s) would exceed the maximum number of ${
            isImageFieldType ? "Images" : isVideoFieldType ? "Videos" : "Documents"
         } (${uploadModel.field.definition.documentMaxCount})`;
      } else if (!uploadModel.files.every((file) => fieldDocType!.isFileTypeValid(file.type))) {
         return `One or more files are not ${fieldDocType!.description}`;
      }

      return null; //no error
   }

   public validateDragEventFiles(
      event: any,
      fieldType: number, // DocumentType or ImageType or VideoType value
      maxFileCount: number,
      currentFiles: string[] | undefined,
      cbFieldType: ContentBrickFieldType = ContentBrickFieldType.Document
   ): string | null {
      const fieldDocType = this.getAttachmentTypeDecoratorFromGenericAttachmentType(cbFieldType, fieldType);

      const uploadCount = [...event.dataTransfer.items].filter((i) => i.kind === "file").length;

      if (this.wouldExceedMaxCount(uploadCount, maxFileCount, currentFiles)) {
         return `Uploading (${uploadCount}) file(s) would exceed the maximum number of ${
            this.isImageField(cbFieldType) ? "images" : this.isVideoField(cbFieldType) ? "videos" : "documents"
         }  (${maxFileCount})`;
      } else if (![...event.dataTransfer.items].every((file) => fieldDocType!.isFileTypeValid(file.type))) {
         return `One or more files are not ${fieldDocType!.description}`;
      }

      return null; //no error
   }

   /**
    * Provides attachment type decorator depending on the CB attachment field type
    * @param field Attachment field to get decorator of
    * @returns Relevant attachment type decorator (document, image, video)
    */
   public getAttachmentTypeDecoratorFromField<
      T extends {
         type: ContentBrickFieldType;
         documentType: DocumentType;
         imageType: ImageType;
         videoType: VideoType;
      },
   >(field: T): ImageTypeDecorator | VideoTypeDecorator | DocumentTypeDecorator {
      return this.getAttachmentTypeDecoratorFromAttachmentTypes(
         field.type,
         field.documentType,
         field.imageType,
         field.videoType
      );
   }

   /**
    * Provides attachment type decorator depending on the CB attachment field type
    * @param cbFieldType Type of CB attachment field to get decorator of
    * @returns Relevant attachment type decorator (document, image, video) with default Document/Image/VideoType
    */
   public getAttachmentTypeDecoratorFromFieldType(
      cbFieldType: ContentBrickFieldType
   ): ImageTypeDecorator | VideoTypeDecorator | DocumentTypeDecorator {
      return this.getAttachmentTypeDecoratorFromAttachmentTypes(
         cbFieldType,
         DocumentType.Any,
         ImageType.Picture,
         VideoType.Video
      );
   }

   /**
    * Provides attachment type decorator depending on the CB attachment field type and attachment type
    * @param cbFieldType Type of CB attachment field to get decorator of
    * @param attachmentFieldType Type of attachment field to get decorator of
    * @returns Relevant attachment type decorator (document, image, video)
    */
   private getAttachmentTypeDecoratorFromGenericAttachmentType(
      cbFieldType: ContentBrickFieldType,
      attachmentFieldType: number // DocumentType or ImageType or VideoType value
   ): ImageTypeDecorator | VideoTypeDecorator | DocumentTypeDecorator {
      return this.getAttachmentTypeDecoratorFromAttachmentTypes(
         cbFieldType,
         attachmentFieldType,
         attachmentFieldType,
         attachmentFieldType
      );
   }

   /**
    * Provides attachment type decorator depending on the CB attachment field type and attachment types
    * @param cbFieldType Type of CB attachment field to get decorator of
    * @param documentType Type of document field to get decorator of
    * @param imageType Type of image field to get decorator of
    * @param videoType Type of video field to get decorator of
    * @returns Relevant attachment type decorator (document, image, video)
    * @throws Throws when unsupported @param cbFieldType
    */
   private getAttachmentTypeDecoratorFromAttachmentTypes(
      cbFieldType: ContentBrickFieldType,
      documentType: number, // DocumentType
      imageType: number, // ImageType
      videoType: number // VideoType
   ): ImageTypeDecorator | VideoTypeDecorator | DocumentTypeDecorator {
      if (this.isDocumentField(cbFieldType)) {
         return new DocumentTypeDecorator(documentType);
      }
      if (this.isImageField(cbFieldType)) {
         return new ImageTypeDecorator(imageType);
      }
      if (this.isVideoField(cbFieldType)) {
         return new VideoTypeDecorator(videoType);
      }

      throw `Unsupported CB field type: '${cbFieldType}'`;
   }

   /**
    * Determines if field is attachment field (document, image, video)
    * @param type Feild type to determine from
    * @returns True if field is attachment field, false otherwise
    */
   public isAttachmentField(type?: ContentBrickFieldType): boolean {
      return this.isDocumentField(type) || this.isImageField(type) || this.isVideoField(type);
   }

   /**
    * Determines if field is media field (image, video)
    * @param field Feild to determine type
    * @returns True if field is media field, false otherwise
    */
   public isContentMediaField(field: ProjectContentBrickField): boolean {
      return this.isMediaField(field?.definition?.type);
   }

   /**
    * Determines if field is media field (image, video)
    * @param type Feild type to determine from
    * @returns True if field is media field, false otherwise
    */
   public isMediaField(type?: ContentBrickFieldType): boolean {
      return this.isImageField(type) || this.isVideoField(type);
   }

   /**
    * Determines if field is document field
    * @param type Feild type to determine from
    * @returns True if field is document field, false otherwise
    */
   public isDocumentField(type?: ContentBrickFieldType): boolean {
      return type === ContentBrickFieldType.Document;
   }

   /**
    * Determines if field is image field
    * @param type Feild type to determine from
    * @returns True if field is image field, false otherwise
    */
   public isImageField(type?: ContentBrickFieldType): boolean {
      return type === ContentBrickFieldType.Image;
   }

   /**
    * Determines if field is video field
    * @param type Feild type to determine from
    * @returns True if field is video field, false otherwise
    */
   public isVideoField(type?: ContentBrickFieldType): boolean {
      return type === ContentBrickFieldType.Video;
   }

   private wouldExceedMaxCount(
      dragFileCount: number,
      maxFileCount: number,
      currentFiles: string[] | undefined
   ): boolean {
      return (currentFiles?.length ?? 0) + dragFileCount > maxFileCount;
   }
}
export default new AttachmentUtils();
