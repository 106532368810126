import { DesignGuidelineResultType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IDesignGuidelineResultProperties extends IEnumBaseProperties {
   readonly icon: string;
   readonly color: string;
}

export default class DesignGuidelineResultDecorator implements IDesignGuidelineResultProperties {
   value: DesignGuidelineResultType | undefined;

   constructor(value: boolean | DesignGuidelineResultType | undefined) {
      this.value = DesignGuidelineResultDecorator.normalize(value);
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get icon(): string {
      return this.properties.icon;
   }

   get color(): string {
      return this.properties.color;
   }

   static isResultEnum(value: any): value is DesignGuidelineResultType {
      return Object.values(DesignGuidelineResultType).includes(value);
   }

   static normalize(result: boolean | DesignGuidelineResultType | undefined): DesignGuidelineResultType | undefined {
      if (DesignGuidelineResultDecorator.isResultEnum(result)) return result;

      if (result === undefined || result === null) return undefined;

      if (result === true) return DesignGuidelineResultType.Passed;

      if (result === false) return DesignGuidelineResultType.Failed;

      throw `Invalid/unknown DesignGuidelineResultType ${result}`;
   }

   static get(result: boolean | DesignGuidelineResultType | undefined): IDesignGuidelineResultProperties {
      return DesignGuidelineResultDecorator.designGuidelineResultProperties.get(
         DesignGuidelineResultDecorator.normalize(result)
      )!;
   }

   static get AllItems(): DesignGuidelineResultDecorator[] {
      return Array.from(DesignGuidelineResultDecorator.designGuidelineResultProperties.keys()).map(
         (status: DesignGuidelineResultType | undefined) => new DesignGuidelineResultDecorator(status)
      );
   }

   private get properties(): IDesignGuidelineResultProperties {
      return DesignGuidelineResultDecorator.designGuidelineResultProperties.get(this.value)!;
   }

   private static readonly designGuidelineResultProperties: Map<
      DesignGuidelineResultType | undefined,
      IDesignGuidelineResultProperties
   > = new Map<DesignGuidelineResultType | undefined, IDesignGuidelineResultProperties>([
      [
         DesignGuidelineResultType.Passed,
         { color: "success", icon: "mdi-check-circle", translationKey: "DesignGuidelineResultType.Passed" },
      ],
      [
         DesignGuidelineResultType.ManuallyPassed,
         { color: "success", icon: "mdi-check-circle", translationKey: "DesignGuidelineResultType.ManuallyPassed" },
      ],
      [
         DesignGuidelineResultType.Failed,
         { color: "error", icon: "mdi-alert-circle", translationKey: "DesignGuidelineResultType.Failed" },
      ],
      [undefined, { color: "grey", icon: "mdi-help-circle", translationKey: "DesignGuidelineResultType.Unevaluated" }],
   ]);
}
