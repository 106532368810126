import { PbbEnactmentStatus } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IStatusProperties extends IEnumBaseProperties {
   readonly color: string;
   readonly isDark: boolean;
}

export class PbbEnactmentStatusDecorator implements IStatusProperties {
   value: PbbEnactmentStatus;
   private static readonly unkown: number = -1;

   constructor(value: PbbEnactmentStatus) {
      this.value = value;
   }

   get color(): string {
      return this.properties.color;
   }

   get isDark(): boolean {
      return this.properties.isDark;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   static valueSort(a: PbbEnactmentStatus, b: PbbEnactmentStatus): number {
      return PbbEnactmentStatusDecorator.get(a).translationKey.localeCompare(
         PbbEnactmentStatusDecorator.get(b).translationKey
      );
   }

   static get AllItems(): PbbEnactmentStatusDecorator[] {
      return Array.from(PbbEnactmentStatusDecorator.pbbEnactmentStatusProperties.keys())
         .filter((status: number) => status != PbbEnactmentStatusDecorator.unkown)
         .map((status: number) => new PbbEnactmentStatusDecorator(status));
   }

   static get(value: PbbEnactmentStatus): IStatusProperties {
      return (
         PbbEnactmentStatusDecorator.pbbEnactmentStatusProperties.get(value) ??
         PbbEnactmentStatusDecorator.pbbEnactmentStatusProperties.get(PbbEnactmentStatusDecorator.unkown)!
      );
   }

   private get properties(): IStatusProperties {
      return PbbEnactmentStatusDecorator.get(this.value);
   }

   private static readonly pbbEnactmentStatusProperties: Map<number, IStatusProperties> = new Map<
      number,
      IStatusProperties
   >([
      [PbbEnactmentStatus.Created, { color: "#d6eaf8", isDark: false, translationKey: "pbbEnacntmentStatus.created" }],
      [
         PbbEnactmentStatus.WaitingForParent,
         { color: "#f9e69a", isDark: false, translationKey: "pbbEnacntmentStatus.waitingForParent" },
      ],
      [
         PbbEnactmentStatus.WaitingForSequence,
         { color: "#f9e69a", isDark: false, translationKey: "pbbEnacntmentStatus.waitingForSequence" },
      ],
      [
         PbbEnactmentStatus.WaitingForEvent,
         { color: "#f9e69a", isDark: false, translationKey: "pbbEnacntmentStatus.waitingForEvent" },
      ],
      [
         PbbEnactmentStatus.ReadyToProcess,
         { color: "#aed6f1", isDark: false, translationKey: "pbbEnacntmentStatus.readyToProcess" },
      ],
      [PbbEnactmentStatus.Planned, { color: "#5dade2", isDark: true, translationKey: "pbbEnacntmentStatus.planned" }],
      [
         PbbEnactmentStatus.InProcess,
         { color: "#2e86c1", isDark: true, translationKey: "pbbEnacntmentStatus.inProgress" },
      ],
      [
         PbbEnactmentStatus.Suspended,
         { color: "#f7dc6f", isDark: false, translationKey: "pbbEnacntmentStatus.suspended" },
      ],
      [
         PbbEnactmentStatus.Postponed,
         { color: "#eb984e", isDark: false, translationKey: "pbbEnacntmentStatus.postponed" },
      ],
      [
         PbbEnactmentStatus.MicroLevelFinished,
         { color: "#82e0aa", isDark: false, translationKey: "pbbEnacntmentStatus.microLevelFinished" },
      ],
      [
         PbbEnactmentStatus.WaitingForResults,
         { color: "#f9e69a", isDark: false, translationKey: "pbbEnacntmentStatus.waitingForResults" },
      ],
      [
         PbbEnactmentStatus.ReadyToReview,
         { color: "#82e0aa", isDark: false, translationKey: "pbbEnacntmentStatus.readyToReview" },
      ],
      [
         PbbEnactmentStatus.InReview,
         { color: "#82e0aa", isDark: false, translationKey: "pbbEnacntmentStatus.inReview" },
      ],
      [PbbEnactmentStatus.Finished, { color: "#28b463", isDark: true, translationKey: "pbbEnacntmentStatus.finished" }],
      [PbbEnactmentStatus.Failed, { color: "#fadbd8", isDark: false, translationKey: "pbbEnacntmentStatus.failed" }],
      [
         PbbEnactmentStatus.FailedAndRetryRequest,
         { color: "#fadbd8", isDark: false, translationKey: "pbbEnacntmentStatus.retryRequest" },
      ],
      [PbbEnactmentStatus.Aborted, { color: "#f1948a", isDark: false, translationKey: "pbbEnacntmentStatus.aborted" }],
      [
         PbbEnactmentStatus.Cancelled,
         { color: "#e74c3c", isDark: true, translationKey: "pbbEnacntmentStatus.cancelled" },
      ],
      [PbbEnactmentStatus.Retried, { color: "#fadbd8", isDark: false, translationKey: "pbbEnacntmentStatus.retried" }],
      [
         PbbEnactmentStatus.CompensationFinished,
         { color: "#28b463", isDark: true, translationKey: "pbbEnacntmentStatus.compensationFinished" },
      ],
      [
         PbbEnactmentStatus.Obsolete,
         { color: "#fadbd8", isDark: false, translationKey: "pbbEnacntmentStatus.obsolete" },
      ],
      [PbbEnactmentStatus.Error, { color: "#f1948a", isDark: false, translationKey: "pbbEnacntmentStatus.error" }],
      [
         PbbEnactmentStatusDecorator.unkown,
         { color: "#dddddd", isDark: false, translationKey: "pbbEnacntmentStatus.unknownStatus" },
      ],
   ]);
}
