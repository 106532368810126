<template>
   <div class="d-flex">
      <v-col :class="{ 'field-value': true, 'value-field': isValueField }">
         <three-state-switch
            v-if="type === ContentBrickFieldType.Boolean"
            :label="fieldLabel"
            v-model="internalValue"
            @change="updateValue"
            hide-details="auto"
            class="mt-0"
            :readonly="readonly"
            :color="color"
            :allowSetIndeterminate="allowSetIndeterminate"
            :layout="booleanLayout"
            :rules="requiredBooleanRules"
         >
            <template v-slot:label>
               <field-value-label
                  v-if="renderDglResult"
                  :field="field"
                  :fieldLabel="fieldLabel"
                  :translations="translations"
                  :designGuidelineNodeResult="designGuidelineNodeResult"
                  @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
               ></field-value-label>
               <span v-else class="field-label">{{ fieldLabel }}</span>
            </template>
         </three-state-switch>
         <v-tooltip v-else-if="isCalculatedFieldType(type)" :disabled="!showTooltip" top>
            <template v-slot:activator="{ on }">
               <v-text-field
                  v-on="on"
                  :type="hasRegularExpression() && !isFieldFocused(field) ? 'text' : 'number'"
                  :label="fieldLabel"
                  v-model="calculatedValueRounded"
                  :readonly="true"
                  :color="color"
                  :loading="isCalculatedFieldLoading"
                  hide-details
                  @focus="fieldFocusChanged(field, true)"
                  @blur="fieldFocusChanged(field, false)"
               >
                  <template v-slot:append>
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-icon v-on="on" :v-bind="attrs">mdi-calculator-variant-outline</v-icon>
                        </template>
                        <span>Calculated field</span>
                     </v-tooltip>
                  </template>
                  <template v-slot:label>
                     <field-value-label
                        v-if="renderDglResult"
                        :field="field"
                        :fieldLabel="fieldLabel"
                        :translations="translations"
                        :designGuidelineNodeResult="designGuidelineNodeResult"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                     ></field-value-label>
                     <span v-else class="field-label">{{ fieldLabel }}</span>
                  </template>
               </v-text-field>
            </template>
            <span>{{ tooltip }}</span>
         </v-tooltip>
         <v-tooltip v-else-if="isNumericFieldType(type)" :disabled="!showTooltip" top>
            <template v-slot:activator="{ on }">
               <v-text-field
                  v-on="on"
                  :type="hasRegularExpression() && !isFieldFocused(field) ? 'text' : 'number'"
                  :label="fieldLabel"
                  v-model="fieldValue"
                  @input="updateValue"
                  hide-details
                  :step="type === ContentBrickFieldType.Decimal ? '0.01' : '1'"
                  :readonly="readonly"
                  :color="color"
                  :rules="requiredRegexRules"
                  @focus="fieldFocusChanged(field, true)"
                  @blur="fieldFocusChanged(field, false)"
               >
                  <template v-slot:label>
                     <field-value-label
                        v-if="renderDglResult"
                        :field="field"
                        :fieldLabel="fieldLabel"
                        :translations="translations"
                        :designGuidelineNodeResult="designGuidelineNodeResult"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                     ></field-value-label>
                     <span v-else class="field-label">{{ fieldLabel }}</span>
                  </template>
               </v-text-field>
            </template>
            <span>{{ tooltip }}</span>
         </v-tooltip>
         <div v-else-if="type === ContentBrickFieldType.TextArea" style="text-align: left">
            <label class="field-label scale-075">
               <field-value-label
                  v-if="renderDglResult"
                  :field="field"
                  :fieldLabel="fieldLabel"
                  :translations="translations"
                  :designGuidelineNodeResult="designGuidelineNodeResult"
                  @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
               ></field-value-label>
               <span v-else class="field-label">{{ fieldLabel }}</span>
               <v-tooltip top v-if="!!link">
                  <template v-slot:activator="{ on, attrs }">
                     <a :href="link.href">
                        <v-icon v-on="on" :v-bind="attrs">
                           {{ link.type === "email" ? "mdi-email-plus" : "mdi-open-in-new" }}
                        </v-icon>
                     </a>
                  </template>
                  <span>{{ link.value }}</span>
               </v-tooltip>
            </label>
            <vue-editor v-if="!readonly" v-model="fieldTrimmedValue" @input="updateValue" :color="color"></vue-editor>
            <span v-else v-html="fieldTrimmedValue"></span>
            <v-messages :value="validationErrorMessage" color="error" class="mt-2"></v-messages>
         </div>
         <v-tooltip v-else-if="type === ContentBrickFieldType.ComboBox" :disabled="!showTooltip" top>
            <template v-slot:activator="{ on }">
               <v-autocomplete
                  v-on="on"
                  :label="fieldLabel"
                  v-model="internalValue"
                  @input="updateValue"
                  item-text="text"
                  item-value="identifier"
                  hide-details
                  :items="list.items"
                  :readonly="readonly"
                  :color="color"
                  :rules="requiredRules"
                  :clearable="!readonly"
               >
                  <template v-slot:item="{ item, on, attrs }">
                     <v-list-item v-bind="attrs" v-on="on" :class="{ 'list-item--hidden': item.disabled }">
                        {{ item.text }}
                     </v-list-item>
                  </template>
                  <template v-slot:label>
                     <field-value-label
                        v-if="renderDglResult"
                        :field="field"
                        :fieldLabel="fieldLabel"
                        :translations="translations"
                        :designGuidelineNodeResult="designGuidelineNodeResult"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                     ></field-value-label>
                     <span v-else class="field-label">{{ fieldLabel }}</span>
                  </template>
               </v-autocomplete>
            </template>
            <span>{{ tooltip }}</span>
         </v-tooltip>
         <div
            v-else-if="
               type === ContentBrickFieldType.Document ||
               type === ContentBrickFieldType.Image ||
               type === ContentBrickFieldType.Video
            "
            ref="fileTypeFiled"
            @dragover.prevent="onDragOver($event)"
            @dragleave="onDragLeave($event)"
            @drop.prevent="onFileDrop($event)"
         >
            <template v-if="!isDragging">
               <v-combobox
                  v-model="internalValue"
                  :label="attachmentFieldLabel"
                  :class="{ 'field-editable': !readonly && !isUnsavedAdhocMember }"
                  :readonly="true"
                  :color="color"
                  hide-details="auto"
                  :loading="loading"
                  multiple
                  small-chips
                  append-icon=""
                  @mouseup.stop.prevent="!readonly && $refs.fileInput.$refs.input.click()"
                  :rules="fileRules"
               >
                  <template v-slot:label>
                     <field-value-label
                        v-if="renderDglResult"
                        :field="field"
                        :fieldLabel="fieldLabel"
                        :translations="translations"
                        :designGuidelineNodeResult="designGuidelineNodeResult"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                     ></field-value-label>
                     <span v-else class="field-label">{{ fieldLabel }}</span>
                  </template>
                  <template v-slot:append>
                     <span class="grey--text mt-2">
                        ({{ (internalValue || 0) && internalValue.length }}/{{ field.definition.documentMaxCount }})
                     </span>
                  </template>
                  <template v-slot:progress>
                     <v-progress-linear
                        v-if="uploadInfo"
                        class="v-progress-linear--absolute"
                        style="height: 24px"
                        color="primary lighten-2"
                        :value="uploadInfo && uploadInfo.allFilesProgress * 100"
                        :indeterminate="uploadInfo && uploadInfo.isFinalizing"
                     >
                        <strong>{{ attachmentFieldHint }}</strong>
                     </v-progress-linear>
                  </template>
                  <template v-slot:append-outer>
                     <v-tooltip top :disabled="!isUnsavedAdhocMember">
                        <template v-slot:activator="{ on, attrs }">
                           <div v-on="on" style="display: inline-flex">
                              <v-file-input
                                 v-if="!readonly"
                                 ref="fileInput"
                                 :disabled="isAtMaxFileCount || loading || isUnsavedAdhocMember"
                                 :color="color"
                                 label="File input"
                                 hide-input
                                 multiple
                                 :class="['pa-0', 'mt-0']"
                                 :accept="attachmentAcceptTypes"
                                 :prepend-icon="isUnsavedAdhocMember ? 'mdi-content-save-alert' : 'mdi-file-upload'"
                                 @change="$emit('upload-attachments', { files: $event, field: field })"
                              ></v-file-input>
                              <v-file-input
                                 v-if="
                                    !readonly &&
                                    isMobile &&
                                    (type === ContentBrickFieldType.Image || type === ContentBrickFieldType.Video)
                                 "
                                 :disabled="isAtMaxFileCount || loading || isUnsavedAdhocMember"
                                 :color="color"
                                 label="Camera input"
                                 hide-input
                                 multiple
                                 :accept="type === ContentBrickFieldType.Image ? 'image/*' : 'video/*'"
                                 capture="user"
                                 :class="['pa-0', 'mt-0']"
                                 :prepend-icon="isUnsavedAdhocMember ? 'mdi-content-save-alert' : 'mdi-camera'"
                                 @change="$emit('upload-attachments', { files: $event, field: field })"
                              ></v-file-input>

                              <v-btn
                                 v-if="
                                    !readonly &&
                                    !isMobile &&
                                    (type === ContentBrickFieldType.Image || type === ContentBrickFieldType.Video)
                                 "
                                 icon
                                 :disabled="isAtMaxFileCount || loading || isUnsavedAdhocMember"
                                 @click="openWebcamDialog()"
                              >
                                 <v-icon>mdi-camera</v-icon>
                              </v-btn>
                              <webcam-recording-dialog
                                 ref="webcamInput"
                                 :isVideo="type === ContentBrickFieldType.Video"
                                 @upload-attachment="$emit('upload-attachments', { files: [$event], field: field })"
                              ></webcam-recording-dialog>
                           </div>
                        </template>
                        <span>Save before modifying files</span>
                     </v-tooltip>
                  </template>
                  <template v-slot:selection="{ attrs, item }">
                     <v-tooltip v-if="type === ContentBrickFieldType.Document" open-delay="250" bottom>
                        <template v-slot:activator="{ on }">
                           <v-chip
                              v-on="on"
                              v-bind="attrs"
                              small
                              :disabled="loading"
                              @click="$emit('download-attachment', item)"
                              @mouseup.stop.prevent
                           >
                              {{ getAttachmentName(item) }}
                              <span class="grey--text ml-1 mr-5">{{ getAttachmentSize(item) }}</span>
                              <v-btn class="delete-file-btn" @click.stop="$emit('delete-attachment', item)" icon>
                                 <v-icon class="v-chip__close">mdi-close-circle</v-icon>
                              </v-btn>
                           </v-chip>
                        </template>
                        <span>
                           {{ getAttachmentName(item) }}
                           <span class="grey--text ml-1">{{ getAttachmentSize(item) }}</span>
                        </span>
                     </v-tooltip>
                     <v-col
                        v-else-if="type === ContentBrickFieldType.Image"
                        cols="12"
                        xs="12"
                        sm="12"
                        :md="useBiggerImageSize ? 12 : 6"
                        :lg="useBiggerImageSize ? 6 : 4"
                        :xl="useBiggerImageSize ? 4 : 3"
                        align-self="start"
                     >
                        <v-tooltip top>
                           <template v-slot:activator="{ on, attrs }">
                              <v-card flat tile v-bind="attrs" v-on="on">
                                 <v-img
                                    :src="internalMediaUrlValues[item]"
                                    @click="$emit('download-attachment', item)"
                                    @mouseup.stop.prevent
                                    class="img-pointer"
                                 >
                                    <div class="mr-2 mt-2 d-flex justify-content-end">
                                       <attachment-delete-btn
                                          v-if="!readonly && allowDeleteAttachment"
                                          @click="$emit('delete-attachment', item)"
                                       />
                                    </div>
                                    <template v-slot:placeholder>
                                       <v-row class="fill-height ma-0" align="center" justify="center">
                                          <v-progress-circular
                                             indeterminate
                                             color="blue lighten-5"
                                          ></v-progress-circular>
                                       </v-row>
                                    </template>
                                 </v-img>
                              </v-card>
                           </template>
                           <div @mouseup.stop.prevent>
                              {{ getAttachmentName(item) }}
                              <span class="grey--text ml-1">
                                 {{ getAttachmentSize(item) }}
                              </span>
                           </div>
                        </v-tooltip>
                     </v-col>
                     <v-col
                        v-else-if="type === ContentBrickFieldType.Video"
                        cols="12"
                        xs="12"
                        sm="12"
                        :md="useBiggerImageSize ? 12 : 6"
                        :lg="useBiggerImageSize ? 6 : 4"
                        :xl="useBiggerImageSize ? 4 : 3"
                        align-self="start"
                        class="pa-1"
                     >
                        <v-tooltip top>
                           <template v-slot:activator="{ on, attrs }">
                              <v-card flat tile v-bind="attrs" v-on="on">
                                 <video
                                    :src="internalMediaUrlValues[item]"
                                    class="w-100 h-100 object-fit-cover"
                                    @mouseup.stop.prevent
                                    controls
                                    preload="metadata"
                                 ></video>
                                 <attachment-delete-btn
                                    v-if="!readonly && allowDeleteAttachment"
                                    @click="$emit('delete-attachment', item)"
                                    absolute
                                    top
                                    right
                                 />
                                 <attachment-download-btn
                                    @click="$emit('download-attachment', item)"
                                    absolute
                                    top
                                    left
                                 />
                              </v-card>
                           </template>
                           <div @mouseup.stop.prevent>
                              {{ getAttachmentName(item) }}
                              <span class="grey--text ml-1">
                                 {{ getAttachmentSize(item) }}
                              </span>
                           </div>
                        </v-tooltip>
                     </v-col>
                  </template>
               </v-combobox>
            </template>
            <template v-else>
               <div class="drop-area" :style="{ height: cbFieldDropAreaHeight }">
                  <span v-if="dragError || isUnsavedAdhocMember" class="grey--text">
                     <v-icon class="red--text text--lighten-2">mdi-cancel</v-icon>
                     {{ dragError }}
                  </span>
                  <span v-else class="grey--text">Drop file here to upload</span>
               </div>
            </template>
         </div>
         <v-tooltip v-else-if="type === ContentBrickFieldType.TextBox" :disabled="!showTooltip" top>
            <template v-slot:activator="{ on }">
               <v-text-field
                  v-on="on"
                  :label="fieldLabel"
                  v-model="fieldTrimmedValue"
                  @input="updateValue"
                  :readonly="readonly"
                  :color="color"
                  @focus="fieldFocusChanged(field, true)"
                  @blur="fieldFocusChanged(field, false)"
                  :rules="requiredRegexTrimmedRules"
               >
                  <template v-slot:label>
                     <field-value-label
                        v-if="renderDglResult"
                        :field="field"
                        :fieldLabel="fieldLabel"
                        :translations="translations"
                        :designGuidelineNodeResult="designGuidelineNodeResult"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                     ></field-value-label>
                     <span v-else class="field-label">{{ fieldLabel }}</span>
                  </template>
                  <template v-slot:append>
                     <v-tooltip top v-if="!!link">
                        <template v-slot:activator="{ on, attrs }">
                           <a :href="link.href">
                              <v-icon v-on="on" :v-bind="attrs">
                                 {{ link.type === "email" ? "mdi-email-plus" : "mdi-open-in-new" }}
                              </v-icon>
                           </a>
                        </template>
                        <span>{{ link.value }}</span>
                     </v-tooltip>
                  </template>
               </v-text-field>
            </template>
            <span>{{ tooltip }}</span>
         </v-tooltip>
         <v-tooltip v-else-if="isDateTimeFieldType(type)" :disabled="!showTooltip" top>
            <template v-slot:activator="{ on }">
               <div v-on="on">
                  <date-time-picker
                     ref="dateTimePicker"
                     v-model="internalValue"
                     :label="fieldLabel"
                     class="mr-auto flex-grow-0"
                     @input="updateValue"
                     :readonly="readonly"
                     :color="color"
                     :clearable="!readonly"
                     :date="type !== ContentBrickFieldType.Time"
                     :time="type !== ContentBrickFieldType.Date"
                     :regularExpression="regularExpression"
                     @focus="fieldFocusChanged(field, true)"
                     @blur="fieldFocusChanged(field, false)"
                     :rules="requiredRules"
                  >
                     <template v-slot:label>
                        <field-value-label
                           v-if="renderDglResult"
                           :field="field"
                           :fieldLabel="fieldLabel"
                           :translations="translations"
                           :designGuidelineNodeResult="designGuidelineNodeResult"
                           @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                        ></field-value-label>
                        <span v-else class="field-label">{{ fieldLabel }}</span>
                     </template>
                  </date-time-picker>
               </div>
            </template>
            <span>{{ tooltip }}</span>
         </v-tooltip>
         <scb-field-value
            v-else-if="isSubContentBrickFieldType(type)"
            v-model="internalValue"
            @input="updateValue"
            :field="field"
            :units="units"
            :showUnits="showUnit"
            :fieldLabel="fieldLabel"
            :readonly="readonly"
            :requiredRules="requiredRules"
            :color="color"
            :focusedField="focusedField"
            @fieldFocusChanged="fieldFocusChanged"
            :regularExpressions="regularExpressions"
         >
            <template v-slot:append-unit="props">
               <v-btn
                  v-if="!readonly"
                  class="task-unit-alignment-fix"
                  :disabled="!canEditFieldUnit(props.scbField)"
                  @click="$emit('editFieldUnit', { field: field, scbField: props.scbField })"
                  icon
                  color="error"
               >
                  <v-icon>mdi-upload</v-icon>
               </v-btn>
            </template>
         </scb-field-value>
         <v-tooltip
            v-else-if="type === ContentBrickFieldType.List || type === ContentBrickFieldType.MultiSelectList"
            :disabled="!showTooltip"
            top
         >
            <template v-slot:activator="{ on }">
               <v-autocomplete
                  v-on="on"
                  v-if="!loading"
                  :label="fieldLabel"
                  v-model="internalValue"
                  @input="updateValue"
                  item-value="identifier"
                  item-text="text"
                  hide-details
                  :items="list.items"
                  :readonly="readonly"
                  :color="color"
                  :rules="requiredRules"
                  :multiple="type === ContentBrickFieldType.MultiSelectList"
                  :clearable="!readonly"
               >
                  <template v-slot:label>
                     <field-value-label
                        v-if="renderDglResult"
                        :field="field"
                        :fieldLabel="fieldLabel"
                        :translations="translations"
                        :designGuidelineNodeResult="designGuidelineNodeResult"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                     ></field-value-label>
                     <span v-else class="field-label">{{ fieldLabel }}</span>
                  </template>
                  <template v-slot:item="{ item, on, attrs }">
                     <v-list-item v-bind="attrs" v-on="on" :class="{ 'list-item--hidden': item.disabled }">
                        {{ item.text }}
                     </v-list-item>
                  </template>
               </v-autocomplete>
            </template>
            <span>{{ tooltip }}</span>
         </v-tooltip>
         <v-text-field
            v-else
            :disabled="true"
            :label="'Not implemented field type: ' + name"
            @input="updateValue"
            hide-details
            :readonly="readonly"
            :color="color"
         ></v-text-field>
      </v-col>
      <spinner v-if="(loading || internalLoading) && type !== ContentBrickFieldType.Document" :circular="true" />
      <v-col v-else-if="isUnitShown" cols="auto" align-self="end" style="font-size: 16px" class="mb-1 pl-0">
         <v-row justify="end" class="mr-0 align-items-end">
            <v-tooltip top>
               <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ unit.abbreviation }}</span>
               </template>
               <span>{{ unit.name }}</span>
            </v-tooltip>
            <v-btn
               v-if="!readonly"
               class="task-unit-alignment-fix"
               :disabled="!canEditFieldUnit()"
               @click="$emit('editFieldUnit', { field: field, scbField: undefined })"
               icon
               color="error"
            >
               <v-icon>mdi-upload</v-icon>
            </v-btn>
         </v-row>
      </v-col>
   </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import {
   ContentBrickFieldDefinition,
   ContentBrickFieldType,
   IList,
   IUnitReference,
   ProjectContentBrickField,
   AttachmentMetadata,
   ContentBrickField,
   IRegularExpression,
   SubContentBrickFieldDefinition,
   BooleanFieldLayout,
   DesignGuidelineNodeResult,
   DesignGuidelineFieldEvaluation,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import Spinner from "@components/Shared/spinner.vue";
import DateTimePicker from "@components/ContentBricks/Shared/field-date-time-picker.vue";
import ScbFieldValue from "@components/ContentBricks/Shared/scb-field-value.vue";
import ValidationRules from "@models/shared/ValidationRules";
import { debounce, find, toNumber, round } from "lodash";
import AttachmentUtils, { AttachmentUploadInfo } from "@utils/AttachmentUtils";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";
import * as linkify from "linkifyjs";
import ThreeStateSwitch from "@components/Shared/three-state-switch.vue";
import DateTimeUtils from "@utils/DateTimeUtils";
import DataModelFieldUtils from "@utils/DataModelFieldUtils";
import { VueEditor } from "vue2-editor";
import AttachmentDeleteBtn from "@components/Shared/attachment-delete-btn.vue";
import AttachmentDownloadBtn from "@components/Shared/attachment-download-btn.vue";
import FieldValueLabel from "@components/ContentBricks/Shared/field-value-label.vue";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import DeviceUtils from "@utils/DeviceUtils";
import WebcamRecordingDialog from "@components/Shared/webcam-recording-dialog.vue";

@Component({
   name: "FieldValue",
   components: {
      Spinner,
      DateTimePicker,
      ThreeStateSwitch,
      ScbFieldValue,
      VueEditor,
      AttachmentDeleteBtn,
      AttachmentDownloadBtn,
      FieldValueLabel,
      WebcamRecordingDialog,
   },
})
export default class FieldValue extends Vue {
   internalLoading: boolean = false;
   ContentBrickFieldType: any = ContentBrickFieldType;
   internalValue: any | null = null;
   validationRules: any = ValidationRules;
   internalMediaUrlValues: { [key: string]: string } = {}; // ContentBrick ImageType or VideoType field URLS content
   validationErrorMessage: string[] = [];

   @Prop({ default: null })
   value: any | null;

   @Prop({ default: false })
   loading: boolean;

   @Prop({ default: ContentBrickFieldType.TextBox })
   type: ContentBrickFieldType;

   @Prop({ default: null })
   name: string | null;

   @Prop({ required: true, default: () => [] })
   units: IUnitReference[];

   @Prop({ default: null })
   list: IList | null;

   @Prop({ required: true, default: () => [] })
   regularExpressions: IRegularExpression[];

   @Prop({ default: null })
   field: any | null;

   @Prop({ default: null })
   treeNode: any | null;

   @Prop({ default: null })
   fields: ProjectContentBrickField[] | null;

   @Prop({ default: null })
   metadataFields: any;

   @Prop({ default: null })
   ddmFields: any;

   @Prop({ default: false })
   initCalculate: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: true })
   validate: boolean;

   @Prop({ default: false })
   fieldValueRequired: boolean;

   @Prop({ default: undefined })
   attachments: { [key: string]: AttachmentMetadata } | undefined;

   @Prop({ default: false })
   allowDeleteAttachment: boolean;

   @Prop({ default: undefined })
   color: string | undefined;

   @Prop({ default: true })
   showUnit: boolean;

   @Prop({ default: false })
   runFinishValidation: boolean;

   @Prop({ default: null })
   uploadInfo: AttachmentUploadInfo | null;

   @Prop({ default: () => {} })
   mediaUrlsValues: { [key: string]: string };

   @Prop({ default: false })
   useBiggerImageSize: boolean;

   @Prop({ default: false })
   allowSetIndeterminate: boolean;

   @Prop({ default: false })
   isUnsavedAdhocMember: boolean;

   @Prop({ default: false })
   isCalculatedFieldLoading: boolean;

   @Prop({ default: null })
   designGuidelineNodeResult: DesignGuidelineNodeResult | null;

   @Prop({ default: null })
   translations: TranslationPublicModel[] | null;

   @Watch("value", { deep: true })
   onValueChanged(): void {
      this.$set(this, "internalValue", this.value);
   }

   @Watch("mediaUrlsValues", { deep: true })
   onMediaUrlsValuesChanged() {
      this.internalMediaUrlValues = Object.assign({}, this.mediaUrlsValues);
   }

   internalFields: ProjectContentBrickField[];

   getAttachmentSize(blobName: string): string | undefined {
      var metadata = this.attachments?.[blobName];
      if (metadata) {
         return AttachmentUtils.sizeHumanReadable(metadata.size);
      }
   }

   get isMobile(): boolean {
      return DeviceUtils.isMobile();
   }

   get fieldLabel(): string | null {
      return `${this.name ?? "Untitled field"} ${this.fieldValueRequired ? "*" : ""}`;
   }

   get attachmentAcceptTypes(): string | undefined {
      const docType = AttachmentUtils.getAttachmentTypeDecoratorFromField(this.field.definition);
      return docType.accept;
   }

   get isAtMaxFileCount(): boolean | undefined {
      if (!this.internalValue) return false;

      return this.internalValue.length >= this.field.definition.documentMaxCount;
   }

   get isAtleastMinFileCount(): boolean | undefined {
      if (!this.value) return undefined;

      return this.value.length >= this.field.definition.documentMinCount;
   }

   getAttachmentName(blobName: string): string | undefined {
      var metadata = this.attachments?.[blobName];
      return metadata?.fileName;
   }

   isImageFieldType(field: any): boolean {
      return AttachmentUtils.isImageField(field.definition.type);
   }

   isVideoFieldType(field: any): boolean {
      return AttachmentUtils.isVideoField(field.definition.type);
   }

   get attachmentFieldLabel(): string {
      const docType = AttachmentUtils.getAttachmentTypeDecoratorFromField(this.field.definition);
      return `${this.name} (${docType.extensions})`;
   }

   get attachmentFieldHint(): string | null {
      if (this.uploadInfo === null) {
         return null;
      }

      let hint = "Uploading ";
      if (this.uploadInfo.filesCount > 1) {
         hint += `(${this.uploadInfo.doneFilesCount}/${this.uploadInfo.filesCount}) `;
      }

      hint += `file ${(this.uploadInfo.currentFileProgress * 100).toFixed(1)}% `;

      if (this.uploadInfo.isFinalizing) {
         hint += "saving to cloud";
      } else {
         hint += `@${AttachmentUtils.speedHumanReadable(this.uploadInfo.wmaSpeed)}`;
      }

      return hint;
   }

   get internalValueTrimmed(): string | null {
      return this.internalValue;
   }

   set internalValueTrimmed(value: string | null) {
      this.internalValue = value?.trim();
   }

   get calculatedValueRounded(): any | null {
      const targetPrecision = 9;

      if (this.internalValue === null) return null;

      let value =
         this.internalValue < 0
            ? -round(-this.internalValue, targetPrecision)
            : round(this.internalValue, targetPrecision);

      return !this.isFieldFocused(this.field) && this.hasRegularExpression()
         ? this.getFormatedValueByRegex(value)
         : value;
   }

   get isUnitShown(): boolean {
      return this.showUnit && this.requiresUnits && !!this.unit && this.unit.isHidden !== true;
   }

   get requiresUnits(): boolean {
      return new ContentBrickFieldTypeDecorator(this.field.definition.type).hasUnits;
   }

   get unit(): IUnitReference | null {
      return this.field.definition.type !== ContentBrickFieldType.SubContentBrick && this.units.length
         ? this.units[0]
         : null;
   }

   get tooltip(): string | undefined | null {
      let displayValue: string | undefined | null = undefined;
      if (this.type === ContentBrickFieldType.TextBox) {
         displayValue = this.internalValueTrimmed;
      } else if (this.isNumericFieldType(this.type)) {
         displayValue = this.internalValue?.toString();
      } else if (this.isCalculatedFieldType(this.type)) {
         displayValue = this.calculatedValueRounded?.toString();
      } else if (this.type === ContentBrickFieldType.ComboBox) {
         displayValue = this.internalValue;
      } else if (this.isDateTimeFieldType(this.type)) {
         displayValue = DateTimeUtils.formatDateTime(this.internalValue, DateTimeUtils.getFieldFormat(this.type));
      } else if (this.type === ContentBrickFieldType.List) {
         displayValue = this.internalValue;
      }

      return displayValue;
   }

   get showTooltip(): boolean {
      return (this.tooltip?.length ?? 0) > DataModelFieldUtils.tooltipThreshold;
   }

   get regularExpression(): IRegularExpression | null {
      return this.field.definition?.type !== ContentBrickFieldType.SubContentBrick && this.regularExpressions.length
         ? this.regularExpressions[0]
         : null;
   }

   get booleanLayout(): BooleanFieldLayout | undefined {
      if (this.type !== ContentBrickFieldType.Boolean) {
         return undefined;
      }

      return this.field?.definition?.booleanLayout ?? BooleanFieldLayout.Switch /*switch by default*/;
   }

   canEditFieldUnit(scbField: any | undefined): boolean {
      if (this.readonly) return false;
      if (scbField) return !scbField.definition.isUnitFixed;
      return !this.field.definition.isUnitFixed;
   }

   get isValueField(): boolean | undefined {
      return this.field.metaData?.isValueField;
   }

   get renderDglResult(): boolean | undefined {
      return (
         !!this.translations &&
         this.isValueField &&
         this.field.metaData?.evaluationType !== DesignGuidelineFieldEvaluation.Disabled &&
         !!this.designGuidelineNodeResult?.hasCondition
      );
   }

   isCalculatedFieldType(type: ContentBrickFieldType | undefined = undefined): boolean {
      return (
         type === ContentBrickFieldType.Calculated || this.field?.definition?.type === ContentBrickFieldType.Calculated
      );
   }

   isCalculatedFieldSource(type: ContentBrickFieldType): boolean {
      return type === ContentBrickFieldType.Integer || type === ContentBrickFieldType.Decimal;
   }

   isNumericFieldType(type: ContentBrickFieldType): boolean {
      return (
         type === ContentBrickFieldType.Integer ||
         type === ContentBrickFieldType.Decimal ||
         type === ContentBrickFieldType.Calculated
      );
   }

   isDateTimeFieldType(type: ContentBrickFieldType): boolean {
      return [ContentBrickFieldType.Date, ContentBrickFieldType.Time, ContentBrickFieldType.DateTime].includes(type);
   }

   isSubContentBrickFieldType(type: ContentBrickFieldType): boolean {
      return type === ContentBrickFieldType.SubContentBrick;
   }

   isTextFieldType(type: ContentBrickFieldType): boolean {
      return [ContentBrickFieldType.TextBox, ContentBrickFieldType.TextArea].includes(type);
   }

   getLinksInValue(field: ProjectContentBrickField): {
      type: string;
      value: any;
      href: string;
   }[] {
      if (!this.isTextFieldType(field.definition!.type)) return [];

      return linkify.find(this.internalValue ?? "" /*, 'url' <- can turn off href mailto:*/);
   }

   get link():
      | {
           type: string;
           value: any;
           href: string;
        }
      | undefined {
      let links = this.getLinksInValue(this.field);

      return links.length ? links[0] : undefined; //only the first link for now
   }

   updateValue(newValue: any) {
      if (this.isTextFieldType(this.type)) {
         if (this.type === ContentBrickFieldType.TextArea) {
            this.validateFieldValueByRules(newValue, this.requiredTrimmedRules);
         }
         this.internalValueTrimmed = newValue;
      } else {
         this.internalValue = newValue;
      }
      this.$emit("input", this.internalValue, this.field, this.treeNode);
   }

   // Gets internalTrimedValue formated by current conditions
   get fieldTrimmedValue(): string | null {
      return !this.isFieldFocused(this.field) && this.hasRegularExpression()
         ? this.getFormatedValueByRegex(this.internalValueTrimmed) ?? null
         : this.internalValueTrimmed;
   }

   set fieldTrimmedValue(value: string | null) {
      this.internalValueTrimmed = value;
   }

   // Gets internal formated by current conditions
   get fieldValue(): string | null {
      let value = this.internalValue;

      if (this.isCalculatedFieldType(this.type)) {
         if (this.calculatedValueRounded !== null) {
            value = this.calculatedValueRounded;
         } else {
            return this.calculatedValueRounded;
         }
      }
      return !this.isFieldFocused(this.field) && this.hasRegularExpression()
         ? this.getFormatedValueByRegex(value)
         : value;
   }

   set fieldValue(value: string | null) {
      this.internalValue = value;
   }

   // ----------- Navigation -----------
   focusedField: ContentBrickFieldDefinition | SubContentBrickFieldDefinition | null = null;

   isFieldFocused(field: ContentBrickFieldDefinition | SubContentBrickFieldDefinition | undefined): boolean {
      return field && this.focusedField && this.focusedField.id === field.id ? true : false;
   }

   fieldFocusChanged(field: ContentBrickFieldDefinition | SubContentBrickFieldDefinition, value: boolean) {
      if (field) {
         if (!value) {
            if (this.focusedField && this.focusedField.id === field.id) {
               this.focusedField = null;
            }
         } else {
            this.focusedField = field;
         }
      }
   }

   // ----------- Regular Expressions -----------
   validateTrimmedInputByRegex(): boolean | string {
      return this.validateFieldInput(this.internalValueTrimmed);
   }

   validateInputByRegex(): boolean | string {
      return this.validateFieldInput(this.internalValue);
   }

   validateFieldInput(value: any): boolean | string {
      if (!this.hasRegularExpression()) return true;
      if (!value || value.length == 0) return true;

      if (
         RegularExpressionUtils.validate(
            value.toString(),
            this.regularExpression?.validationRegex?.regex,
            this.regularExpression?.validationRegex?.flags
         )
      ) {
         return true;
      } else {
         return RegularExpressionUtils.regexNotMatchMessage;
      }
   }

   hasRegularExpression(): boolean {
      return this.regularExpression && RegularExpressionUtils.isRegularExpressionEnabled(this.type) ? true : false;
   }

   getFormatedValueByRegex(value: any | null): string | undefined {
      let formattedValue: string | undefined = value ? value.toString() : undefined;
      if (this.regularExpression?.formatRegexes?.length) {
         formattedValue = RegularExpressionUtils.formatByRegexProperties(
            formattedValue,
            this.regularExpression.validationRegex,
            this.regularExpression.formatRegexes
         );
         if (formattedValue !== undefined) {
            return formattedValue;
         }
      }
      return value !== null ? value : undefined;
   }

   mounted() {
      this.setInternalFields();
      this.onValueChanged();

      // Download Image/Video attachment URLS
      if (AttachmentUtils.isMediaField(this.field?.definition?.type)) {
         this.internalMediaUrlValues = Object.assign({}, this.mediaUrlsValues);
         this.$emit("download-attachmentsUrls", this.internalValue, this.field);
      }
   }

   setInternalFields() {
      if (this.fields) {
         this.internalFields = this.fields;
      } else if (this.metadataFields) {
         this.internalFields = this.getProjectFieldsFromMetadataFields();
      } else if (this.ddmFields) {
         this.internalFields = this.getProjectFieldsFromDdmFields();
      }
   }

   getProjectFieldsFromDdmFields(): ProjectContentBrickField[] {
      let fields: ProjectContentBrickField[] = [];
      this.ddmFields.forEach((field) => {
         fields.push(
            new ProjectContentBrickField({
               id: field.id,
               value: this.treeNode[field.identifier],
               definition: new ContentBrickFieldDefinition({ ...field.definition }),
            })
         );
      });
      return fields;
   }

   getProjectFieldsFromMetadataFields(): ProjectContentBrickField[] {
      let fields: ProjectContentBrickField[] = [];
      Object.keys(this.metadataFields).forEach((fieldIdentifier) => {
         let fieldDefinition = this.metadataFields[fieldIdentifier].definition;
         fields.push(
            new ProjectContentBrickField({
               id: fieldDefinition.id,
               value: this.treeNode.data[fieldDefinition.identifier],
               definition: new ContentBrickFieldDefinition({ ...fieldDefinition }),
            })
         );
      });
      return fields;
   }

   projectValuesFromMetadataFieldsSource() {
      Object.keys(this.metadataFields).forEach((fieldIdentifier) => {
         let fieldDefinition = this.metadataFields[fieldIdentifier].definition;
         let field = this.internalFields.find((f) => f.id == fieldDefinition.id);
         if (field) {
            field.value = this.treeNode.data[fieldIdentifier];
         }
      });
   }

   projectValuesFromDdmFieldsSource() {
      this.ddmFields.forEach((field) => {
         let internalField = this.internalFields.find((f) => f.id == field.id);
         if (internalField) {
            internalField.value = this.treeNode[field.identifier];
         }
      });
   }

   // ----------- File drag n drop ------------------
   isDragging: boolean = false;
   dragError: string | null = null;
   cbFieldDropAreaHeightValue: number = 52;

   get cbFieldDropAreaHeight(): string {
      return this.cbFieldDropAreaHeightValue.toString() + "px";
   }

   changeFieldDropAreaHeight() {
      // Set drop-area height according selected cbFileFiled height
      if (!this.isDragging && this.$refs?.fileTypeFiled?.clientHeight) {
         const currentHeight = this.$refs.fileTypeFiled.clientHeight;

         if (currentHeight !== this.cbFieldDropAreaHeightValue) {
            this.cbFieldDropAreaHeightValue = currentHeight;
         }
      }
   }

   onDragOver(event: any) {
      if (this.readonly) {
         event.dataTransfer.dropEffect = "none";
         return;
      }

      this.changeFieldDropAreaHeight();

      this.dragError = AttachmentUtils.validateDragEventFiles(
         event,
         AttachmentUtils.isImageField(this.field.definition.type)
            ? this.field.definition.imageType
            : AttachmentUtils.isVideoField(this.field.definition.type)
            ? this.field.definition.videoType
            : this.field.definition.documentType,
         this.field.definition.documentMaxCount,
         this.internalValue,
         this.field.definition.type
      );
      event.dataTransfer.dropEffect = this.dragError ? "none" : "copy";

      if (!this.isDragging) {
         this.isDragging = true;
      }
   }

   onDragLeave(event: any) {
      this.isDragging = false;
      this.dragError = null;
   }

   onFileDrop(event: any) {
      this.isDragging = false;
      this.dragError = null;
      if (event.dataTransfer.files?.length) {
         this.$emit("upload-attachments", { files: [...event.dataTransfer.files], field: this.field });
      }
   }

   // --------- Webcam input ---------
   openWebcamDialog() {
      this.$refs.webcamInput.openDialog();
   }

   // --------- Validation ---------
   get isRequired(): boolean {
      return this.validate && this.fieldValueRequired;
   }

   requiredRules: Function[] = [(v) => !this.isRequired || !this.runFinishValidation || !!v || "Value is required"];
   requiredBooleanRules: Function[] = [
      (v) => !this.isRequired || !this.runFinishValidation || (v !== null && v !== undefined) || "Value is required",
   ];
   requiredTrimmedRules: Function[] = [
      (v) => !this.isRequired || !this.runFinishValidation || !!v?.trim() || "Value is required",
   ];
   fileRules: Function[] = [
      (v) =>
         !this.isRequired ||
         !this.runFinishValidation ||
         this.isAtleastMinFileCount ||
         `At least ${this.field.definition.documentMinCount} ${
            this.isImageFieldType(this.field)
               ? "Image(s)"
               : this.isVideoFieldType(this.field)
               ? "Video(s)"
               : "Document(s)"
         } required`,
   ];

   get requiredRegexRules(): Function[] {
      let rules = this.requiredRules.slice();

      if (RegularExpressionUtils.isRegularExpressionEnabled(this.type) && this.hasRegularExpression()) {
         rules.push((v) => this.validateInputByRegex() || RegularExpressionUtils.regexNotMatchMessage);
      }

      return rules;
   }

   get requiredRegexTrimmedRules(): Function[] {
      let rules = this.requiredTrimmedRules.slice();

      if (RegularExpressionUtils.isRegularExpressionEnabled(this.type) && this.hasRegularExpression()) {
         rules.push((v) => this.validateTrimmedInputByRegex() || RegularExpressionUtils.regexNotMatchMessage);
      }

      return rules;
   }

   validateFieldValueByRules(value: any, rules: Function[]) {
      for (const rule of rules) {
         const result = rule.call(this, value);

         // if field value validation by rules failed
         if (typeof result === "string") {
            this.validationErrorMessage = [result];
            return;
         }
      }
      // if field value validation is successful
      this.validationErrorMessage = [];
   }
}
</script>
<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_colors";

.img {
   &-pointer {
      cursor: pointer;
   }
}

// realign with Unit due to layout issues in Task detail
.task-unit-alignment-fix {
   margin-bottom: -6px !important;
}

.v-btn.delete-file-btn {
   position: absolute;
   width: 30px;
   height: 24px;
   right: 0px;
   background-color: map-get($grey, "lighten-2");
}

// reduce size of TextArea label to match size of active label of input fields
.scale-075 {
   transform-origin: left;
   transform: scale(0.75);
}

// make the icons smaller/translated for Switch/Checkbox inputs which do not apply the normal transforms to their labels
::v-deep {
   & .three-state-checkbox,
   & .three-state-switch {
      .dgl-icon-container {
         transform-origin: top left;
         transform: scale(0.75);
         position: absolute;
         top: -5px;
      }
   }
}
</style>
