import moment from "moment";

export class ApiResult<TResult> {
   validUntil: string;
   isWaitingForRequest: boolean;
   documents: TResult[];

   isAlive(): boolean {
      return !this.isWaitingForRequest && !!this.validUntil && moment(this.validUntil).isAfter(moment());
   }

   public constructor(init?: Partial<ApiResult<TResult>>) {
      for (var property in init) {
         if (init.hasOwnProperty(property)) {
            (<any>this)[property] = init[property];
         }
      }
   }
}

export abstract class CachedApiBase {
   private static ttl: number = 120; // seconds
   private static storageEventTimeout: number = 15 * 1000; // ms
   private static readonly instances: Set<WeakRef<CachedApiBase>> = new Set<WeakRef<CachedApiBase>>();
   private readonly promises: Map<string, Promise<any[]>> = new Map<string, Promise<any[]>>();

   static init(ttl: number) {
      this.ttl = ttl;
   }

   static invalidate() {
      for (let instanceRef of CachedApiBase.instances) {
         let instance = instanceRef.deref();
         instance?.invalidateAll();
      }
   }

   protected constructor() {
      CachedApiBase.instances.add(new WeakRef(this));
   }

   abstract invalidateAll();

   protected invalidateItem(storageKey: string) {
      localStorage.removeItem(storageKey);
   }

   /**
    * Caches API endpoint results
    * @param storageKey key for (local) storage to store data in
    * @param apiCall API call that retireves the data
    * @param fromJs API fromJS() method of corresponding entity
    * @returns Documents from the API endpoint - either cached objects or result of fresh API call if cahce expired
    */
   protected async cacheEndpointAsync<TEntity>(
      storageKey: string,
      apiCall: () => Promise<TEntity[]>,
      fromJs: (data: any, _mappings?: any) => TEntity
   ): Promise<TEntity[]> {
      let storageEventHandler: (ev: StorageEvent) => void;
      let eventPromise = new Promise<TEntity[]>((resolve) => {
         storageEventHandler = (ev: StorageEvent) => {
            if (ev.key != storageKey) return;

            let result = this.fromString(ev.newValue, fromJs);
            if (!result?.isWaitingForRequest) {
               resolve(result?.documents ?? []);
            }
         };
         window.addEventListener("storage", storageEventHandler);
      });

      try {
         // attempt to find any existing info in local storage
         let cachedString = localStorage.getItem(storageKey);
         let cachedVersion = this.fromString(cachedString, fromJs);
         let isExpired = !cachedVersion?.isAlive();

         // if exists and not expired, use cached data
         if (!isExpired) {
            return cachedVersion!.documents;
         }

         // attempt to find a running local API request
         let dataPromise = this.promises.get(storageKey);

         if (dataPromise) {
            // await promised documents
            let documents = await dataPromise;
            return documents;
         }

         // a request is running in different tab
         if (
            !!cachedVersion?.isWaitingForRequest &&
            !!cachedVersion?.isAlive() /*if the request takes suspitiosly long, make own anyway*/
         ) {
            // promise data from event
            let resultOrTimeout = await this.resultOrTimeout(eventPromise, CachedApiBase.storageEventTimeout);
            // if event not timed out, use its result, otherwise request API
            if (!resultOrTimeout.isTimedout) {
               return resultOrTimeout.result!;
            }
         }

         // if everything else fails, create new API request
         // publish globally that a request is running
         cachedVersion = new ApiResult<TEntity>({
            validUntil: moment().add(CachedApiBase.ttl, "seconds").toISOString(),
            isWaitingForRequest: true,
         });
         localStorage.setItem(storageKey, JSON.stringify(cachedVersion));
         dataPromise = apiCall();
         // publish locally that a request is running
         this.promises.set(storageKey, dataPromise);

         let documents = await dataPromise;

         // save the documents - notify globally any waiting tabs via storage event
         cachedVersion.documents = documents;
         cachedVersion.isWaitingForRequest = false;
         localStorage.setItem(storageKey, JSON.stringify(cachedVersion));
         // delete local flag
         this.promises.delete(storageKey);

         return documents;
      } finally {
         window.removeEventListener("storage", storageEventHandler!);
      }
   }

   /**
    * Parses storage/storageEvent string and returns API-like objects
    * @param cachedString storage/storageEvent string
    * @param fromJs API fromJS method of corresponding entity
    * @returns ApiResult with API-like objects
    */
   private fromString<TEntity>(
      cachedString: string | null,
      fromJs: (data: any, _mappings?: any) => TEntity
   ): ApiResult<TEntity> | null {
      let cachedVersion = cachedString !== null ? new ApiResult<TEntity>(JSON.parse(cachedString)) : null;
      if (cachedVersion?.documents) {
         // JSON.parse does not fully construct child objects - it only copies prop values
         cachedVersion.documents = cachedVersion!.documents.map((d) => {
            return fromJs(d);
         });
      }

      return cachedVersion;
   }

   /**
    * Return a wrapper promise that awaits either result or times out after set period of time
    * @param promise Original promise
    * @param timeout Max execution time of promise
    * @returns Object indicating success/timeout and possibly the result
    */
   protected async resultOrTimeout<TResult>(
      promise: Promise<TResult>,
      timeout: number
   ): Promise<{ isTimedout: Boolean; result: TResult | null }> {
      let timeoutPromise = new Promise<{ isTimedout: Boolean; result: TResult | null }>((resolve) => {
         setTimeout(() => resolve({ isTimedout: true, result: null }), timeout);
      });
      let raceResult = await Promise.race([
         promise.then((result) => {
            return { isTimedout: false, result: result };
         }),
         timeoutPromise,
      ]);

      return raceResult;
   }
}
