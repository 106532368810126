import { DesignGuidelineFieldEvaluation } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IDesignGuidelineFieldEvaluationProperties extends IEnumBaseProperties {}

export class DesignGuidelineFieldEvaluationDecorator implements IDesignGuidelineFieldEvaluationProperties {
   value: DesignGuidelineFieldEvaluation;
   private static readonly unkown: number = -1;

   constructor(value: DesignGuidelineFieldEvaluation) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   static get(value: DesignGuidelineFieldEvaluation): IDesignGuidelineFieldEvaluationProperties {
      return (
         DesignGuidelineFieldEvaluationDecorator.DesignGuidelineFieldEvaluationProperties.get(value) ??
         DesignGuidelineFieldEvaluationDecorator.DesignGuidelineFieldEvaluationProperties.get(
            DesignGuidelineFieldEvaluationDecorator.unkown
         )!
      );
   }

   static get AllItems(): DesignGuidelineFieldEvaluationDecorator[] {
      return Array.from(DesignGuidelineFieldEvaluationDecorator.DesignGuidelineFieldEvaluationProperties.keys())
         .filter((status: number) => status != DesignGuidelineFieldEvaluationDecorator.unkown)
         .map((status: number) => new DesignGuidelineFieldEvaluationDecorator(status));
   }

   private get properties(): IDesignGuidelineFieldEvaluationProperties {
      return (
         DesignGuidelineFieldEvaluationDecorator.DesignGuidelineFieldEvaluationProperties.get(this.value) ??
         DesignGuidelineFieldEvaluationDecorator.DesignGuidelineFieldEvaluationProperties.get(
            DesignGuidelineFieldEvaluationDecorator.unkown
         )!
      );
   }

   private static readonly DesignGuidelineFieldEvaluationProperties: Map<
      number,
      IDesignGuidelineFieldEvaluationProperties
   > = new Map<number, IDesignGuidelineFieldEvaluationProperties>([
      [DesignGuidelineFieldEvaluation.Manual, { translationKey: "designGuidelineFieldEvaluation.manual" }],
      [DesignGuidelineFieldEvaluation.Automatic, { translationKey: "designGuidelineFieldEvaluation.automatic" }],
      [DesignGuidelineFieldEvaluation.Disabled, { translationKey: "designGuidelineFieldEvaluation.disabled" }],
   ]);
}
