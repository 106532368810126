import axios from "axios";
import appsettings from "@app/appsettings.json";
import appDevSettings from "@app/appsettings.Development.json";

export type AppSettingKeys = keyof typeof appsettings;

export class AppSettings {
   public settings;

   public async init() {
      if (process.env.NODE_ENV == "development") {
         this.settings = (await axios.get(appDevSettings as any)).data;
      } else {
         this.settings = (await axios.get(appsettings as any)).data;
      }
   }

   public getSetting(key: AppSettingKeys): string {
      return this.settings[key];
   }
}

const store = new AppSettings();

export default store;
