<template>
   <v-dialog :value="showDialog" width="75%" persistent scrollable>
      <v-card>
         <v-card-title>
            <span>{{ fieldName }}</span>
            <v-spacer />
            <v-btn
               class="red--text"
               icon
               v-shortkey.once="['esc']"
               @shortkey="hideEditorDialog()"
               @click="hideEditorDialog()"
            >
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <vue-editor :disabled="readonly" v-model="fieldValueInternal" :class="editorClass"></vue-editor>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error mx-4" @click="hideEditorDialog">Ok</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";

@Component({
   name: "FieldTextAreaDialog",
   components: {
      VueEditor,
   },
})
export default class FieldTextAreaDialog extends Vue {
   @Prop({ required: true })
   value: string | null;

   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   fieldName: string | null;

   get fieldValueInternal(): string | null {
      return this.value;
   }

   set fieldValueInternal(value: string | null) {
      this.$emit("input", value);
   }

   hideEditorDialog() {
      this.$emit("update:showDialog", false);
   }

   get editorClass() {
      return this.readonly ? ["vue-editor-disabled"] : [];
   }
}
</script>
<style scoped lang="scss">
/*hide quill toolbar when disabled */
::v-deep.vue-editor-disabled {
   .ql-toolbar {
      border-bottom: 0;
      > span {
         display: none;
      }
   }
}
</style>
