<template>
   <div>
      <h5>{{ translateKey("dglExportPdf.historyLabel", translations) }}</h5>

      <table class="table">
         <tr>
            <th v-for="header in headers" :key="header.value" :style="header.style">
               {{ header.text }}
            </th>
         </tr>
         <tr v-for="item in contentBrick.availableVersions" :key="item.id">
            <td>{{ item.code }}</td>
            <td>{{ item.displayText }}</td>
            <td>{{ item.lastModified?.user?.displayText }}</td>
            <td>{{ item.lastModified?.timestamp.format("l") }}</td>
         </tr>
      </table>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { IContentBrickDefinition, IItemReference, TranslationPublicModel } from "@backend/api/pmToolApi";

@Component({
   name: "ExportPdfDesignGuidelineHistory",
})
export default class ExportPdfDesignGuidelineHistory extends ComponentBase {
   @Prop({ required: true })
   contentBrick: IContentBrickDefinition;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   items: IItemReference[] = [];

   headers: any[] = [
      {
         text: "Version",
         value: "code",
         translationKey: "dglExportPdf.versionHeader",
         style: "width: 35%",
      },

      { text: "Changed by", value: "activatedBy", translationKey: "dglExportPdf.changedByHeader", style: "width: 15%" },
      {
         text: "Change date",
         value: "activatedDate",
         translationKey: "dglExportPdf.changeDateHeader",
         style: "width: 15%",
      },
      { text: "Note", value: "displayText", translationKey: "dglExportPdf.noteHeader", style: "width: 35%" },
   ];

   mounted() {
      this.translateHeaders(this.headers, this.translations);
   }
}
</script>

<style scoped></style>
