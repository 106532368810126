<template>
   <v-progress-circular v-if="circular" :color="fillColor" indeterminate size="16"></v-progress-circular>
   <v-progress-linear v-else :color="fillColor" indeterminate></v-progress-linear>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class Spinner extends Vue {
   @Prop()
   public color: string;

   @Prop({ default: false })
   public circular: boolean;

   private get fillColor(): string {
      if (this.color) {
         return this.color;
      }

      return "red";
   }
}
</script>
<style lang="scss" scoped></style>
