import {
   AttachmentMetadata,
   QueryDataModelType,
   ProjectApi,
   ProjectDefinitionApi,
   DomainDataModelInstanceApi,
   ContentBrickDefinitionApi,
   OrganigramApi,
   OrganigramConditionImportRowResult,
} from "@backend/api/pmToolApi";
import { AttachmentUploadInfo } from "@utils/AttachmentUtils";
import { AxiosRequestConfig } from "axios";
import api from "@backend/api/api";

export default class AttachmentApi {
   static async uploadProjectAttachment(
      id: string,
      fieldNodeId: string,
      modelType: QueryDataModelType,
      pdmNodeId: string | null,
      taskId: string | null,
      attachment: File,
      uploadInfo: AttachmentUploadInfo
   ): Promise<AttachmentMetadata | undefined> {
      let url_ = "/api/v1/Project/{id}/attachment?";
      if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
      url_ = url_.replace("{id}", encodeURIComponent("" + id));
      if (fieldNodeId === undefined || fieldNodeId === null)
         throw new Error("The parameter 'fieldNodeId' must be defined and cannot be null.");
      else url_ += "fieldNodeId=" + encodeURIComponent("" + fieldNodeId) + "&";
      if (modelType === undefined || modelType === null)
         throw new Error("The parameter 'modelType' must be defined and cannot be null.");
      else url_ += "modelType=" + encodeURIComponent("" + modelType) + "&";
      if (pdmNodeId === undefined) throw new Error("The parameter 'pdmNodeId' must be defined.");
      else if (pdmNodeId !== null) url_ += "pdmNodeId=" + encodeURIComponent("" + pdmNodeId) + "&";
      if (taskId === undefined) throw new Error("The parameter 'taskId' must be defined.");
      else if (taskId !== null) url_ += "taskId=" + encodeURIComponent("" + taskId) + "&";
      url_ = url_.replace(/[?&]$/, "");

      if (attachment === null || attachment === undefined) throw new Error("The parameter 'attachment' is required.");

      const content_ = new FormData();
      content_.append("attachment", attachment, attachment.name ? attachment.name : "attachment");

      const rqstConf: AxiosRequestConfig = {
         onUploadProgress: (progressEvent: any) => {
            uploadInfo.update(progressEvent);
         },
      };
      const resp = await api.post<AttachmentMetadata>(url_, content_, rqstConf);

      return ProjectApi.processUploadProjectAttachment(resp);
   }

   static async uploadProjectDraftAttachment(
      id: string,
      fieldNodeId: string,
      modelType: QueryDataModelType,
      pdmNodeId: string | null,
      taskId: string,
      attachment: File,
      uploadInfo: AttachmentUploadInfo
   ): Promise<AttachmentMetadata | undefined> {
      let url_ = "/api/v1/ProjectDefinition/{id}/attachment?";
      if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
      url_ = url_.replace("{id}", encodeURIComponent("" + id));
      if (fieldNodeId === undefined || fieldNodeId === null)
         throw new Error("The parameter 'fieldNodeId' must be defined and cannot be null.");
      else url_ += "fieldNodeId=" + encodeURIComponent("" + fieldNodeId) + "&";
      if (modelType === undefined || modelType === null)
         throw new Error("The parameter 'modelType' must be defined and cannot be null.");
      else url_ += "modelType=" + encodeURIComponent("" + modelType) + "&";
      if (pdmNodeId === undefined) throw new Error("The parameter 'pdmNodeId' must be defined.");
      else if (pdmNodeId !== null) url_ += "pdmNodeId=" + encodeURIComponent("" + pdmNodeId) + "&";
      if (taskId === undefined || taskId === null)
         throw new Error("The parameter 'taskId' must be defined and cannot be null.");
      else url_ += "taskId=" + encodeURIComponent("" + taskId) + "&";
      url_ = url_.replace(/[?&]$/, "");

      if (attachment === null || attachment === undefined) throw new Error("The parameter 'attachment' is required.");

      const content_ = new FormData();
      content_.append("attachment", attachment, attachment.name ? attachment.name : "attachment");

      const rqstConf: AxiosRequestConfig = {
         onUploadProgress: (progressEvent: any) => {
            uploadInfo.update(progressEvent);
         },
      };
      const resp = await api.post<AttachmentMetadata>(url_, content_, rqstConf);

      return ProjectDefinitionApi.processUploadAttachment(resp);
   }

   static async uploadDomainDataModelInstanceAttachment(
      id: string,
      fieldNodeId: string,
      attachment: File,
      uploadInfo: AttachmentUploadInfo
   ): Promise<AttachmentMetadata | undefined> {
      let url_ = "/api/v1/DomainDataModelInstance/{id}/attachment?";
      if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
      url_ = url_.replace("{id}", encodeURIComponent("" + id));
      if (fieldNodeId === undefined || fieldNodeId === null)
         throw new Error("The parameter 'fieldNodeId' must be defined and cannot be null.");
      else url_ += "fieldNodeId=" + encodeURIComponent("" + fieldNodeId) + "&";
      url_ = url_.replace(/[?&]$/, "");

      if (attachment === null || attachment === undefined) throw new Error("The parameter 'attachment' is required.");

      const content_ = new FormData();
      content_.append("attachment", attachment, attachment.name ? attachment.name : "attachment");

      const rqstConf: AxiosRequestConfig = {
         onUploadProgress: (progressEvent: any) => {
            uploadInfo.update(progressEvent);
         },
      };
      const resp = await api.post<AttachmentMetadata>(url_, content_, rqstConf);

      return DomainDataModelInstanceApi.processUploadDomainDataModelInstanceAttachment(resp);
   }

   static async uploadDesignGuidelinesAttachment(
      id: string,
      fieldId: string,
      attachment: File,
      uploadInfo: AttachmentUploadInfo
   ): Promise<AttachmentMetadata | undefined> {
      let url_ = "/api/v1/ContentBrickDefinition/{id}/attachment?";
      if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
      url_ = url_.replace("{id}", encodeURIComponent("" + id));
      if (fieldId === undefined || fieldId === null)
         throw new Error("The parameter 'fieldId' must be defined and cannot be null.");
      else url_ += "fieldId=" + encodeURIComponent("" + fieldId) + "&";
      url_ = url_.replace(/[?&]$/, "");

      if (attachment === null || attachment === undefined) throw new Error("The parameter 'attachment' is required.");

      const content_ = new FormData();
      content_.append("attachment", attachment, attachment.name ? attachment.name : "attachment");

      const rqstConf: AxiosRequestConfig = {
         onUploadProgress: (progressEvent: any) => {
            uploadInfo.update(progressEvent);
         },
      };
      const resp = await api.post<AttachmentMetadata>(url_, content_, rqstConf);

      return ContentBrickDefinitionApi.processUploadDesignGuidelineAttachment(resp);
   }

   static async importOrganigramConditionsAttachmentAsync(
      id: string,
      attachment: File,
      uploadInfo: AttachmentUploadInfo
   ): Promise<OrganigramConditionImportRowResult[] | undefined> {
      let url_ = "/api/v1/Organigram/nodes/{id}/users/conditions";
      if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.");
      url_ = url_.replace("{id}", encodeURIComponent("" + id));
      url_ = url_.replace(/[?&]$/, "");

      if (attachment === null || attachment === undefined) throw new Error("The parameter 'attachment' is required.");

      const content_ = new FormData();
      content_.append("attachment", attachment, attachment.name ? attachment.name : "attachment");

      const rqstConf: AxiosRequestConfig = {
         onUploadProgress: (progressEvent: any) => {
            uploadInfo.update(progressEvent);
         },
      };
      const resp = await api.post<OrganigramConditionImportRowResult[]>(url_, content_, rqstConf);

      return OrganigramApi.processImportOrganigramNodeUserConditions(resp);
   }
}
