import colors from "vuetify/lib/util/colors";

const pmToolColGrey = colors.grey.lighten4;
const pmToolColBg = pmToolColGrey;
const pmToolColBg240 = "#F0F0F0";
const pmToolColBg240_lighten1 = "#F5F5F5";
const pmToolColLightBlue = "#D5ECFF";
const pmToolColLightYellow = "#FFF3D5";

// Vuetify custom color themes, see bellow:
// https://vuetifyjs.com/en/features/theme/#custom-theme-variants
export default {
   bg: pmToolColBg, // custom color class called 'bg' with its color value
   bg240: {
      base: pmToolColBg240,
      lighten1: pmToolColBg240_lighten1,
   },
   bgBlue: pmToolColLightBlue,
   bgYellow: pmToolColLightYellow,
};
