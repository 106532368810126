import { DocumentType } from "@backend/api/pmToolApi";
import { IEnumBaseProperties } from "@models/shared/IEnumBaseProperties";

export interface IDocumentTypeProperties extends IEnumBaseProperties {
   readonly extensions: string;
   isFileTypeValid(fileType: string): boolean;
}

export class DocumentTypeDecorator implements IDocumentTypeProperties {
   value: DocumentType;
   private static readonly unkown: number = -1;

   constructor(value: DocumentType) {
      this.value = value;
   }

   get translationKey(): string {
      return this.properties.translationKey;
   }

   get extensions(): string {
      return this.properties.extensions;
   }

   get accept(): string {
      // wildcards not supported on Mac - test if Mac and Any type and return empty string
      if (this.value === DocumentType.Any && /(Mac)/i.test(navigator.platform)) {
         return "";
      }
      return this.properties.extensions.replaceAll(/(?<!\.)\*/gm, ""); //html uses syntax without star
   }

   isFileTypeValid(fileType: string): boolean {
      return this.properties.isFileTypeValid(fileType);
   }

   static get AllItems(): DocumentTypeDecorator[] {
      return Array.from(DocumentTypeDecorator.entityStatusProperties.keys())
         .filter((status: number) => status != DocumentTypeDecorator.unkown)
         .map((status: number) => new DocumentTypeDecorator(status));
   }

   private get properties(): IDocumentTypeProperties {
      return (
         DocumentTypeDecorator.entityStatusProperties.get(this.value) ??
         DocumentTypeDecorator.entityStatusProperties.get(DocumentTypeDecorator.unkown)!
      );
   }

   private static readonly textFileTypes: string[] = ["text/plain"];
   private static readonly spreadsheetFileTypes: string[] = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
   ];
   private static readonly imageFileTypes: string[] = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/tiff",
      "image/gif",
   ];
   private static readonly pdfFileTypes: string[] = ["application/pdf"];
   private static readonly archiveFileTypes: string[] = ["application/x-zip-compressed", "application/x-rar"];

   private static readonly entityStatusProperties: Map<number, IDocumentTypeProperties> = new Map<
      number,
      IDocumentTypeProperties
   >([
      [DocumentType.Any, { extensions: "*.*", isFileTypeValid: () => true, translationKey: "any" }],
      [
         DocumentType.TextDocument,
         {
            extensions: "*.txt, *.doc, *.docx",
            isFileTypeValid: (type: string) => DocumentTypeDecorator.textFileTypes.includes(type),
            translationKey: "documentType.textDocument",
         },
      ],
      [
         DocumentType.Spreadsheet,
         {
            extensions: "*.xls, *.xlsx, *.csv",
            isFileTypeValid: (type: string) => DocumentTypeDecorator.spreadsheetFileTypes.includes(type),
            translationKey: "documentType.spreadsheet",
         },
      ],
      [
         DocumentType.Image,
         {
            extensions: "*.jpg, *.jpeg, *.png, *.bmp, *.tiff, *.gif",
            isFileTypeValid: (type: string) => DocumentTypeDecorator.imageFileTypes.includes(type),
            translationKey: "documentType.image",
         },
      ],
      [
         DocumentType.PdfDocument,
         {
            extensions: "*.pdf",
            isFileTypeValid: (type: string) => DocumentTypeDecorator.pdfFileTypes.includes(type),
            translationKey: "documentType.pdfDocument",
         },
      ],
      [
         DocumentType.Archive,
         {
            extensions: "*.zip, *.rar",
            isFileTypeValid: (type: string) => DocumentTypeDecorator.archiveFileTypes.includes(type),
            translationKey: "documentType.archive",
         },
      ],
      [DocumentTypeDecorator.unkown, { extensions: "", isFileTypeValid: () => false, translationKey: "unknown" }],
   ]);
}
