import {
   DesignGuidelineNodeType,
   DesignGuidelineNodeResult,
   DesignGuidelineResult,
   DesignGuidelineFieldEvaluation,
} from "@backend/api/pmToolApi";
import { Guid } from "guid-typescript";

export default class TestResultTree extends DesignGuidelineResult {
   resultTree!: TestResultTreeNode;

   constructor(parent: DesignGuidelineResult) {
      super(parent);
      this.resultTree = this.buildTree();
   }

   public writeResults() {
      this.result = this.resultTree.recursiveResult ?? undefined;
      this.nodeResults?.forEach((nr) => (nr.result = (<TestResultTreeNode>nr).dynamicResult ?? undefined));
   }

   private buildTree(): TestResultTreeNode {
      const nodeMap: { [key: string]: TestResultTreeNode } = {};

      const nodeResults = (this.nodeResults ?? []).map((node) => new TestResultTreeNode(node));
      this.nodeResults = nodeResults;

      nodeResults.forEach((node) => {
         nodeMap[node.nodeId] = node;
      });

      let root: TestResultTreeNode | null = null;

      this.nodeResults.forEach((node) => {
         const treeNode = nodeMap[node.nodeId];
         if (node.groupId === Guid.EMPTY) {
            if (root) {
               throw "Multiple root nodes found";
            }
            root = treeNode;
         } else {
            const parent = nodeMap[node.groupId];
            if (!parent) {
               throw `Parent node with id ${node.groupId} not found`;
            }
            parent.children.push(treeNode);
         }
      });

      return (
         root ??
         new TestResultTreeNode(DesignGuidelineNodeResult.fromJS({ name: "Root", type: DesignGuidelineNodeType.Group }))
      );
   }
}

export class TestResultTreeNode extends DesignGuidelineNodeResult {
   children!: TestResultTreeNode[];

   get recursiveResult(): boolean | null | undefined {
      if (this.type !== DesignGuidelineNodeType.Group) {
         return this.dynamicResult;
      }

      return this.children
         .filter((c) => c.isVisible)
         .reduce(
            (acc, curr) =>
               TestResultTreeNode.getResultPriority(curr.recursiveResult) > TestResultTreeNode.getResultPriority(acc)
                  ? curr.recursiveResult
                  : acc,
            this.dynamicResult
         );
   }

   public static getResultPriority(result: boolean | null | undefined): number {
      switch (result) {
         case undefined:
            return 0;
         case true:
            return 1;
         case false:
            return 2;
         case null:
            return 3;
      }
   }

   /**
    * BE implementation in TaskDesignGuidelineService.EvaluateResult
    */
   get dynamicResult(): boolean | null | undefined {
      if (!this.hasCondition) return undefined; // not participating in the result
      if (this.manualResult != undefined) return this.manualResult; // manual result always come first
      if (this.evaluationType == DesignGuidelineFieldEvaluation.Manual) return null; // manual result is require but not filled => Unevaluated
      return this.conditionResult; // if all above fails, it is the Condtion result
   }

   constructor(parent: DesignGuidelineNodeResult) {
      super(parent);
      this.children = [];
   }
}
