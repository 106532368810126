import {
   ContentNode,
   IProjectTask,
   ProjectContentBrick,
   ProjectTaskContent,
   PbbContentItemType,
   QueryDataModelType,
   DesignGuidelineFieldEvaluation,
} from "@backend/api/pmToolApi";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import TestResultTree from "@models/testReport/TestResultTree";
import { flatMapDeep } from "lodash";

export default class TaskUtils {
   public static getAttachmentModelType(contentBrick: ProjectContentBrick): QueryDataModelType {
      const contentDefinition = contentBrick.originatingContentDefinition!;

      if (contentDefinition.contentItemType === PbbContentItemType.Adhoc) return QueryDataModelType.Process;

      if (contentBrick.metaData?.saveOnlyInPdm) return QueryDataModelType.Process;

      return contentDefinition.queryContentFields!.queryModelType;
   }

   public static getReportAttachmentModelType(testResult: TestResultTree): QueryDataModelType {
      if (testResult.contentItemType === PbbContentItemType.Adhoc) return QueryDataModelType.Process;

      if (testResult.saveOnlyInPdm) return QueryDataModelType.Process;

      return testResult.modelType ?? QueryDataModelType.Domain;
   }

   public static getAttachmentPdmNodeId(
      task: IProjectTask,
      contentBrick: ProjectContentBrick,
      pdmNodeId: string | null
   ): string | null {
      const contentDefinition = contentBrick.originatingContentDefinition!;

      if (contentDefinition.contentItemType === PbbContentItemType.Adhoc)
         return task.id; /*pdmNodeId is null for ad-hoc*/

      if (contentBrick.metaData?.saveOnlyInPdm) return null;

      return pdmNodeId;
   }

   /**
    * Return all DGLs in the Task Content
    * @param content Task content
    * @returns List of Task's DGLs
    */
   public static getAllDesignGuidelines(content: ProjectTaskContent | undefined): ContentNode[] {
      return (
         content?.contentItems?.flatMap(
            (contentItem) =>
               contentItem.contentRoot?.flatMap((contentNode) => {
                  if (!contentNode) return [];

                  const flatten = (item: ContentNode) => [item, flatMapDeep(item?.children ?? [], flatten)];
                  const flattenedTree = flatMapDeep([contentNode], flatten) as ContentNode[];

                  return flattenedTree.filter(
                     (cn) =>
                        cn.projectContentBrick?.metaData?.contentBrickType != null &&
                        ContentBrickTypeDecorator.get(cn.projectContentBrick.metaData.contentBrickType).hasValueFields
                  );
               }) ?? []
         ) ?? []
      );
   }

   /**
    * Return true if there is at least 1 DGL in the Task Content
    * @param content Task content
    * @returns true if there is at least 1 DGL in the Task Content
    */
   public static hasDesignGuidelines(content: ProjectTaskContent | undefined): boolean {
      return !!this.getAllDesignGuidelines(content).length;
   }

   /**
    * Return true if all existing DGL Results are Passed/ManuallyPassed
    * @param content Task content
    * @returns true if all existing DGL Results are Passed/ManuallyPassed
    */
   public static hasDesignGuidelinesAllPassed(content: ProjectTaskContent | undefined): boolean {
      return !content?.taskDesignGuidelineReport?.designGuidelineResults?.some((dglr) => dglr.result !== true);
   }

   /**
    * Return true if there is at least 1 Value Field with evaluation type = Manual in the Content
    * @param content Task content
    * @returns true if there is at least 1 Value Field with evaluation type = Manual in the Content
    */
   public static hasManualConditionFields(content: ProjectTaskContent | undefined): boolean {
      const dgls = this.getAllDesignGuidelines(content);

      const visibleValueFields = dgls.flatMap((dgl) =>
         dgl.projectContentBrick!.fields!.filter(
            (f) => f.metaData!.isValueField && dgl.projectContentBrick!.fieldGroupVisibility![f.id]
         )
      );
      const hasManualConditionFields = visibleValueFields.some(
         (vf) => vf.metaData?.evaluationType === DesignGuidelineFieldEvaluation.Manual
      );

      return hasManualConditionFields;
   }
}
