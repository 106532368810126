import { LanguagePublicModel, TranslationApi, TranslationPublicModel } from "@backend/api/pmToolApi";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";

class TranslationCachedApi extends CachedApiBase {
   private readonly storedTranslationRoutesKey: string = "TranslationApi.storedTranslationRoutes";
   private readonly translationsRoutePrefix: string = "TranslationApi.getRouteTranslations_";
   private readonly batchTranslationsRoutePrefix: string = "TranslationApi.getBatchRouteTranslations_";
   private static readonly getActiveLanguagesKey = "TranslationApi.getActiveLanguages";

   prefixRoute(route: string) {
      return this.translationsRoutePrefix + route;
   }

   prefixBatchRoutes(routes: string[]) {
      return this.batchTranslationsRoutePrefix + routes.join("_");
   }

   private addRouteToStore(route: string) {
      var routes = this.getStoredRoutes();
      if (!routes.includes(route)) {
         routes.push(route);
      }
      localStorage.setItem(this.storedTranslationRoutesKey, JSON.stringify(routes));
   }

   private getStoredRoutes(): string[] {
      var routes = localStorage.getItem(this.storedTranslationRoutesKey);
      return !routes ? [] : JSON.parse(routes);
   }

   async getBatchRouteTranslations(routes: string[], languageId: string): Promise<TranslationPublicModel[]> {
      var storageKey = this.prefixBatchRoutes(routes);
      this.addRouteToStore(storageKey);
      return await this.cacheEndpointAsync<TranslationPublicModel>(
         storageKey,
         async () => {
            let result = await TranslationApi.getBatchRoutesTranslations(languageId, routes);
            return result;
         },
         TranslationPublicModel.fromJS
      );
   }

   async getBatchRouteDefaultTranslations(routes: string[]): Promise<TranslationPublicModel[]> {
      var storageKey = this.prefixBatchRoutes(routes);
      this.addRouteToStore(storageKey);
      return await this.cacheEndpointAsync<TranslationPublicModel>(
         storageKey,
         async () => {
            let result = await TranslationApi.getBatchRoutesDefaultTranslations(routes);
            return result;
         },
         TranslationPublicModel.fromJS
      );
   }

   async getRouteTranslations(route: string, languageId: string): Promise<TranslationPublicModel[]> {
      var storageKey = this.prefixRoute(route);
      this.addRouteToStore(storageKey);
      return await this.cacheEndpointAsync<TranslationPublicModel>(
         storageKey,
         async () => {
            let result = await TranslationApi.getRouteTranslations(route, languageId);
            return result;
         },
         TranslationPublicModel.fromJS
      );
   }

   async getRouteDefaultTranslations(route: string): Promise<TranslationPublicModel[]> {
      var storageKey = this.prefixRoute(route);
      this.addRouteToStore(storageKey);
      return await this.cacheEndpointAsync<TranslationPublicModel>(
         storageKey,
         async () => {
            let result = await TranslationApi.getRouteDefaultTranslations(route);
            return result;
         },
         TranslationPublicModel.fromJS
      );
   }

   /**
    * Returns list of active languages
    */
   async getActiveLanguages(): Promise<LanguagePublicModel[]> {
      return await this.cacheEndpointAsync<LanguagePublicModel>(
         TranslationCachedApi.getActiveLanguagesKey,
         TranslationApi.getActiveLanguages.bind(TranslationApi),
         LanguagePublicModel.fromJS
      );
   }

   invalidateTranslations() {
      var routes = this.getStoredRoutes();
      for (var route of routes) {
         this.invalidateItem(route);
      }
      localStorage.removeItem(this.storedTranslationRoutesKey);
   }

   invalidateAll() {
      this.invalidateItem(TranslationCachedApi.getActiveLanguagesKey);
      this.invalidateTranslations();
   }

   private static _instance: TranslationCachedApi;

   private constructor() {
      super();
   }

   public static get instance(): TranslationCachedApi {
      return this._instance || (this._instance = new this());
   }
}

export default TranslationCachedApi.instance;
