import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Keycloak, { KeycloakInstance } from "keycloak-js";

export class AxiosKeycloak {
   private static instance: AxiosInstance;
   private static keycloakInstance: KeycloakInstance;

   static createAxiosInstance(keycloak: KeycloakInstance, config?: AxiosRequestConfig): AxiosInstance {
      if (AxiosKeycloak.instance) {
         console.warn("Axios instance already created");
         return;
      }

      AxiosKeycloak.instance = axios.create(config);
      AxiosKeycloak.keycloakInstance = keycloak;

      AxiosKeycloak.instance.interceptors.request.use(
         (config) =>
            new Promise((resolve, reject) =>
               keycloak
                  .updateToken(30)
                  .success(() => {
                     config.headers.Authorization = "Bearer " + keycloak.token;
                     resolve(config);
                  })
                  .error(() => {
                     keycloak.login();
                  })
            )
      );

      return AxiosKeycloak.instance;
   }

   static get axios(): AxiosInstance {
      return AxiosKeycloak.instance;
   }

   static getKeycloak(): KeycloakInstance {
      return AxiosKeycloak.keycloakInstance;
   }
}
