<template>
   <div v-if="isUser()">
      <v-app-bar elevation="0" color="white" class="pt-1">
         <div class="mobile-top-menu-title pb-1">
            <search-bar :translations="routeTranslations" />
         </div>
         <div class="d-flex align-center">
            <div class="px-2 mobile-top-menu-title">
               <lang-selector></lang-selector>
            </div>
            <!--<chat-notifications class="mr-5" />-->
            <notifications :translations="routeTranslations" class="pr-4 mr-1" />
            <div class="d-inline-flex">
               <v-icon>mdi-account</v-icon>
               <span class="mx-1 font-weight-medium">{{ userName }}</span>
            </div>

            <v-menu offset-y v-model="isOpen" class="top-bar-menu-z-index">
               <template v-slot:activator="{ on }" :offset-x="true" :close-on-content-click="false" :nudge-width="200">
                  <v-btn ui-test-data="top-bar-more-options" v-on="on" icon class="nav-menu-icon">
                     <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
               </template>
               <v-card>
                  <v-list>
                     <v-list-item
                        link
                        tag="span"
                        ui-test-data="top-bar-more-options-my-acc"
                        :to="`/user/detail/${userId}`"
                     >
                        {{ translateKey("accountMenu.myAccountLink") }}
                     </v-list-item>
                     <v-spacer></v-spacer>
                     <v-list-item link tag="span" ui-test-data="top-bar-more-options-logout" @click="logOut">
                        {{ translateKey("accountMenu.logoutLink") }}
                     </v-list-item>
                  </v-list>
               </v-card>
            </v-menu>
         </div>
      </v-app-bar>
      <v-divider class="my-0" />
      <v-banner single-line color="amber accent-2" :value="userUnavailable">
         <span>{{ translateKey("unavailabilityBanner.unavailabilityLabel") }}</span>
         <v-btn text @click="unavailabityTurnOff" class="error ml-5">
            {{ translateKey("unavailabilityBanner.turnOffButton") }}
         </v-btn>
      </v-banner>
   </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AuthStore from "@backend/store/authStore";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import UserDto from "@models/user/UserDto";
import EngFlag from "@resources/eng-flag.png";
import Notifications from "@components/Notifications/notification-list.vue";
import ChatNotifications from "@components/Chat/chat-notifications.vue";
import SearchBar from "@components/SearchBar/search-bar.vue";
import LangSelector from "@components/Shared/lang-selector.vue";
import BaseResponse from "@models/BaseResponse";
import { UserApi, UserUnavailability } from "@backend/api//pmToolApi";
import * as moment from "moment";
import { setCookie, getCookie, deleteCookie } from "@utils/CookieUtils";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import UserCachedApi from "@backend/store/apiCache/UserCachedApi";

import { AxiosKeycloak } from "@backend/axiosKeycloak";

@Component({
   name: "TopBar",
   components: {
      Notifications,
      ChatNotifications,
      SearchBar,
      LangSelector,
   },
})
export default class TopBar extends ComponentBase {
   isOpen: boolean = false;
   engFlag = EngFlag;
   userUnavailable: boolean = false;

   userName: string | null = "";
   userId: string | null = null;
   onNavIconClick() {
      this.$emit("toggle-nav-menu");
   }

   isUserLoggedIn(): boolean {
      return AuthStore.isUserLoggedIn();
   }

   CurrentUser(): UserDto {
      return AuthStore.getCurrentUser();
   }

   isUser(): boolean {
      return true;
   }

   setUserUnavailability(userUnavailability: UserUnavailability) {
      if (!userUnavailability) {
         this.userUnavailable = false;
         return;
      }

      var now = moment();

      if (userUnavailability.unavailableFrom === undefined) {
         this.userUnavailable = !!userUnavailability.unavailableIndefinitely;
      } else if (userUnavailability.unavailableFrom !== undefined && userUnavailability.unavailableTo === undefined) {
         this.userUnavailable = userUnavailability.unavailableFrom <= now;
      } else if (userUnavailability.unavailableFrom !== undefined && userUnavailability.unavailableTo !== undefined) {
         this.userUnavailable = userUnavailability.unavailableFrom <= now && userUnavailability.unavailableTo >= now;
      }
   }

   async isUserUnavailable(): Promise<void> {
      if (this.userId) {
         try {
            let userUnavailability: UserUnavailability = await UserCachedApi.getCurrentUserUnavailability();
            this.setUserUnavailability(userUnavailability);
         } catch (e) {
            let error = e as BaseResponse;
            // TODO handle error, show to user
            console.log("API User unavailability load error:", error);
            this.notifyError(error, "load", "User unavailability");
            this.userUnavailable = false;
         }
      }
   }

   async unavailabityTurnOff(): Promise<void> {
      if (this.userId) {
         try {
            let userUnavailability: UserUnavailability = new UserUnavailability({
               unavailableIndefinitely: false,
               unavailableFrom: undefined,
               unavailableTo: undefined,
            });
            let unavailability = await UserApi.updateUserUnavailability(this.userId, userUnavailability);
            UserCachedApi.invalidatetUserUnavailability();
            EventBus.$emit(Events.UserUnavailabilityChanged, unavailability);
         } catch (e) {
            let error = e as BaseResponse;
            // TODO handle error, show to user
            console.log("API User load and unavailibity update error:", error);
            this.notifyError(error, "tunrn off", "User unavailability");
            this.userUnavailable = false;
         }
      }
   }

   logOut() {
      EventBus.$emit(Events.UserLoggedOut);
      let keycloak = AxiosKeycloak.getKeycloak();
      keycloak.logout();
      deleteCookie("token");
      deleteCookie("refreshToken");
      EventBus.$off(Events.UserUnavailabilityChanged, this.setUserUnavailability);
   }

   mounted() {
      this.loadRouteTranslations("topBar");
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations("topBar");
      });

      var base = this;
      this.userName = AuthStore.getUserName();
      this.userId = AuthStore.getUserId();
      this.isUserUnavailable();
      EventBus.$on(Events.UserLoggedIn, () => {
         this.userName = AuthStore.getUserName();
         this.userId = AuthStore.getUserId();
         base.$nextTick(() => {
            base.$forceUpdate();
         });
      });
      EventBus.$on(Events.UserLoggedOut, () => {
         base.$nextTick(() => {
            base.$forceUpdate();
         });
      });

      EventBus.$on(Events.UserUnavailabilityChanged, this.setUserUnavailability);
   }
}
</script>

<style scoped>
.top-bar-background {
   background-color: white;
}

.nav-menu-icon {
   background-color: white;
   margin-left: 1rem;
}

.top-bar-menu-z-index {
   z-index: 11;
}
</style>
