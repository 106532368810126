<template>
   <v-card>
      <v-card-title primary-title>
         <v-row>
            <v-col cols="12" md="6">{{ translateKey("pbbReferencesTab.pbbReferencesLabel", translations) }}</v-col>
            <v-col cols="12" md="6">
               <div class="d-flex">
                  <v-switch v-model="showAllVersions" color="error" label="Show all Versions" class="mr-8"></v-switch>
                  <v-text-field
                     v-model="searchQuery"
                     append-icon="mdi-magnify"
                     color="red darken-2"
                     label="Search"
                     @input="searchDebounced()"
                     single-line
                     hide-details
                     autofocus
                  />
               </div>
            </v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-data-table
            :mobile-breakpoint="0"
            class="overview-table pmtool-table"
            dense
            :headers="headersPbb"
            :items="pbbs"
            :loading="loading"
            :search="searchQuery"
            :custom-filter="customSearch"
            :server-items-length="pagingTotalDocuments"
            :options.sync="dataTableOptions"
            :footer-props="footerProps"
         >
            <template v-slot:item.code="{ item }">
               <router-link :to="getPbbDetailValue(item)" class="black--text">
                  <div class="max-height">
                     {{ item.code }}
                  </div>
               </router-link>
            </template>
            <template v-slot:item.displayText="{ item }">
               <router-link :to="getPbbDetailValue(item)" class="black--text">
                  <div class="max-height">
                     {{ item.displayText }}
                  </div>
               </router-link>
            </template>
            <template v-slot:item.created="{ item }">
               <router-link :to="getPbbDetailValue(item)" class="black--text">
                  <div class="max-height">
                     {{ item.creationDate }}
                  </div>
               </router-link>
            </template>
            <template v-slot:item.version="{ item }">
               <div>
                  <router-link :to="getPbbDetailValue(item)" class="black--text">
                     <div class="max-height">
                        <span>v{{ ("000" + item.version.major).slice(-4) }}</span>
                        <v-tooltip top>
                           <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" class="ml-3">{{ getVersionIcon(item) }}</v-icon>
                           </template>
                           <span>{{ getVersionHint(item) }}</span>
                        </v-tooltip>
                     </div>
                  </router-link>
               </div>
            </template>
            <template v-slot:item.latestActiveVersion="{ item }">
               <router-link
                  v-if="item.latestActiveVersion"
                  :to="getPbbDetailValue(item.latestActiveVersion)"
                  class="black--text"
               >
                  <div class="max-height">
                     <span>v{{ ("000" + item.latestActiveVersion.version.major).slice(-4) }}</span>
                  </div>
               </router-link>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import OverviewBase from "@components/Shared/Base/overview-base.vue";
import {
   ContentBrickDefinitionApi,
   DomainDataModelApi,
   OrganigramApi,
   EntityType,
   ProcessBuildingBlockWithLatestReference,
   QueryOptionsOfPbbFilterOptions,
   QueryResultOfProcessBuildingBlockReference,
   PbbFilterOptions,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import BaseResponse from "@models/BaseResponse";
import { debounce } from "lodash";
import { DataOptions } from "vuetify";

@Component({
   name: "ContentBrickDetailTabPbbUsage",
   components: {},
})
export default class ContentBrickDetailTabPbbUsage extends OverviewBase {
   loading: boolean = false;
   searchQuery: string = "";
   filter: PbbFilterOptions = PbbFilterOptions.fromJS({ lastOnly: true });
   pbbs: ProcessBuildingBlockWithLatestReference[] = [];

   @Prop({ required: true })
   documentId: string;

   @Prop({ required: true })
   documentType: EntityType;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ default: false })
   showLatestVersion: boolean;

   //------------------- Base --------------------
   loadData = this.loadPbbReferences;
   protected defaultDataTableOptions: Partial<DataOptions> | undefined = {
      sortBy: ["created"],
      sortDesc: [true],
   };

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.headersPbb, this.translations);
   }

   headersPbb = [
      {
         text: "Code",
         value: "code",
         translationKey: "pbbReferencesTab.codeHeader",
      },
      {
         text: "Name",
         value: "displayText",
         translationKey: "pbbReferencesTab.nameHeader",
      },
      {
         text: "Created Date",
         value: "creationDate",
         translationKey: "pbbReferencesTab.createdDateHeader",
      },
      {
         text: "Version",
         value: "version",
         sort: (a: moment.Moment, b: moment.Moment) => a.valueOf() - b.valueOf(),
         sortable: false,
         translationKey: "pbbReferencesTab.versionHeader",
      },
      ...(this.showLatestVersion
         ? [
              {
                 text: "Latest version",
                 value: "latestActiveVersion",
                 sort: (a: moment.Moment, b: moment.Moment) => a.valueOf() - b.valueOf(),
                 sortable: false,
                 translationKey: "pbbReferencesTab.latestVersionHeader",
              },
           ]
         : []),
   ];

   get showAllVersions() {
      return !this.filter.lastOnly;
   }

   set showAllVersions(value: boolean) {
      this.filter.lastOnly = !value;
      this.searchDebounced();
   }

   private searchDebounced = debounce(() => this.search(), 200);

   search() {
      this.filter.searchQuery = this.searchQuery.length >= 3 ? this.searchQuery : undefined;
      this.loadPbbReferences();
   }

   getPbbDetailValue(pbb: ProcessBuildingBlockWithLatestReference) {
      return {
         name: "pbb-detail",
         params: { id: pbb.id },
      };
   }

   getVersionIcon(pbb: ProcessBuildingBlockWithLatestReference) {
      if (pbb.latestActiveVersion?.id === pbb.id) return "mdi-check";
      else if (pbb.isLatestReferencing) return "mdi-update";
      return "mdi-clock-remove-outline";
   }

   getVersionHint(pbb: ProcessBuildingBlockWithLatestReference) {
      if (pbb.latestActiveVersion?.id === pbb.id) return "Latest Active PBB version";
      else if (pbb.isLatestReferencing) return "Latest Active PBB version also references this Organigram node";
      return "PBB no longer references this Organigram node in the latest Active version";
   }

   //------------Lifecycle-----------
   mounted() {
      this.loadData();
      this.translateHeaders(this.headersPbb, this.translations);
   }

   //------------API-----------
   async loadPbbReferences() {
      this.loading = true;

      try {
         // prepare api call parameters
         let queryOptions = new QueryOptionsOfPbbFilterOptions();
         queryOptions.filter = this.filter;
         this.OnBeforePagedQuery(queryOptions);

         let result: QueryResultOfProcessBuildingBlockReference;

         if (this.documentType == EntityType.DomainDataModelDefinition) {
            result = await DomainDataModelApi.queryDomainDataModelDefinitionPbbs(this.documentId, queryOptions);
         } else if (this.documentType == EntityType.ContentBrickDefinition) {
            result = await ContentBrickDefinitionApi.queryContentBrickDefinitionPbbs(this.documentId, queryOptions);
         } else if (this.documentType == EntityType.OrganigramNode) {
            result = await OrganigramApi.queryOrganigramNodePbbs(this.documentId, queryOptions);
         } else {
            throw "documentType is not set";
         }

         this.pbbs = this.extendPbbs(result.documents ?? []);

         // preserve pagination parameters
         this.OnAfterPagedQuery(result.continuationToken, result.totalDocumentsCount);
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API load PBB references error:", error);
         this.notifyError(error, "load", "PBB References");
      }

      this.loading = false;
   }

   extendPbbs(ppbs: ProcessBuildingBlockWithLatestReference[]): ProcessBuildingBlockWithLatestReference[] {
      ppbs.forEach((pbb) => {
         Object.defineProperty(pbb, "creationDate", {
            get: function (): string {
               return this.createdTimestamp.format("l");
            },
         });
      });

      return ppbs;
   }
}
</script>
