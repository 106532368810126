<template>
   <div>
      <v-row>
         <v-col class="d-flex pl-0 ml-0">
            <div v-if="smallSections" class="subtitle-1 mt-4 ml-3">{{ title }}</div>
            <v-card-title v-else primary-title>{{ title }}</v-card-title>
            <v-btn-toggle
               v-model="conditionResultsInInternal"
               label="Condition results in"
               class="ml-10 mt-3 btn-color-disabled"
               dense
               mandatory
               color="red"
            >
               <v-btn class="transparent-background" :value="1" :disabled="readonly" outlined>
                  {{ translateKey("contentTab.warningButton", translations) }}
               </v-btn>
               <v-btn class="transparent-background" :value="2" :disabled="readonly" outlined>
                  {{ translateKey("contentTab.errorButton", translations) }}
               </v-btn>
            </v-btn-toggle>
         </v-col>
      </v-row>
      <v-card class="ml-0 pl-0">
         <vue-query-builder
            v-if="fields && fields.length > 0"
            :rules="queryFieldRules"
            :labels="conditionLabels"
            v-model="condition"
         >
            <template v-slot:default="slotProps">
               <query-builder-group v-bind="slotProps" :query.sync="condition" :readonly="readonly" />
            </template>
         </vue-query-builder>
         <v-row v-else>
            <v-col>
               <span>{{ translateKey("contentTab.noFieldsDefinedLabel", translations) }}</span>
            </v-col>
         </v-row>
      </v-card>
      <div v-if="smallSections" class="d-flex">
         <div class="subtitle-1 pl-0 mt-5 mb-2">
            {{ translateKey("contentTab.errorDescriptionLabel", translations) }}
         </div>
      </div>
      <v-card-title v-else primary-title class="pl-0 mt-3">
         {{ translateKey("contentTab.errorDescriptionLabel", translations) }}
      </v-card-title>
      <v-row>
         <v-col class="pt-0">
            <vue-editor v-model="conditionResultsDescriptionInternal" :disabled="readonly"></vue-editor>
         </v-col>
      </v-row>
   </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch, VModel } from "vue-property-decorator";

import VueQueryBuilder from "vue-query-builder";
import QueryBuilderGroup from "@components/ContentBricks/TabDetail/QueryBuilder/vuetify-group.vue";
import { VueEditor } from "vue2-editor";
import QueryBuilderRule from "@models/shared/query-builder/QueryBuilderRule";
import Util from "@models/shared/ContentBrickDefinitionConditionUtils";
import IProjectContentBrickExpression from "@models/project/IProjectContentBrickExpression";
import ProjectContentBrickExpression from "@models/project/ProjectContentBrickExpression";
import {
   ContentBrickConditionResultsIn,
   IContentBrickFieldDefinition,
   IContentBrickExpression,
   ContentBrickExpression,
   ContentBrickExpressionType,
   ContentBrickPrimaryExpression,
   ContentBrickEqualityPrimaryExpressionType,
   List,
   QueryDataModelType,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   name: "ContentBrickConditions",
   components: {
      VueQueryBuilder,
      QueryBuilderGroup,
      VueEditor,
   },
})
export default class ContentBrickConditions extends ComponentBase {
   @VModel({ required: true })
   conditionExpression: IContentBrickExpression | IProjectContentBrickExpression;

   @Prop({ required: true })
   conditionResultsIn: ContentBrickConditionResultsIn;

   @Prop({ required: true })
   conditionResultsDescription: string | undefined;

   @Prop({ required: true })
   fields: IContentBrickFieldDefinition[];

   @Prop({ required: true })
   lists: List[];

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: "Conditions" })
   title: string;

   @Prop({ default: false })
   smallSections: boolean;

   @Prop({ required: true })
   isFromProject: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   mounted() {
      if (this.conditionExpression) {
         this.condition = this.expressionToQuery(this.conditionExpression); // transform CB condition to internal QueryBuilder condition
      } else {
         this.condition = {
            logicalOperator: "and",
            children: [],
         };
      }
   }

   @Watch("condition", { deep: true })
   onConditionChanged(): void {
      if (this.condition) {
         this.conditionExpression = this.queryToExpression(this.condition); // transform internal QueryBuilder condition to CB condition
         this.updateConditionResultsIn();
         this.updateConditionResultsDescription();
      }
   }

   /**
    * Condition internal representation object
    */
   condition: object = {};
   conditionLabels: object = {
      matchType: "Operator",
      matchTypes: [
         { id: "and", label: "AND" },
         { id: "or", label: "OR" },
         { id: "andnot", label: "AND NOT" },
      ],
      addRule: "Add Condition",
      removeRule: "&times;",
      addGroup: "Add Group",
      removeGroup: "&times;",
      textInputPlaceholder: "value",
   };

   get conditionResultsInInternal(): ContentBrickConditionResultsIn {
      return this.conditionResultsIn;
   }

   set conditionResultsInInternal(value: ContentBrickConditionResultsIn) {
      this.$emit("update:conditionResultsIn", value);
   }

   updateConditionResultsIn() {
      this.$emit("update:conditionResultsIn", this.conditionResultsInInternal);
   }

   get conditionResultsDescriptionInternal() {
      return this.conditionResultsDescription;
   }

   set conditionResultsDescriptionInternal(value: string | undefined) {
      this.$emit("update:conditionResultsDescription", value);
   }

   updateConditionResultsDescription() {
      this.$emit("update:conditionResultsDescription", this.conditionResultsDescriptionInternal);
   }

   get queryFieldRules(): QueryBuilderRule[] {
      return Util.queryFieldRules(this.fields);
   }

   /**
    * NOTE: Expression originating from Project JSON-like structure must use null values instead of undefined
    * in order to not trigger change watcher. In that case special class is used here to transform from query
    * of the component inner model.
    */
   queryToExpression(group: any): IContentBrickExpression | IProjectContentBrickExpression {
      var expression = {
         expressionType: ContentBrickExpressionType.LogicGroupExpression,
         operator: group.logicalOperator ?? (this.isFromProject ? null : (undefined as any)),
         operands:
            group.children?.map((child) => {
               if (child.type === "query-builder-group") {
                  return this.queryToExpression(child.query);
               }

               var childExpression = {
                  expressionType: ContentBrickExpressionType.BinaryExpression,
                  operator: child.query.operator ?? (this.isFromProject ? null : (undefined as any)),
                  operands: this.isFromProject ? null : (undefined as any),
                  left: new ContentBrickPrimaryExpression({
                     type: ContentBrickEqualityPrimaryExpressionType.Indentifier,
                     value: this.fields.findIndex((field) => field.id === child.query.rule).toString(),
                     modelType: 0,
                  }),
                  right: new ContentBrickPrimaryExpression({
                     type: child.query.value?.startsWith("ref|")
                        ? ContentBrickEqualityPrimaryExpressionType.Indentifier
                        : child.query.value?.startsWith("fieldRefQuery|")
                          ? ContentBrickEqualityPrimaryExpressionType.FieldQuery
                          : ContentBrickEqualityPrimaryExpressionType.Constant,
                     value: child.query.value?.startsWith("ref|")
                        ? this.fields
                             .findIndex((field) => field.id === child.query.value.slice("ref|".length))
                             .toString()
                        : child.query.value?.startsWith("fieldRefQuery|")
                          ? child.query.value.slice("fieldRefQuery|***|".length)
                          : child.query.value,
                     modelType: child.query.value?.startsWith("fieldRefQuery|PDM|")
                        ? QueryDataModelType.Process
                        : QueryDataModelType.Domain,
                  }),
               };

               return this.isFromProject
                  ? new ProjectContentBrickExpression(childExpression)
                  : new ContentBrickExpression(childExpression);
            }) ?? [],
         left: this.isFromProject ? null : (undefined as any),
         right: this.isFromProject ? null : (undefined as any),
      };

      return this.isFromProject
         ? new ProjectContentBrickExpression(expression)
         : new ContentBrickExpression(expression);
   }

   expressionToQuery(expression: IContentBrickExpression | IProjectContentBrickExpression): any {
      if (expression.expressionType === ContentBrickExpressionType.LogicGroupExpression) {
         return {
            logicalOperator: expression.operator,
            children: expression.operands?.map((op) => ({
               type:
                  op.expressionType === ContentBrickExpressionType.LogicGroupExpression
                     ? "query-builder-group"
                     : "query-builder-rule",
               query: this.expressionToQuery(op),
            })),
         };
      } else {
         let self = this;
         return {
            operator: expression.operator,
            rule: this.fields[expression.left.value].id,
            value:
               expression.right.type === ContentBrickEqualityPrimaryExpressionType.Indentifier
                  ? `ref|${this.fields[expression.right.value].id}`
                  : expression.right.type === ContentBrickEqualityPrimaryExpressionType.FieldQuery
                    ? `fieldRefQuery|${expression.right.modelType === QueryDataModelType.Process ? "PDM" : "DDM"}|${
                         expression.right.value
                      }`
                    : expression.right.value,
            get operand() {
               return self.fields.find((field) => field.id == this.rule)?.name;
            },
         };
      }
   }
}
</script>
