import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes.ts";
import AuthStore from "./backend/store/authStore";
import Events from "./models/shared/Events";
import MaintenanceUtils from "@utils/MaintenanceUtils";

Vue.use(VueRouter);

let router = new VueRouter({
   mode: "history",
   routes,
});

router.beforeEach(async (to, from, next) => {
   console.log(to);

   if (to.meta.requiresAuth) {
      document.title = to.meta.title;

      /*var token = AuthStore.getToken();

        if (token == null) {
            EventBus.$emit(Events.UserLoggedOut);

            next("/");
            return;
        }*/
   }
   router.canGoBack = from.matched && from.matched.length > 0;

   let isMaintenance = MaintenanceUtils.isMaintenance;
   let isKeycloakAdmin = MaintenanceUtils.isKeycloakAdmin;

   // if in Maintenance mode but not PM admin, redirect to maintenance page
   if (isMaintenance && !isKeycloakAdmin) {
      if (to.name !== "maintenance") {
         next({ name: "maintenance" });
      } else {
         next();
      }
   }
   // if NOT in Maintenance mode or PM admin, redirect from maintenance page to home page
   else if ((!isMaintenance || isKeycloakAdmin) && to.name === "maintenance") {
      next({ name: "home" });
   } else {
      next();
   }
});

export default router;
