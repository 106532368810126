import { ContentBrickFieldType, DataModelFieldMetaData, ProjectContentBrickField } from "@backend/api/pmToolApi";
import _ from "lodash";

class DataModelFieldUtils {
   readonly tooltipThreshold: number = 20;

   findReferencedUnitIds(field: ProjectContentBrickField | DataModelFieldMetaData, value: any): string[] {
      if (field.definition!.type === ContentBrickFieldType.SubContentBrick) {
         let scbFields = Object.values(<any[]>value.$metadata.fields);
         let uniqeUnitIds = _.uniq(scbFields.filter((f) => !!f.definition.unitId).map((f) => f.definition.unitId));
         return uniqeUnitIds;
      } else {
         return [field.definition!.unitId!];
      }
   }

   findReferencedRegularExpressionIds(field: ProjectContentBrickField, value: any): string[] {
      if (field.definition!.type === ContentBrickFieldType.SubContentBrick) {
         let scbFields = Object.values(<any[]>value.$metadata.fields);
         let uniqeRegularExpressionIds = _.uniq(
            scbFields.filter((f) => !!f.definition.regularExpressionId).map((f) => f.definition.regularExpressionId)
         );
         return uniqeRegularExpressionIds;
      } else {
         return [field.definition!.regularExpressionId!];
      }
   }
}
export default new DataModelFieldUtils();
